// ext functions & others - non components
import React, { Component, Suspense } from "react"
import { configureStore } from "./store"
import { createBrowserHistory } from "redux-first-routing"

// ext components
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import ReactGA from "react-ga"

// commons frontend - components
import { ApplicationsContextProvider } from "./ApplicationsContext"
import AppLoader from "./components/AppLoader"

// project - functions & others
import { getClientConfig } from "./api/common"

// project - components
import Routes from "./Routes"
import { Application } from "./Applications"
import { BassetProvider } from "./components/BassetProvider"
import { Theme } from "./components/BassetProvider"

const history = createBrowserHistory()
const store = configureStore(history)

interface Props {}
interface State {
  theme: Theme | null
  applications?: Application[]
  favicon: string
}
class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      theme: null,
      favicon: ""
    }
  }

  componentDidMount() {
    this.fetchClientConfig()
  }

  fetchClientConfig = async () => {
    const config = await getClientConfig()

    ReactGA.initialize(config.analytics_id)

    this.setState({
      theme: {
        brand_colors: config.brand_colors,
        searchbox_colors: config.searchbox_colors
      },
      applications: config.applications,
      favicon: config.favicon_url
    })
    this.overrideFavicon()
  }

  overrideFavicon = () => {
    let head = document.getElementsByTagName("head")[0]

    let linkEl = document.createElement("link")
    linkEl.type = "image/x-icon"
    linkEl.rel = "icon"

    // remove existing favicons
    let links = head.getElementsByTagName("link")
    for (let i = links.length; --i >= 0; ) {
      const rel = links[i].getAttribute("rel")
      if (rel && /\bicon\b/i.test(rel)) {
        head.removeChild(links[i])
      }
    }

    head.appendChild(linkEl)

    linkEl.href = this.state.favicon
  }

  render() {
    const { theme, applications } = this.state

    if (!theme) {
      return <AppLoader />
    } else
      return (
        <Suspense fallback={<AppLoader />}>
          <ApplicationsContextProvider value={applications}>
            <BassetProvider theme={theme}>
              <Provider store={store}>
                <Router history={history}>
                  <Routes />
                </Router>
              </Provider>
            </BassetProvider>
          </ApplicationsContextProvider>
        </Suspense>
      )
  }
}

export default App
