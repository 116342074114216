import React from "react"
import { css } from "glamor"
import Icon from "@material-ui/core/Icon"
import { Theme, theme, ThemeProps } from "../BassetProvider"

interface Value {
  value: any
  text: string
}

interface Props extends ThemeProps {
  options: Value[]
  value: any
  onChange: (v: any) => void
}

const DropdownSelect = ({ options, value, onChange, theme }: Props) => {
  const onValueChange = (e: any) => {
    onChange(e.target.value)
  }

  return (
    <div className={`${containerStyle}`}>
      <select className={`${selectStyle}`} value={value} onChange={onValueChange}>
        {options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.text}
          </option>
        ))}
      </select>
      <Icon className={`${arrowStyle(theme)}`}>keyboard_arrow_down</Icon>
    </div>
  )
}

const containerStyle = css({
  position: "relative",
  display: "flex",
  backgroundColor: "#fff",
  flexDirection: "row",
  width: "100%"
})

const selectStyle = css({
  float: "left",
  fontFamily: "Roboto",
  fontSize: "14px",
  width: "100%",
  fontWeight: "300",
  appearance: "none",
  padding: "0 8px",
  backgroundColor: "transparent",
  border: "none",
  zIndex: "2"
})

const arrowStyle = (theme: Theme) =>
  css({
    position: "absolute",
    right: "8px",
    top: "5px",
    display: "block",
    float: "right",
    color: theme.brand_colors.click_color,
    zIndex: "1"
  })

export default theme(DropdownSelect)
