import * as React from "react"
import { css } from "glamor"
import MenuItem from "@material-ui/core/MenuItem"

const highlightedStyle = css({
  backgroundColor: "rgba(252, 81, 31, 0.35)"
})

const menuItemStyle = css({
  "&.MuiMenuItem-root": {
    height: "25px",
    padding: "15px",
    color: "#4a4a4a",
    fontSize: "14px",
    minHeight: "40px !important",
    fontWeight: 100
  }
})
const menuItemInnerDivStyle = css({
  overflow: "hidden",
  textOverflow: "ellipsis"
})

interface Part {
  highlight: boolean
  text: string
}

interface Props {
  parts: Part[]
  className?: string
}

const Suggestion = ({ parts, className }: Props) => (
  <MenuItem component={"div"} className={[className, menuItemStyle].join(" ")}>
    <div className={`${menuItemInnerDivStyle}`}>
      {parts.map((part, index) => (
        <span className={part.highlight ? `${highlightedStyle}` : ""} key={index}>
          {part.text}
        </span>
      ))}
    </div>
  </MenuItem>
)

export default Suggestion
