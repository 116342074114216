import { css } from "emotion"

export default {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    " > div": {
      marginBottom: "8px"
    },
    " > div:last-child": {
      marginBottom: "0px"
    }
  }),

  buttonStyle: (searchboxClickColor: string, searchboxBackgroundColor: string) =>
    css({
      "&.MuiButton-root": {
        marginTop: "16px",
        backgroundColor: `${searchboxClickColor}`,
        " span": {
          textTransform: "none",
          fontWeight: 300
        },
        color: searchboxBackgroundColor
      }
    }),

  containerStyle: (searchboxHighlightColor: string, borderless: boolean | undefined) =>
    css({
      backgroundColor: searchboxHighlightColor,
      borderRadius: borderless ? "" : "3px",
      boxSizing: "border-box",
      boxShadow: borderless ? "" : "0 2px 2px 0 rgba(0, 0, 0, 0.24),, 0 0 2px 0 rgba(0, 0, 0, 0.12),"
    }),

  searchboxStyle: (borderless: boolean | undefined, mobile: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: borderless ? "" : "16px 8px",
      borderTop: mobile ? "solid 1px rgba(255, 255, 255, 0.5)," : "none"
    }),

  searchboxTitle: (searchboxBackgroundColor: string) =>
    css({
      fontSize: "20px",
      lineHeight: 1.2,
      fontWeight: 300,
      color: searchboxBackgroundColor,
      padding: "16px 8px 0px 8px"
    })
}
