// ext functions & others - non components
import React from "react"
import { connect } from "react-redux"
import { push } from "redux-first-routing"
import _ from "underscore"
import qs from "qs"
import { withTranslation, WithTranslation } from "react-i18next"

// ext components
import ReactGA from "react-ga"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import LinearProgress from "@material-ui/core/LinearProgress"

// commons-frontend functions & others
import { theme, ThemeProps } from "../BassetProvider"
import { searchParamsToDetailQueryString, searchParamsToPath } from "../../utils/helpers"

// commons frontend - components
import HotelList from "./list/HotelList"
import HotelListMap from "./list/HotelListMap"
import MobileBottomNavigation from "./list/MobileBottomNavigation"
import ListHeader from "./list/ListHeader"
import Filters from "./filters/Filters"
import SortingOptions from "./sorting/SortingOptions"

// project - functions & others
import { searchAccommodations, searchPageAccommodations } from "../../actions/search"

// project - components
import Searchbox from "../Searchbox/Searchbox"
import Wrapper from "../Wrapper"
import { SearchParams } from "../../types"
import { RewardsCookies } from "../../utils/rewards"
import SliderComponent from "../Common/Slider"
import SliderAdvertising from "../Common/SliderAdvertising"
import { Slider } from "../../types"
import { getAdvertising } from "../../api/advertising"
import { ApplicationsProps, applications } from "../../Applications"
import styles from "./Result.styles"

interface StateProps {
  queries: any
  pathname: string
  availableFilters: any
  availableSortingOptions: any
  searchParams: any
  pageSize: number
  shouldSearch: boolean
  accommodations: any[]
  isPaginating: boolean
  isFetching: boolean
  totalAccommodations: number
  regionName: string
  currencyCode: string
  rewards: any
}

interface OwnProps extends WithTranslation, ThemeProps, ApplicationsProps {}

interface DispachProps {
  dispatchSearchAcommodations: (s: SearchParams) => void
  dispatchPush: (path: string) => void
  dispatchPagination: (s: SearchParams) => void
}

interface Props extends StateProps, OwnProps, DispachProps {}

interface State {
  viewMap: boolean
  viewFilters: boolean
  viewSorting: boolean
  completed: number
  searchedRegion: any
  rewardsPoint: number
  sliderAdvertising?: Slider
  financialAdvertising?: any
}

class Result extends React.Component<Props, State> {
  timer?: NodeJS.Timeout = undefined
  timeout?: NodeJS.Timeout = undefined

  constructor(props) {
    super(props)

    this.state = {
      viewMap: false,
      viewFilters: false,
      viewSorting: false,
      completed: 0,
      searchedRegion: null,
      rewardsPoint: RewardsCookies(0)
    }

    this.onSearchAccommodations()
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (this.state.completed > 0) return true
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
  }

  componentDidUpdate() {
    const { shouldSearch } = this.props
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (shouldSearch) {
      ReactGA.pageview(window.location.pathname + window.location.search)
      this.onSearchAccommodations()
      this.timer = setInterval(this.progress, 200)
    }
  }

  calculateTotalGuests(rooms: any[]): number {
    let total = 0
    for (let r of rooms) {
      total += r.adults
      total += r.children.length
    }
    return total
  }

  componentDidMount() {
    document.title = this.props.t("Result.documentTitle")
    this.timer = setInterval(this.progress, 200)
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  // progress bar logic
  progress = () => {
    const { isFetching } = this.props
    const { completed } = this.state
    if (isFetching) {
      const diff = Math.random() * 10
      this.setState({ completed: Math.min(completed + diff, 95) })
    } else {
      if (completed !== 0) {
        this.setState({ completed: 100 }, () => {
          this.timeout = setTimeout(() => {
            this.setState({ completed: 0 })
          }, 400)
        })
      }
      if (this.timer) {
        clearInterval(this.timer)
      }
    }
  }

  onSearchAccommodations = async () => {
    const { dispatchSearchAcommodations, searchParams } = this.props
    dispatchSearchAcommodations(searchParams)
    try {
      const advertising = await this.getAdvertisings()
      this.setState({ sliderAdvertising: advertising.slider, financialAdvertising: advertising.financial })
    } catch (err) {
      console.error(err)
    }
    return null
  }

  onPoints = value => {
    this.setState({ rewardsPoint: value })
  }

  onViewMap = () => {
    this.setState({ viewMap: !this.state.viewMap })
  }

  onViewFilters = () => {
    this.setState({ viewFilters: true })
  }

  onCloseFilters = () => {
    this.setState({ viewFilters: false })
  }

  onViewSorting = () => {
    this.setState({ viewSorting: true })
  }

  onCloseSorting = () => {
    this.setState({ viewSorting: false })
  }

  getAdvertisings = async (): Promise<any> => {
    try {
      return await getAdvertising()
    } catch (err) {
      console.error(err)
    }
    return null
  }

  goToDetail = (hotelID: string, points: number) => {
    const { searchParams, rewards } = this.props

    let uri = `/accommodations/details/${hotelID}?${searchParamsToDetailQueryString(searchParams)}`
    if (rewards && points !== 0) uri = uri.concat(`&points=${points}`)

    window.open(uri, "_blank")
  }

  onFilterChange = (key: string, value: string[]) => {
    const { queries, pathname, dispatchPush } = this.props

    let path = ""

    if (value.length) {
      path = `${pathname}?${qs.stringify({ ...queries, [key]: value.join(",") })}`
    } else {
      delete queries[key]
      path = `${pathname}?${qs.stringify(queries)}`
    }

    dispatchPush(path)
  }

  onPagination = () => {
    const { dispatchPagination, pageSize, searchParams } = this.props

    const offset = searchParams.pagination ? searchParams.pagination.offset + pageSize : pageSize
    const limit = searchParams.pagination ? searchParams.pagination.limit + pageSize : pageSize * 2
    searchParams.pagination = {
      limit,
      offset
    }
    dispatchPagination(searchParams)
  }

  onSearch = params => {
    const { dispatchPush } = this.props
    const path = searchParamsToPath(params)
    dispatchPush(path)
  }

  showResults = () => {
    const { accommodations } = this.props
    return accommodations && accommodations.length > 0 //Has hotel results
  }

  showRewards = () => {
    const { rewards, accommodations } = this.props
    return rewards !== undefined && accommodations && accommodations.length > 0
  }

  handlePoints = value => {
    this.setState({ rewardsPoint: value })
  }

  render() {
    const {
      accommodations,
      isPaginating,
      isFetching,
      totalAccommodations,
      pageSize,
      searchParams,
      availableFilters,
      availableSortingOptions,
      regionName,
      currencyCode,
      theme,
      rewards
    } = this.props

    const {
      viewMap,
      viewFilters,
      viewSorting,
      completed,
      sliderAdvertising,
      financialAdvertising,
      rewardsPoint
    } = this.state

    const isMobile = window.innerWidth < 1200
    const nights = searchParams.checkout.diff(searchParams.checkin, "days")
    return (
      <Wrapper>
        {completed > 0 && (
          <LinearProgress
            className={styles.progressStyle(theme.brand_colors.highlight_color)}
            variant="determinate"
            value={this.state.completed}
          />
        )}
        <div className={styles.backgroundStyle}>
          <div className={styles.containerStyle}>
            <div className={styles.filterContainerStyle}>
              <div className={styles.searchboxContainerStyle}>
                <Searchbox
                  destination={searchParams.location}
                  checkin={searchParams.checkin}
                  checkout={searchParams.checkout}
                  rooms={searchParams.rooms}
                  mobile={isMobile}
                  onSearch={this.onSearch}
                  rewards={rewards}
                />
              </div>
              {!isMobile && this.showResults() && (
                <div id={"filters"}>
                  {availableFilters && (
                    <Filters
                      availableFilters={availableFilters}
                      appliedFilters={searchParams.filters}
                      onFilterChange={this.onFilterChange}
                      currencyCode={currencyCode}
                    />
                  )}
                </div>
              )}
            </div>

            <div className={styles.hotelListStyle}>
              {sliderAdvertising && <SliderAdvertising slider={sliderAdvertising} />}
              {this.showRewards() && (
                <SliderComponent
                  color={this.props.theme.brand_colors.highlight_color}
                  minPoints={rewards.min_points}
                  maxPoints={rewards.max_points}
                  precision={rewards.response.precision}
                  handlePoints={this.handlePoints}
                  selectedPoints={rewardsPoint}
                  userPoints={RewardsCookies(rewards.min_points)}
                />
              )}
              {!isMobile && this.showResults() && (
                <ListHeader
                  id={"listHeader"}
                  regionName={regionName}
                  searchParams={searchParams}
                  viewMap={this.state.viewMap}
                  onViewMap={this.onViewMap}
                  availableSortingOptions={availableSortingOptions}
                  appliedSortingOption={searchParams.order_by}
                  onFilterChange={this.onFilterChange}
                  rewards={rewards}
                />
              )}

              {!rewards && viewMap && this.showResults() && (
                <HotelListMap
                  top={"#listHeader"}
                  bottomBoundary={"#filters"}
                  onHotelSelect={this.goToDetail}
                  accommodations={accommodations}
                />
              )}

              {!viewMap && (
                <HotelList
                  accommodations={accommodations}
                  isPaginating={isPaginating}
                  loading={isFetching}
                  pageSize={pageSize}
                  total={totalAccommodations}
                  onPagination={this.onPagination}
                  onHotelSelect={this.goToDetail}
                  showProvider={searchParams.showProvider}
                  nights={nights}
                  rewards={
                    rewards
                      ? {
                          points: this.state.rewardsPoint,
                          rewards: rewards.response,
                          passengers: this.calculateTotalGuests(searchParams.rooms)
                        }
                      : undefined
                  }
                  financial={financialAdvertising}
                />
              )}
            </div>

            {isMobile && (
              <MobileBottomNavigation
                onViewFilters={this.onViewFilters}
                onViewSorting={this.onViewSorting}
                onViewMap={this.onViewMap}
                viewMap={this.state.viewMap}
              />
            )}
            {isMobile && this.showResults() && (
              <div>
                <Dialog fullScreen open={viewFilters}>
                  <div>
                    <div className={styles.closeFiltersStyle(theme.brand_colors.click_color)}>
                      <IconButton onClick={this.onCloseFilters}>
                        <Icon>close</Icon>
                      </IconButton>
                    </div>
                    <Filters
                      availableFilters={availableFilters}
                      appliedFilters={searchParams.filters}
                      onFilterChange={this.onFilterChange}
                      currencyCode={currencyCode}
                    />
                  </div>
                </Dialog>
                {availableSortingOptions && (
                  <Dialog fullScreen open={viewSorting}>
                    <div>
                      <div className={styles.closeFiltersStyle(theme.brand_colors.click_color)}>
                        <IconButton onClick={this.onCloseSorting}>
                          <Icon>close</Icon>
                        </IconButton>
                      </div>
                      <SortingOptions
                        availableSortingOptions={availableSortingOptions}
                        appliedSortingOption={searchParams.order_by}
                        onFilterChange={this.onFilterChange}
                      />
                    </div>
                  </Dialog>
                )}
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    queries: state.router.queries,
    pathname: state.router.pathname,
    availableFilters: state.search.availableFilters,
    availableSortingOptions: state.search.availableSortingOptions,
    searchParams: state.search.searchParams,
    pageSize: state.search.pageSize,
    shouldSearch: state.search.shouldSearch,
    accommodations: state.search.accommodations,
    isPaginating: state.search.isPaginating,
    isFetching: state.search.isFetching,
    totalAccommodations: state.search.total,
    regionName: state.search.regionName,
    currencyCode: getCurrencyCode(state.search.accommodations),
    rewards: state.search.applications ? state.search.applications.rewards : undefined
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchSearchAcommodations: searchParams => {
      dispatch(searchAccommodations(searchParams))
    },
    dispatchPush: path => {
      dispatch(push(path))
    },
    dispatchPagination: searchParams => {
      dispatch(searchPageAccommodations(searchParams))
    }
  }
}

function getCurrencyCode(accommodations: any[]): string {
  if (accommodations && accommodations.length > 0 && accommodations[0].rates && accommodations[0].rates.length > 0) {
    return accommodations[0].rates[0].currency
  }

  return ""
}

export default connect<StateProps, DispachProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(applications(theme(Result))))
