import React from "react"
import { css, keyframes } from "glamor"
import * as constants from "../../utils/constants"

import { Slider } from "../../types"

interface Props {
  slider: Slider
}

interface State {
  indexToShow: number
  hide: boolean
}

class SliderAdvertising extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      indexToShow: 0,
      hide: false
    }
  }

  componentDidMount() {
    const { slider } = this.props

    let interval = setInterval(() => {
      let indexToShow = this.state.indexToShow
      indexToShow++
      if (indexToShow === slider.images.length) {
        indexToShow = 0
      }
      this.setState({ indexToShow: indexToShow })
    }, constants.SLIDER_ANIMATION_INTERVAL)
    if (slider.duration > 0) {
      setTimeout(() => {
        clearInterval(interval)
        this.setState({ hide: true })
      }, slider.duration * 1000)
    }
  }

  render() {
    const { slider } = this.props
    const { hide, indexToShow } = this.state
    return (
      <div className={`${hide ? hideSliderContainer : sliderContainer}`}>
        {slider.images.map((image, i) =>
          image.link !== "" ? (
            <a
              rel="noopener noreferrer"
              target="_blank"
              key={i}
              href={image.link}
              className={`${slideItem(indexToShow === i)}`}
            >
              <img className={`${slideContent}`} alt={image.alt} src={image.url} />
            </a>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <div key={i} className={`${slideItem(indexToShow === i)}`}>
              <img className={`${slideContent}`} alt={image.alt} src={image.url} />
            </div>
          )
        )}
      </div>
    )
  }
}
const animateFade = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 }
})

const slideItem = (show: boolean) =>
  css({
    display: show ? "block" : "none",
    textAlign: "center",
    height: "100%",
    width: "100%",
    position: "relative",
    animation: `${animateFade} 0.5s`
  })

const slideContent = css({
  maxWidth: "100%",
  maxHeight: "100%"
})
const sliderContainer = css({
  width: "100%",
  maxHeight: "200px",
  marginBottom: "10px"
})
const hideSliderContainer = css({
  maxHeight: "0",
  overflow: "hidden",
  transition: "max-height .5s"
})

export default SliderAdvertising
