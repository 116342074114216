import * as React from "react"
import Chip from "@material-ui/core/Chip"
import { Theme, theme, ThemeProps } from "../../BassetProvider"
import { css } from "glamor"

interface Props extends ThemeProps {
  children: any
  onDelete: () => void
}

const AppliedFilter = ({ children, theme, onDelete }: Props) => (
  <Chip onDelete={onDelete} label={children} className={`${style(theme)}`} />
)

const style = (theme: Theme) =>
  css({
    backgroundColor: "#fff !important",
    height: "32px !important",
    border: `1px solid ${theme.brand_colors.click_color} !important`,
    " > svg, > span": {
      color: `${theme.brand_colors.click_color} !important`
    }
  })

export default theme(AppliedFilter)
