import { css, keyframes } from "emotion"
import { img } from "../../api/common"

const animatedBackground = keyframes({
  "0%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-4.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "24%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-4.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "25%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-1.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "49%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-1.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "50%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-2.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "74%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-2.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "75%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-3.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "98%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-3.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  },
  "99%": {
    background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-4.jpg) no-repeat 0 0 `,
    backgroundSize: "cover"
  }
})

export default {
  background: (val: string | undefined) => {
    let data = {}
    if (!val) {
      data = {
        background: `url(${process.env.REACT_APP_BASSET_STATICS_DOMAIN}/accommodations/home-bg-4.jpg) no-repeat 0 0`,
        animation: `${animatedBackground} 50s infinite`,
        animationFillMode: "both",
        animationTimingFunction: " linear"
      }
    } else {
      data = {
        background: "url(" + img(val) + ") no-repeat 0 0"
      }
    }
    return css({
      ...data,
      minHeight: "100%",
      backgroundSize: "cover",
      width: "100%",
      height: "auto",
      position: "fixed",
      top: "0",
      left: "0",
      zIndex: -1
    })
  },

  searchboxContainer: css({
    padding: "0px 8px",
    "@media(min-width:1200px)": {
      padding: "0px 16px",
      width: "352px"
    }
  }),

  filterContainer: css({
    "@media(min-width:1200px)": {
      maxWidth: "352px"
    }
  }),

  container: css({
    display: "flex",
    padding: "58px 0px 8px 0px",
    flexDirection: "column",
    "@media(min-width:1200px)": {
      padding: "160px 84px 24px 84px",
      flexDirection: "row"
    }
  })
}
