import * as React from "react"
import { getClientConfig } from "../api/common"
import GoogleTagManager from "./Common/GoogleTagManager"
import { RouteComponentProps, withRouter } from "react-router"
import i18n from "../i18n/i18n"

interface Props extends RouteComponentProps {
  children?: React.ReactNode
}

interface State {
  configuration: any
}

class Wrapper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      configuration: null
    }
  }

  componentDidMount() {
    this.getHeaderAndFooter()
    this.setLanguage()
  }

  setLanguage = () => {
    const { location } = this.props
    const qs = new URLSearchParams(location.search)
    if (qs.has("language")) {
      i18n.changeLanguage(qs.get("language")!)
    }
  }

  getHeaderAndFooter = async () => {
    const configuration = await getClientConfig()
    this.setState({ configuration: configuration })
  }

  render() {
    const { children } = this.props
    const { configuration } = this.state

    return (
      <div>
        {configuration && configuration.google_tag_manager_id.length > 0 && (
          <GoogleTagManager gtmId={configuration.google_tag_manager_id} />
        )}

        {configuration && configuration.header && <div dangerouslySetInnerHTML={{ __html: configuration.header }} />}

        <div>{children}</div>

        {configuration && configuration.footer && <div dangerouslySetInnerHTML={{ __html: configuration.footer }} />}
      </div>
    )
  }
}

export default withRouter(Wrapper)
