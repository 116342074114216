import * as React from "react"
import AnimateHeight from "react-animate-height"
import { css } from "glamor"
import Icon from "@material-ui/core/Icon"
import { theme, ThemeProps } from "../../BassetProvider"

interface Props extends ThemeProps {
  title: string
  children: React.ReactNode
}

interface State {
  hidden: boolean
}

class FilterSection extends React.PureComponent<Props, State> {
  state = {
    hidden: false
  }

  changeHidden = () => {
    this.setState({ hidden: !this.state.hidden })
  }

  render() {
    const { hidden } = this.state
    const { title, children, theme } = this.props
    const arrow = hidden ? "keyboard_arrow_down" : "keyboard_arrow_up"
    const height = hidden ? "0" : "auto"

    const arrowStyle = css({
      fontSize: "24px !important",
      color: `${theme.brand_colors.click_color} !important`
    })

    return (
      <div className={`${containerStyle}`}>
        <div className={`${headerStyle}`} onClick={this.changeHidden}>
          <p>{title}</p>
          <Icon className={`${arrowStyle}`}>{arrow}</Icon>
        </div>
        <AnimateHeight duration={150} height={height}>
          {children}
        </AnimateHeight>
      </div>
    )
  }
}

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "8px 0px"
})

const headerStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
  " > p": {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#4a4a4a"
  },
  cursor: "pointer"
})

export default theme(FilterSection)
