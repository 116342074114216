import ls from "localstorage-ttl"

export const makeApiRequest = async (uri: string) => {
  const config = await getClientConfig()
  const opts = {
    method: "GET",
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-client-id": config.id
    }
  }
  let qs = `site=${config.site}&channel=${config.channel}`
  if (!uri.includes("?")) {
    qs = `?${qs}`
  } else {
    qs = `&${qs}`
  }
  return fetchAsync(`${uri}${qs}`, opts)
}

export const getSearchConfiguration = async () => {
  const config = await getClientConfig()
  return config.search_configurations.accommodations
}

export const getClientConfig = async () => {
  const appURI = process.env.REACT_APP_API_URI
  if (!appURI) throw new Error("Missing REACT_APP_API_URI")

  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  const uri = appURI + "/clients/theme?domain=" + domain
  const cacheKey = getClientConfigCacheKey()
  const cachedConfig = ls.get(cacheKey)

  if (cachedConfig != null) {
    return JSON.parse(cachedConfig)
  }
  const config = await fetchAsync(uri, { headers: { "x-api-key": process.env.REACT_APP_API_KEY } })

  saveClientConfig(config)

  return config
}

const getClientConfigCacheKey = () => {
  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  return "theme_acc_" + domain
}

const saveClientConfig = config => {
  const ttl = process.env.REACT_APP_THEME_TTL
  if (!ttl) throw new Error("Missing REACT_APP_THEME_TTL")
  ls.set(getClientConfigCacheKey(), JSON.stringify(config), parseInt(ttl, 10))
}

const fetchAsync = async (url: string, options: any) => {
  const response = await fetch(url, options)
  const json = await response.json()
  return json
}

export const img = path => `${process.env.PUBLIC_URL}/img/${path}`
export const statics = path => `${process.env.REACT_APP_BASSET_STATIC_URL}/${path}`
