import {
  SEARCH_ACCOMMODATIONS_ERROR,
  SEARCH_ACCOMMODATIONS_FETCHING,
  SEARCH_ACCOMMODATIONS_FINISHED,
  SEARCH_PAGE_ACCOMODATIONS_FETCHING,
  SEARCH_PAGE_ACCOMODATIONS_FINISHED,
  LOCATION_CHANGE
} from "../actions/search"
import { queryToSearchParams } from "../utils/helpers"
import moment from "moment"

const initialState = {
  isFetching: true,
  isPaginating: false,
  shouldSearch: false,
  hasError: false,
  accommodations: [],
  total: 0,
  pageSize: 20,
  availableFilters: null,
  availableSortingOptions: null,
  searchParams: {
    location: {
      id: "",
      type: ""
    },
    checkin: moment(),
    checkout: moment(),
    adults: 2,
    children: [],
    pagination: {
      offset: 0,
      limit: 20 // FIXME: hardcoded
    },
    showProvider: false,
    filters: {},
    sorting: "rank"
  }
}

export default function searchReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case SEARCH_ACCOMMODATIONS_FETCHING:
      return {
        ...state,
        availableFilters: null,
        availableSortingOptions: null,
        accommodations: [],
        total: 0,
        regionName: null,
        isFetching: true,
        shouldSearch: false
      }
    case SEARCH_ACCOMMODATIONS_FINISHED:
      return {
        ...state,
        isFetching: false,
        availableFilters: action.result.filters,
        availableSortingOptions: action.result.sorting,
        accommodations: action.result.clusters,
        total: action.result.total,
        regionName: action.result.region_name,
        shouldSearch: false,
        applications: action.result.applications
      }
    case SEARCH_ACCOMMODATIONS_ERROR:
      return { ...state, isFetching: false, hasError: true, shouldSearch: false }
    case SEARCH_PAGE_ACCOMODATIONS_FETCHING:
      return { ...state, isPaginating: true, shouldSearch: false }
    case SEARCH_PAGE_ACCOMODATIONS_FINISHED:
      return {
        ...state,
        isPaginating: false,
        accommodations: [...state.accommodations, ...action.accommodations]
      }
    case LOCATION_CHANGE: {
      if (!action.payload.pathname.startsWith("/accommodations/result")) return state
      return {
        ...state,
        searchParams: queryToSearchParams(action.payload.queries, { offset: 0, limit: state.pageSize }),
        shouldSearch: true
      }
    }
    default:
      return state
  }
}
