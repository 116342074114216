import React from "react"
import { Switch, Route } from "react-router-dom"
import Result from "./components/Result/Result"
import Detail from "./components/Detail/Detail"
import AppLoader from "./components/AppLoader"
import Home from "./components/Home/Home"

export default () => (
  <Switch>
    <Route path="/accommodations/results" component={Result} />
    <Route path="/accommodations/details/:id" component={Detail} />
    <Route path="/accommodations/loader" component={AppLoader} />
    <Route path="/accommodations/" component={Home} />
  </Switch>
)
