import * as React from "react"
import { css } from "glamor"

//Components
import Tooltip from "@material-ui/core/Tooltip"

interface Props {
  error?: string
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
  children: React.ReactElement<any>
}

const tooltipTextLength = 50
const defaultTop = 8

const ErrorTooltip = ({ error, placement, children }: Props) => {
  const top: string =
    error && error.length > 0
      ? (Math.round(error.length / tooltipTextLength) * defaultTop).toString().concat("px")
      : "0"
  const popperStyle = css({
    "&[x-placement*='bottom'] > div > span": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent #d0011b transparent`
      }
    },
    "&[x-placement*='top'] > div > span": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "100%",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `#d0011b transparent transparent transparent`
      }
    },
    "&[x-placement*='right'] > div > span": {
      left: "-1px",
      top: top,
      fontSize: "10px",
      transform: "rotate(-45deg)",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `#d0011b transparent transparent`
      }
    },
    "&[x-placement*='left'] > div > span": {
      right: "0",
      transform: "rotate(45deg)",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent #d0011b`
      }
    }
  })

  const openTooltip = (error?: string): boolean => {
    if (error) return error.length > 0
    else return false
  }

  return (
    <Tooltip
      classes={{ popper: `${popperStyle}`, tooltip: `${toolTipStyle}` }}
      placement={placement}
      open={openTooltip(error)}
      title={
        <React.Fragment>
          {error}
          <span className={`${arrowStyle}`} />
        </React.Fragment>
      }
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      {children}
    </Tooltip>
  )
}

const toolTipStyle = css({
  color: "#ffffff !important",
  backgroundColor: "#d0011b !important",
  fontSize: "12px !important",
  padding: "6px 10px !important"
})

const arrowStyle = css({
  position: "absolute",
  fontSize: "8px",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid"
  }
})

export default ErrorTooltip
