import * as React from "react"
import { css } from "glamor"

interface Props {
  value: number
}

const DiscountLabel = ({ value }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      <p className={`${textStyle}`}>{`${value}% OFF`}</p>
    </div>
  )
}

const textStyle = css({
  display: "inline-flex",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  color: "#fff",
  alignItems: "center"
})

const containerStyle = css({
  padding: "1px 8px",
  display: "inline-flex",
  borderRadius: "8px",
  backgroundColor: "#02ae02"
})

export default DiscountLabel
