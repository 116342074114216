import React from "react"
import { css } from "glamor"
import AppliedFilter from "./AppliedFilter"
import Stars from "../../Common/Stars"
import _ from "underscore"

interface Props {
  availableFilters: any
  appliedFilters: any
  onFilterChange: (filterKey: string, availableFilters: string[]) => void
  currencyCode: string
}

const ActiveFilters = ({ availableFilters, appliedFilters, onFilterChange, currencyCode }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      <p>Filtros activos</p>
      <div className={`${appliedFiltersStyle}`}>
        {createFilter("stars", appliedFilters.stars, availableFilters.stars, onFilterChange, starsDecorator)}
        {createFilter("zone", appliedFilters.zone, availableFilters.zone, onFilterChange)}
        {createFilter("category", appliedFilters.category, availableFilters.category, onFilterChange)}
        {createFilter("amenities", appliedFilters.amenities, availableFilters.amenities, onFilterChange)}
        {createFilter("name", appliedFilters.name, availableFilters.name, onFilterChange)}
        {createPriceFilter(appliedFilters.price, onFilterChange, currencyCode)}
      </div>
    </div>
  )
}

// TODO: FIX Currency
const createPriceFilter = (
  appliedFilters: string[],
  onFilterChange: (filterKey: string, availableFilters: string[]) => void,
  currencyCode: string
) => {
  if (!appliedFilters) return
  return (
    <AppliedFilter onDelete={() => onFilterChange("price", [])}>
      {`${currencyCode} ${appliedFilters[0]} - ${currencyCode} ${appliedFilters[1]}`}
    </AppliedFilter>
  )
}

const createFilter = (
  filterKey: string,
  appliedFilters: string[],
  availableFilters: any[] | undefined,
  onFilterChange: (filterKey: string, availableFilters: string[]) => void,
  filterDecorator?: (filterKey: string, availableFilters: any[]) => any
) => {
  if (!appliedFilters) return
  return appliedFilters.map((e, i) => {
    const children = filterDecorator ? filterDecorator(e, availableFilters!) : defaultDecorator(e, availableFilters)

    return (
      <AppliedFilter key={i} onDelete={() => onFilterChange(filterKey, _.without(appliedFilters, e))}>
        {children}
      </AppliedFilter>
    )
  })
}

const defaultDecorator = (value: string, availableFilters?: any[]) => {
  if (availableFilters) {
    const item = _.find(availableFilters, e => e.value === value)
    return item.label
  }
  return value
}

const starsDecorator = (value: string) => {
  return (
    <p style={{ display: "flex", flexDirection: "row" }}>
      {value} <Stars amount={1} />
    </p>
  )
}

const appliedFiltersStyle = css({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  paddingBottom: "4px",
  " > div": {
    margin: "4px"
  }
})

const containerStyle = css({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "344px",
  flexDirection: "column",
  " > p": {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#4a4a4a"
  }
})

export default ActiveFilters
