import * as React from "react"
import Icon from "../Icon"
const icon = {
  p: [
    "M682.966 370.684l56.075-56.075c-192.022-191.923-504.406-191.923-696.374 0l56.059 56.075c161.089-161.086 423.146-161.086 584.24 0z",
    "M331.040 567.838c-26.91 26.943-26.91 70.567 0 97.502 26.91 26.88 70.57 26.88 97.449-0.018 26.973-26.918 26.973-70.542 0-97.485-26.88-26.915-70.539-26.915-97.449 0z",
    "M783.18 598.253c-5.65 0-10.315 2.808-13.999 7.987-3.684 5.191-5.498 12.386-5.435 21.347h38.685c-0.185-8.96-2.12-16.837-5.804-21.747-3.686-4.916-8.165-7.586-13.447-7.586z",
    "M884.386 598.253c-5.65 0-10.318 2.808-14.002 7.987-3.684 5.191-5.495 12.386-5.435 21.347h38.685c-0.182-8.96-2.118-16.837-5.802-21.747-3.684-4.916-8.165-7.586-13.447-7.586z",
    "M928.591 641.925h-64.844c0.182 7.59 2.269 13.989 6.262 18.546 3.99 4.562 8.967 6.545 14.92 6.545 4.056 0 7.464-1.326 10.227-3.689 2.763-2.358 4.848-6.232 6.262-11.479l25.792 4.59c-3.317 10.095-8.552 17.761-15.704 23.039-7.155 5.278-16.104 7.909-26.852 7.909-17.010 0-29.597-5.941-37.764-17.804-6.449-9.503-9.673-21.503-9.673-35.993 0-17.308 4.238-30.865 12.711-40.664 8.476-9.799 19.191-14.705 32.147-14.705 14.553 0 26.035 5.715 34.45 15.978 8.413 10.257 12.435 24.957 12.066 47.728zM826.314 641.925h-64.844c0.182 7.59 2.269 13.989 6.262 18.546 3.992 4.562 8.967 6.545 14.92 6.545 4.053 0 7.461-1.326 10.229-3.689 2.76-2.358 4.848-6.232 6.259-11.479l25.792 4.59c-3.317 10.095-8.549 17.761-15.704 23.039s-16.107 7.909-26.849 7.909c-17.012 0-29.6-5.941-37.767-17.804-6.447-9.503-9.67-21.503-9.67-35.993 0-17.308 4.238-30.865 12.711-40.664s19.191-14.705 32.147-14.705c14.553 0 26.032 5.715 34.445 15.978 8.415 10.257 12.438 24.957 12.069 47.728zM725.97 607.525c-4.362-3.016-8.413-4.524-12.157-4.524-3.626 0-6.695 1.068-9.212 3.196-2.52 2.133-4.352 5.981-5.794 11.555-1.442 5.571-2.014 17.243-2.014 35.009v32.175h-25.301v-103.734h22.771v14.227c5.060-7.013 8.010-11.636 11.294-13.865 3.284-2.226 7.112-3.345 11.289-3.345 5.895 0 11.626 1.738 17.088 5.215l-7.965 24.092zM656.312 566.022h-63.252v32.891h55.662v25.301h-55.662v60.722h-27.831v-144.216h91.084v25.301zM821.135 429.396h-155.070c-22.227 0-43.381 4.577-62.595 12.812-120.182-116.812-312.892-115.782-431.769 3.079l55.981 56.021c85.606-85.616 222.783-88.847 312.343-9.807-20.668 26.834-32.99 60.434-32.99 96.926v53.863c0 87.827 71.202 159.029 159.029 159.029h155.070c87.827 0 159.029-71.202 159.029-159.029v-53.863c0-87.83-71.202-159.032-159.029-159.032z"
  ],
  a: [
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" }
  ]
}
const FreeWifi = (props: any) => {
  return <Icon icon={icon} {...props} />
}
export default FreeWifi
