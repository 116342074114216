import * as React from "react"
import FieldContainer from "./FieldContainer"
import DropdownSelect from "./DropdownSelect"
import FaceIcon from "@material-ui/icons/Face"

interface Props {
  index: number
  age: number
  onChildrenAgeChange: (i: number, v: number) => void
}

const ChildrenAgeSelector = ({ index, age, onChildrenAgeChange }: Props) => {
  return (
    <div>
      <FieldContainer icon={<FaceIcon style={{ fontSize: "16px" }} />} label={`${index + 1}`}>
        <DropdownSelect
          value={`${age}`}
          options={Array.apply(null, Array(18)).map((e: any, i: number) => ({ value: i, text: `${i}` }))}
          onChange={(value: any) => {
            onChildrenAgeChange(index, value)
          }}
        />
      </FieldContainer>
    </div>
  )
}

export default ChildrenAgeSelector
