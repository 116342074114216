import { SearchParams } from "../types"
import { getClientConfig } from "./common"
import { searchParamsToQueryString } from "../utils/helpers"

export const getAccommodationInformation = async (id: string) => {
  const url = process.env.REACT_APP_ACCOMMODATIONS_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!url) throw new Error("missing key REACT_APP_ACCOMMODATIONS_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  // FIXME: language hardcoded
  const config = await getClientConfig()
  const language = config.language.substring(0, 2) //only get es/en
  const res = await fetch(`${url}/${id}?language=${language}&channel=${config.channel}`, {
    headers: {
      "x-client-id": config.id,
      "x-api-key": apiKey
    }
  })
  if (res.ok) {
    const accommodation = await res.json()
    return accommodation
  }
  throw new Error("Failed to get accommodation")
}

export const getRoomClusters = async (
  hotelID: string,
  checkin: string,
  checkout: string,
  distribution: string,
  rate_type: string
) => {
  const baseURL = process.env.REACT_APP_ROOM_CLUSTERS_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_ROOM_CLUSTERS_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  const config = await getClientConfig()
  let uri = `${baseURL}?hotel_id=${hotelID}&checkin=${checkin}&checkout=${checkout}&distribution=${distribution}&site=${config.site}&channel=${config.channel}&currency=ARS&language=es-MX` // FIXME: currency & language hardcoded
  if (rate_type) uri += `&rate_type=${rate_type}`
  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.id
    }
  })
  if (res.ok) {
    return res.json()
  }
  throw new Error("Failed to get availability")
}

export const checkRate = async (rateID: string) => {
  const baseURL = process.env.REACT_APP_RATES_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_ROOM_CLUSTERS_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  const config = await getClientConfig()

  const uri = `${baseURL}/${rateID}/price-check?site=${config.site}&channel=${config.channel}`
  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.id
    }
  })
  if (res.ok) {
    return res.json()
  }
  throw new Error("Failed to get availability")
}

export const searchAPIAccommodations = async (searchParams: SearchParams) => {
  let baseURL = process.env.REACT_APP_ACCOMMODATIONS_SEARCH_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_ACCOMMODATIONS_SEARCH_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const config = await getClientConfig()

  let uri = `${baseURL}?${searchParamsToQueryString(searchParams)}&site=${config.site}&channel=${
    config.channel
  }&currency=ARS&language=es-MX` // FIXME: currency & language hardcoded
  if (searchParams.rate_type) uri += `&rate_type=${searchParams.rate_type}`
  //Applications - REWARDS
  const applications = config.applications
  const applyRewards = applications && applications.some(a => a.category === "REWARDS")
  if (applyRewards) uri += "&app=REWARDS"

  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.id
    }
  })

  if (res.ok) {
    return res.json()
  }

  throw new Error("Failed to get availability")
}

export const getRewardsPoints = async (searchParams: SearchParams, minPrice: number, maxPrice: number) => {
  const baseURL = process.env.REACT_APP_REWARDS_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_ACCOMMODATIONS_SEARCH_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  const config = await getClientConfig()
  const uri = `${baseURL}?site=${config.site}&channel=${config.channel}&${searchParamsToQueryString(
    searchParams
  )}&min_price=${minPrice}&max_price=${maxPrice}&currency=ARS&language=es-MX` // FIXME: currency & language hardcoded
  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.id
    }
  })
  if (res.ok) {
    return res.json()
  }
  throw new Error("Failed to get availability")
}
