import { css } from "emotion"

export default {
  searchboxContainerStyle: css({
    padding: "0px 8px",
    "@media(min-width:1200px),": {
      padding: "0px 16px",
      width: "352px"
    }
  }),

  filterContainerStyle: css({
    "@media(min-width:1200px),": {
      maxWidth: "352px"
    }
  }),

  hotelListStyle: css({
    paddingBottom: "56px",
    flex: "1 0 0%",
    "@media(min-width:1200px),": {
      padding: "0",
      width: "1000px"
    },
    "@media(max-width:600px),": {
      padding: "0",
      width: "100%"
    }
  }),

  containerStyle: css({
    display: "flex",
    padding: "8px 0px",
    flexDirection: "column",
    "@media(min-width:1200px),": {
      padding: "24px 0px",
      flexDirection: "row",
      margin: "0 auto",
      width: "fit-content"
    }
  }),

  backgroundStyle: css({
    backgroundColor: "#f9f9f9",
    minHeight: "100vh"
  }),

  closeFiltersStyle: (clickColor: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      " > button": {
        color: `${clickColor} !important`,
        "&:focus": {
          outline: 0
        }
      }
    }),

  progressStyle: (highlightColor: string) =>
    css({
      " > div": {
        backgroundColor: `${highlightColor} !important`
      }
    })
}
