import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import { css } from "glamor"
import * as React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { getProviderName, formatPrice } from "../../../utils/helpers"

interface Props extends WithTranslation {
  rates: any[]
  site?: string
}

interface State {
  open: boolean
}

interface ProviderOptionProps {
  rate: any
  site?: string
}

const ProviderOption = ({ rate, site }: ProviderOptionProps) => {
  const ratePrice = `${rate.fare.currency} ${formatPrice(rate.fare.total, site)}`
  return (
    <p className={`${providerOptionStyle}`}>
      {getProviderName(rate.provider)} <br /> <b>{ratePrice}</b>
    </p>
  )
}

class ProviderRatesOptions extends React.Component<Props, State> {
  state = {
    open: false
  }

  anchorEl: any

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose = (event: any) => {
    if (this.anchorEl && this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  render() {
    const { t, rates, site } = this.props

    return (
      <div className={`${providerOptionsStyle}`}>
        {rates.slice(0, 3).map((_rate, i) => (
          <ProviderOption rate={_rate} key={i} site={site} />
        ))}
        {rates.length > 3 && (
          <div>
            <Button
              fullWidth={true}
              classes={{ root: `${linkRootStyle}` }}
              buttonRef={(node: any) => {
                this.anchorEl = node
              }}
              aria-owns={this.state.open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              {t("ProviderRatesOptions.more_providers")} <ArrowDropDown fontSize="small" />
            </Button>
            <Popper open={this.state.open} anchorEl={this.anchorEl} transition placement="bottom">
              {({ TransitionProps, placement }: any) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <div className={`${providersOptionsPopupStyle}`}>
                        {rates.slice(3).map((_rate, i) => (
                          <ProviderOption rate={_rate} key={i} site={site} />
                        ))}
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </div>
    )
  }
}

const providerOptionsStyle = css({
  padding: "24px 8px",
  width: "160px",
  lineHeight: "1.33",
  borderRight: "1px solid #eaeaea",
  borderLeft: "1px solid #eaeaea",
  " > a": {
    fontSize: "13px",
    " > svg": {
      verticalAlign: "top"
    }
  }
})

const providerOptionStyle = css({
  textTransform: "capitalize",
  fontWeight: "300",
  fontSize: "12px",
  marginBottom: "24px",
  textAlign: "center",
  " > b": {
    fontSize: "14px"
  }
})

const providersOptionsPopupStyle = css({
  padding: "24px 16px",
  lineHeight: "1.33",
  width: "144px",
  " > p:last-child": {
    marginBottom: "0"
  }
})

const linkRootStyle = css({
  textTransform: "none !important",
  fontSize: "14px !important",
  padding: "0 !important",
  color: "#126aff !important",
  fontWeight: "400 !important",
  minHeight: "20px !important",
  ":hover": {
    backgroundColor: "transparent !important"
  }
})

export default withTranslation()(ProviderRatesOptions)
