import * as React from "react"
import { css, keyframes } from "glamor"
import * as componentQueries from "react-component-queries"
import LoadingRooms from "./LoadingRooms"
import LoadingButton from "./LoadingButton"

interface Props {
  mode?: string
}

const LoadingDetail = ({ mode }: Props) => {
  const isMobile = mode === "narrow"
  return (
    <div>
      <div className={`${hotelDetailContainerStyle}`}>
        <div className={[animatedBgStyle, hotelImgStyle].join(" ")} />
        <div className={`${detailBreakdownStyle}`}>
          <div className={[animatedBgStyle, hotelNameStyle].join(" ")} />
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "40%" }} />
          {!isMobile && (
            <div style={{ marginTop: "64px", marginBottom: "40px" }}>
              <div
                className={[animatedBgStyle, loadingBarStyle].join(" ")}
                style={{ width: "60%", marginBottom: "8px" }}
              />
              <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "60%" }} />
            </div>
          )}
          <LoadingButton />
        </div>
      </div>
      <div className={`${amenitiesAndCheckinStyle}`}>
        <div className={`${amenitiesStyle}`}>
          <div style={{ width: isMobile ? "52px" : "72px" }} className={[animatedBgStyle, loadingBarStyle].join(" ")} />
          <div
            style={{ width: isMobile ? "161px" : "224px" }}
            className={[animatedBgStyle, loadingBarStyle].join(" ")}
          />
          <div style={{ width: isMobile ? "52px" : "72px" }} className={[animatedBgStyle, loadingBarStyle].join(" ")} />
        </div>
        <div className={`${checkinCheckout}`}>
          <div style={{ width: "72px" }} className={[animatedBgStyle, loadingBarStyle].join(" ")} />
          <div style={{ width: "72px" }} className={[animatedBgStyle, loadingBarStyle].join(" ")} />
          <div style={{ width: "72px" }} className={[animatedBgStyle, loadingBarStyle].join(" ")} />
        </div>
      </div>
      <LoadingRooms />
    </div>
  )
}

const checkinCheckout = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flex: "1 0 0%",
  padding: "16px",
  "@media(min-width:1200px)": {
    padding: "32px 64px 24px 24px"
  }
})

const amenitiesStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  padding: "16px",
  borderBottom: "1px solid #eaeaea",
  "@media(min-width:1200px)": {
    borderBottom: 0,
    padding: "32px 64px 24px 16px",
    borderRight: "1px solid #eaeaea",
    width: "560px"
  }
})

const amenitiesAndCheckinStyle = css({
  marginTop: "8px",
  display: "flex",
  flexDirection: "column",
  borderTop: "1px solid #eaeaea",
  borderBottom: "1px solid #eaeaea",
  marginBottom: "8px",
  "@media(min-width:1200px)": {
    flexDirection: "row"
  }
})

const hotelImgStyle = css({
  width: "100%",
  height: "360px",
  "@media(min-width: 1200px)": {
    width: "664px",
    height: "400px"
  }
})

const hotelNameStyle = css({
  width: "80%",
  borderRadius: "12px",
  height: "24px",
  marginBottom: "16px",
  "@media(min-width:1200px)": {
    marginBottom: "40px"
  }
})

const detailBreakdownStyle = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: "1 0 0%",
  backgroundColor: "#fff",
  padding: "8px",
  "@media(min-width:1200px)": {
    width: "440px",
    padding: "32px 24px"
  }
})

const loadingBarStyle = css({
  height: "16px",
  borderRadius: "8px"
})

const hotelDetailContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
  "@media(min-width:1200px)": {
    flexDirection: "row"
  }
})

const placeHolderShimmer = keyframes({
  "0%": {
    backgroundPosition: "-468px 0"
  },
  "100%": {
    backgroundPosition: "468px 0"
  }
})

const animatedBgStyle = css({
  animationDuration: "1s",
  animationFillMode: "forwards",
  animationIterationCount: "infinite",
  animationName: `${placeHolderShimmer}`,
  animationTimingFunction: "linear",
  background: "linear-gradient(to right, #dedede 8%, #ccc 18%, #dedede 33%)",
  backgroundSize: "800px 104px"
})

export default componentQueries(({ width }: any) => ({
  mode: width < 1100 ? "narrow" : "wide"
}))(LoadingDetail)
