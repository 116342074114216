import * as React from "react"
import { css } from "glamor"
import * as componentQueries from "react-component-queries"
import Button from "@material-ui/core/Button"
import AnimateHeight from "react-animate-height"
import { Theme, theme, ThemeProps } from "../BassetProvider"
import { WithTranslation, withTranslation } from "react-i18next"

interface Props extends ThemeProps, WithTranslation {
  amenities: any[]
  mode?: string
  id?: string
}

class HotelAmenities extends React.PureComponent<Props, any> {
  state = {
    showAll: false
  }

  onClick = () => {
    this.setState({ showAll: !this.state.showAll })
  }

  render() {
    const { amenities, mode, id, t, theme } = this.props
    const { showAll } = this.state
    const isMobile = mode === "narrow"
    const shouldShowMoreButton = isMobile && amenities.length > 10

    return (
      <div id={id} className={`${containerStyle(theme)}`}>
        <p>Servicios</p>
        <AnimateHeight duration={200} height={shouldShowMoreButton && !showAll ? 230 : "auto"}>
          <div className={`${amenitiesContainerStyle}`}>
            {amenities.map((e, i) => (
              <p key={i}>{`• ${e.name}`}</p>
            ))}
          </div>
        </AnimateHeight>
        {shouldShowMoreButton && (
          <Button className={`${buttonStyle(theme)}`} onClick={this.onClick}>
            {showAll ? t("HotelAmenities.see_less") : t("HotelAmenities.see_more")}
          </Button>
        )}
      </div>
    )
  }
}

const amenitiesContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  "@media(min-width:1200px)": {
    flexWrap: "wrap",
    flexDirection: "row"
  },
  " > p": {
    fontSize: "14px",
    lineHeight: "1.71",
    color: "#4a4a4a",
    fontWeight: "300",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "@media(min-width:1200px)": {
      width: "25%"
    }
  }
})

const buttonStyle = (theme: Theme) =>
  css({
    fontSize: "16px !important",
    color: `${theme.brand_colors.click_color} !important`,
    width: "100% !important",
    display: "flex",
    flexGrow: "0",
    lineHeight: "1.5 !important",
    textTransform: "none !important",
    fontWeight: "300 !important",
    padding: "4px !important",
    minHeight: "24px !important",
    marginTop: "8px !important",
    minWidth: "0px !important"
  })

const containerStyle = (theme: Theme) =>
  css({
    display: "flex",
    backgroundColor: "#fff",
    flexDirection: "column",
    padding: "8px",
    margin: "0 8px",
    " > p": {
      fontSize: "18px",
      fontWeight: "300",
      lineHeight: "1.33",
      color: theme.brand_colors.highlight_color,
      marginBottom: "8px"
    },
    "@media(min-width: 1200px)": {
      padding: "24px",
      margin: "0px"
    }
  })

export default componentQueries(({ width }: any) => ({
  mode: width < 1104 ? "narrow" : "wide"
}))(withTranslation()(theme(HotelAmenities)))
