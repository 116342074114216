import * as React from "react"
import { css } from "glamor"
import Icon from "@material-ui/core/Icon"
import Button from "@material-ui/core/Button"
import * as componentQueries from "react-component-queries"
import AccommodationImagesCarousel from "./AccommodationImagesCarousel"
import Stars from "../Common/Stars"
import TripadvisorRating from "../Common/TripadvisorRating"
import { withTranslation, WithTranslation } from "react-i18next"
import { Theme, theme, ThemeProps } from "../BassetProvider"
import moment from "moment"
import LoadingButton from "./LoadingButton"
import { formatPrice, setFormatPrice } from "../../utils/helpers"
import DiscountLabel from "../Common/DiscountLabel"
import StrikethroughPrice from "../Common/StrikethroughPrice"
import { Points, calculateCash, calculatePointsByRate } from "../../utils/rewards"
import { roundingWithStep } from "../../utils/helpers"
moment.locale("es")

interface Props extends ThemeProps, WithTranslation {
  name: string
  rating?: number
  stars: number
  address: string
  photos: any[]
  fare: any
  checkin?: string
  checkout?: string
  totalGuests?: number
  totalRooms?: number
  onViewRooms: () => void
  fetchingRates?: boolean
  noAvailability?: boolean
  onOpenSearch: () => void
  mode?: string
  hideTaxNotIncluded?: boolean
  onViewMap: () => void
  rewards?: Points
  channel?: string
  financial?: any
  site?: string
}

class HotelDetailSummary extends React.PureComponent<Props, any> {
  render() {
    const {
      mode,
      onViewRooms,
      name,
      rating,
      stars,
      photos,
      address,
      fare,
      checkin,
      checkout,
      totalGuests,
      totalRooms,
      theme,
      noAvailability,
      fetchingRates,
      onOpenSearch,
      hideTaxNotIncluded,
      t,
      onViewMap,
      rewards,
      channel,
      financial,
      site
    } = this.props
    const isMobile = mode === "narrow"

    const checkinDate = checkin ? moment(checkin) : null
    const checkoutDate = checkout ? moment(checkout) : null

    let cash = 0,
      points = 0
    if (rewards) {
      points = calculatePointsByRate(
        fare,
        roundingWithStep(rewards.points, rewards.rewards.precision),
        rewards.rewards,
        rewards.passengers
      )
      cash = calculateCash(
        fare,
        roundingWithStep(rewards.points, rewards.rewards.precision),
        rewards.rewards,
        rewards.passengers
      )
    }

    return (
      <div className={`${mainContainerStyle}`}>
        <div className={`${imgContainerStyle}`}>
          <AccommodationImagesCarousel maxHeight={isMobile ? "375px" : "400px"} imgs={photos.map(e => e.url)} />
        </div>
        <div className={`${summaryContainerStyle}`}>
          <div>
            <p className={`${hotelTitleStyle}`}>{name}</p>
            {stars && stars > 0 && <Stars amount={stars} size={isMobile ? "12px" : "16px"} />}
            <p className={`${addressStyleLink(theme)}`}>
              <Icon>location_on</Icon>
              <span>{`${address} - `}</span>
              <button onClick={onViewMap}>{t("HotelDetailSummary.see_on_map")}</button>
            </p>
            {rating && <TripadvisorRating rating={rating} />}
          </div>
          {!isMobile && checkinDate && checkoutDate && totalGuests && (
            <div className={`${searchedInfoStyle(theme)}`}>
              <div className={`${searchedInfoRowStyle(theme)}`}>
                <Icon style={{ marginRight: "8px" }}>event</Icon>
                <span style={{ fontWeight: 500 }}>{t("HotelDetailSummary.dates")}: </span>
                <span style={{ color: "#4a4a4a" }}>
                  {checkinDate.format("DD MMM YYYY")} - {checkoutDate.format("DD MMM YYYY")}
                </span>
              </div>
              <div className={`${searchedInfoRowStyle(theme)}`}>
                <div>
                  <Icon style={{ marginRight: "8px" }}>hotel</Icon>
                  <span style={{ fontWeight: 500 }}>{t("HotelDetailSummary.rooms")}: </span>
                  <span style={{ color: "#4a4a4a" }}>{totalRooms}</span>
                </div>
                <div>
                  <Icon style={{ marginRight: "8px", marginLeft: "18px" }}>person</Icon>
                  <span style={{ fontWeight: 500 }}>{t("HotelDetailSummary.guests")}: </span>
                  <span style={{ color: "#4a4a4a" }}>{totalGuests}</span>
                </div>
              </div>
              <div className={`${searchedInfoRowStyle(theme)}`}>
                <button onClick={onOpenSearch}>{t("HotelDetailSummary.edit_search")}</button>
              </div>
            </div>
          )}
          {fetchingRates && <LoadingButton />}
          {!fetchingRates && noAvailability && (
            <div className={`${priceDetailContainerStyle}`}>
              <div className={`${noAvailabilityContainer}`}>
                <p>{t("HotelDetailSummary.no_availability_found")}</p>
                <p>{t("HotelDetailSummary.try_other_dates")}</p>
              </div>
              <Button variant="contained" onClick={onOpenSearch} className={`${viewRoomsButtonStyle(theme)}`}>
                {t("HotelDetailSummary.change_dates")}
              </Button>
            </div>
          )}
          {!fetchingRates && fare && fare.total && fare.currency && (
            <div className={`${priceDetailContainerStyle}`}>
              {fare.discount && (
                <div className={`${strikethroughStyle}`}>
                  <StrikethroughPrice
                    value={`${fare.currency} ${formatPrice(
                      channel && channel === "DESKTOP" ? fare.discount.total : fare.discount.base_rate,
                      site
                    )}`}
                  />
                  <DiscountLabel value={Math.floor(fare.discount.discount)} />
                </div>
              )}
              <div className={`${priceStyle}`}>
                {rewards && (
                  <span>
                    {setFormatPrice(roundingWithStep(points, rewards.rewards.precision), 0, 0)} {t("rewards.points")}
                    <span className={`${highlight}`}> a canjear </span>
                    {cash > 0 && (
                      <>
                        + <br />
                        {`${fare.currency} ${formatPrice(cash, site)} `}{" "}
                        {financial && (
                          <span>
                            {t("FinancialAdvertising.installments_financial_rewards", {
                              installments: financial.installments
                            })}
                          </span>
                        )}
                      </>
                    )}
                  </span>
                )}
                <span>
                  {!rewards && (
                    <>
                      <span>{`${fare.currency} ${formatPrice(fare.total, site)}`} </span>
                      <span>{t("HotelDetailSummary.total_price")}</span>
                    </>
                  )}
                </span>
              </div>
              {!hideTaxNotIncluded && (
                <>
                  {rewards ? (
                    <p className={`${disclamerStyle}`}>{t("AccommodationClusterPrice.fee_not_included")}</p>
                  ) : (
                    <p className={`${disclamerStyle}`}>{t("HotelDetailSummary.taxes_not_included")}</p>
                  )}
                </>
              )}
              <Button variant="contained" onClick={onViewRooms} className={`${viewRoomsButtonStyle(theme)}`}>
                {t("HotelDetailSummary.see_rooms")}
              </Button>{" "}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const strikethroughStyle = css({
  "& > *:first-child": {
    marginRight: "16px"
  }
})

const noAvailabilityContainer = css({
  display: "flex",
  flexDirection: "column",
  " >p": {
    fontSize: "12px",
    lineHeight: "2",
    fontWeight: "300",
    color: "#d0021b"
  },
  " >p:last-child": {
    lineHeight: "1.33",
    color: "#4a4a4a"
  }
})

const priceStyle = css({
  color: "#4a4a4a",
  " > span": {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "1.33",
    marginRight: "8px"
  },
  " > span > span:last-child": {
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "1.71"
  }
})

const highlight = css({
  fontSize: "14px",
  fontWeight: "300",
  lineHeight: "1.71"
})

const hotelTitleStyle = css({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "1.5",
  color: "#4a4a4a",
  "@media(min-width:1200px)": {
    fontSize: "24px",
    lineHeight: "1.33"
  }
})

const priceDetailContainerStyle = css({
  marginTop: "8px"
})

const disclamerStyle = css({
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "1.33",
  color: "#9b9b9b"
})

const mainContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
  width: "100vw",
  "@media(min-width: 1200px)": {
    width: "1104px",
    maxHeight: "400px",
    flexDirection: "row"
  }
})

const summaryContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "8px",
  "@media(min-width:1200px)": {
    padding: "32px 24px",
    justifyContent: "space-between"
  }
})

const imgContainerStyle = css({
  width: "100%",
  height: "375px",
  "@media(min-width:1200px)": {
    width: "664px",
    height: "400px"
  }
})

const viewRoomsButtonStyle = (theme: Theme) =>
  css({
    color: "#fff !important",
    marginTop: "16px !important",
    fontSize: "16px !important",
    fontWeight: "300 !important",
    width: "100% !important",
    textTransform: "none !important",
    backgroundColor: `${theme.brand_colors.click_color} !important`,
    "@media(min-width:1200px)": {
      width: "auto !important"
    }
  })

const searchedInfoRowStyle = (theme: Theme) =>
  css({
    display: "flex",
    flexDirection: "row",
    marginBottom: "8px",
    " > button": {
      color: theme.brand_colors.click_color,
      cursor: "pointer",
      fontWeight: "300",
      fontSize: "12px",
      background: "none !important",
      border: "none",
      "&:focus": {
        outline: 0
      }
    }
  })

const searchedInfoStyle = (theme: Theme) =>
  css({
    fontSize: "14px",
    color: theme.brand_colors.highlight_color,
    " span": {
      fontSize: "14px !important",
      color: `${theme.brand_colors.highlight_color}`
    },
    lineHeight: "1.14",
    display: "flex",
    flexDirection: "column"
  })

const addressStyleLink = (theme: Theme) =>
  css({
    marginTop: "8px",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "1.14",
    fontWeight: "300",
    color: "#4a4a4a",
    " > span": {
      fontSize: "14px !important"
    },
    " > button": {
      color: theme.brand_colors.click_color,
      cursor: "pointer",
      background: "none !important",
      border: "none"
    },
    " > button:focus": {
      outline: 0
    },
    "@media(min-width:1200px)": {
      marginTop: "8px"
    }
  })

export default componentQueries(({ width }: any) => ({
  mode: width < 1104 ? "narrow" : "wide"
}))(theme(withTranslation()(HotelDetailSummary)))
