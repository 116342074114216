import { SearchParams, Room, Filters, RateTypeEnum, Pagination } from "../types"
import qs from "qs"
import { isString } from "util"
import moment from "moment"

export const searchParamsToQueryString = (searchParams: SearchParams): string => {
  const { location, checkin, checkout, rooms, filters, order_by, rate_type } = searchParams

  return qs.stringify({
    region_id: location.id,
    region_type: location.type,
    checkin: checkin.format("YYYY-MM-DD"),
    checkout: checkout.format("YYYY-MM-DD"),
    distribution: getRoomDistributionString(rooms),
    rate_type: rate_type ? rate_type : RateTypeEnum.Standalone,
    offset: searchParams.pagination ? searchParams.pagination.offset : undefined,
    limit: searchParams.pagination ? searchParams.pagination.limit : undefined,
    ...filtersToQuery(filters),
    order_by: order_by
  })
}

const providerNames = { EXPEDIA: "EPS" }

export const getProviderName = (provider: string): string => {
  if (providerNames[provider.toUpperCase()]) {
    return providerNames[provider.toUpperCase()]
  }
  return provider.toUpperCase()
}

export const queryToSearchParams = (query: any, pagination: Pagination): SearchParams => {
  const {
    region_id,
    region_type,
    checkin,
    checkout,
    distribution,
    stars,
    mealPlan,
    zone,
    name,
    amenities,
    price,
    category,
    order_by,
    showProvider,
    rate_type
  } = query

  let filters: Filters = {}
  if (stars && stars.length) {
    filters.stars = stars.split(",")
  }
  if (category && category.length) {
    filters.category = category.split(",")
  }
  if (mealPlan && mealPlan.length) {
    filters.mealPlan = mealPlan.split(",")
  }
  if (zone && zone.length) {
    filters.zone = zone.split(",")
  }
  if (name && name.length) {
    filters.name = name.split(",")
  }
  if (amenities && amenities.length) {
    filters.amenities = amenities.split(",")
  }
  if (price && price.length) {
    filters.price = price.split(",")
  }
  let rooms = [{ adults: 2, children: [] }]
  if (distribution) {
    rooms = parseDistribution(distribution)
  }
  let _checkin = moment()
  if (checkin) {
    _checkin = moment(checkin)
  }
  let _checkout = moment().add(1, "d")
  if (checkout) {
    _checkout = moment(checkout)
  }

  return {
    location: {
      id: region_id,
      type: region_type
    },
    checkin: _checkin,
    checkout: _checkout,
    rooms: rooms,
    pagination: pagination,
    filters: filters,
    order_by: order_by || "rank",
    showProvider: showProvider === "true",
    rate_type: rate_type ? rate_type : RateTypeEnum.Standalone,
    shouldSearchPackageRates: rate_type === RateTypeEnum.PackageOpaque
  }
}

export const getRoomDistributionString = (rooms: Room[]): string => {
  let roomsDistribution: string[] = []
  for (let room of rooms) {
    let roomDist = `${room.adults}`
    if (room.children.length) {
      roomDist += `-${room.children.join("-")}`
    }
    roomsDistribution.push(roomDist)
  }
  return roomsDistribution.join(",")
}

export const filtersToQuery = (filters?: Filters) => {
  let query: any = {}

  if (!filters) return query

  if (filters.stars) {
    query.stars = filters.stars.join(",")
  }
  if (filters.category) {
    query.category = filters.category.join(",")
  }
  if (filters.mealPlan) {
    query.mealPlan = filters.mealPlan.join(",")
  }
  if (filters.zone) {
    query.zone = filters.zone.join(",")
  }
  if (filters.name) {
    query.name = filters.name.join(",")
  }
  if (filters.amenities) {
    query.amenities = filters.amenities.join(",")
  }
  if (filters.price) {
    query.price = filters.price.join(",")
  }
  return query
}

export const calculateTotalGuests = (distribution: string): number => {
  const rooms = distribution.split(",")
  let totalGuests = 0
  rooms.forEach(room => {
    const guests = room.split("-")
    guests.forEach((g, i) => {
      if (i === 0) {
        totalGuests += parseInt(g, 10)
      } else {
        totalGuests += 1
      }
    })
  })
  return totalGuests
}

export const searchParamsToPath = (
  params: any,
  options?: { detailUrlBase?: string; resultsUrlBase?: string }
): string => {
  let path = ""
  let urlbase = ""
  if (params.location.type === "accommodation") {
    urlbase = options && options.detailUrlBase ? options.detailUrlBase : "/accommodations/details"
    path = `${urlbase}/${params.location.id}?${searchParamsToDetailQueryString(params)}`
  } else {
    const searchParams: SearchParams = {
      location: {
        id: params.location.id,
        type: params.location.type
      },
      checkin: params.checkin,
      checkout: params.checkout,
      rooms: params.rooms,
      shouldSearchPackageRates: params.shouldSearchPackageRates,
      rate_type: params.rate_type ? params.rate_type : RateTypeEnum.Standalone
    }
    urlbase = options && options.resultsUrlBase ? options.resultsUrlBase : "/accommodations/results"
    const qs = searchParamsToQueryString(searchParams)
    path = `${urlbase}?${qs}`
  }
  return path
}

export const searchParamsToDetailQueryString = (searchParams: SearchParams): string => {
  const { checkin, checkout, rooms, rate_type } = searchParams
  return qs.stringify({
    checkin: checkin.format("YYYY-MM-DD"),
    checkout: checkout.format("YYYY-MM-DD"),
    distribution: getRoomDistributionString(rooms),
    rate_type: rate_type ? rate_type : RateTypeEnum.Standalone
  })
}

export const parseDistribution = (distribution: string): any[] => {
  return distribution.split(",").map(r => {
    const room = r.split("-")
    return {
      adults: parseInt(room[0], 10),
      children: room.slice(1, room.length).map(c => parseInt(c, 10))
    }
  })
}

export const highlightedAmenities = (accommodation: any): any => {
  if (accommodation.amenities && accommodation.amenities.length > 0) {
    const amenities = new Set(accommodation.amenities.map((e: any) => e.id))
    return {
      freeWifi: amenities.has("2390"),
      freeParking: amenities.has("3861") || amenities.has("3863") || amenities.has("4450"),
      pool: amenities.has("14") || amenities.has("24"),
      allDayCheckin: amenities.has("2063")
    }
  }
  return {
    freeWifi: false,
    freeParking: false,
    pool: false,
    allDayCheckin: false
  }
}

/**
 * Accommodations: 0 and 0
 * Flights: 2 and 2
 *
 * @param price
 * @param minimumFractionDigits
 * @param maximumFractionDigits
 * @returns {string}
 */
export const setFormatPrice = (
  price: string | number,
  minimumFractionDigits: number,
  maximumFractionDigits: number
) => {
  let p = 0
  if (isString(price)) {
    p = parseFloat(price as string)
  } else {
    p = price as number
  }

  return p.toLocaleString("de-DE", {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  })
}

export const formatPrice = (price: string | number, site?: string) => {
  if (site === "CL") {
    return setFormatPrice(price, 0, 0)
  }
  return setFormatPrice(price, 2, 2)
}

export const roundingWithStep = (val: number, step: number) => {
  let res = Math.ceil(val / step)
  if (res !== val / step) {
    res--
  }
  return res * step
}
