import React from "react"
import Icon from "@material-ui/core/Icon"
import componentQueries from "react-component-queries"
import { theme, ThemeProps } from "../BassetProvider"
import { useTranslation } from "react-i18next"
import styles from "./HotelZone.styles"
import _ from "underscore"

interface Props extends ThemeProps {
  zone: any
  onViewMap: () => void
  mode?: string
}

const HotelZone: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation()
  const { theme, zone, onViewMap } = props

  if (
    (!zone.pois || zone.pois.length === 0) &&
    (!zone.train_stations || zone.train_stations.length === 0) &&
    (!zone.metro_stations || zone.metro_stations.length === 0)
  ) {
    return <div />
  }

  return (
    <div className={styles.containerStyle}>
      <div className={styles.titleContainer(theme.brand_colors.highlight_color, theme.brand_colors.click_color)}>
        <p>{t("HotelZone.zone")}</p>
        <button onClick={onViewMap}>
          {t("HotelZone.seeOnMap")} <Icon>open_in_new</Icon>
        </button>
      </div>
      <p className={styles.subtitleStyle}>{t("HotelZone.nearByHotel")}</p>
      <div className={styles.poisContainer}>
        {!_.isEmpty(zone.pois) && (
          <div className={styles.poiSectionStyle}>
            <div className={styles.poiTitleStyle}>
              <p>{t("HotelZone.pointsOfInterest")}</p>
            </div>
            <div className={styles.poisStyle}>
              {zone.pois.map((e, i) => (
                <div key={i}>
                  <p>{e.name}</p>
                  {e.distance && <p>{e.distance}</p>}
                </div>
              ))}
            </div>
          </div>
        )}
        {!_.isEmpty(zone.train_stations) && (
          <div className={styles.poiSectionStyle}>
            <div className={styles.poiTitleStyle}>
              <p>{t("HotelZone.trainStations")}</p>
            </div>
            <div className={styles.poisStyle}>
              {zone.train_stations.map((e, i) => (
                <div key={i}>
                  <p>{e.name}</p>
                  {e.distance && <p>{e.distance}</p>}
                </div>
              ))}
            </div>
          </div>
        )}
        {!_.isEmpty(zone.metro_stations) && (
          <div className={styles.poiSectionStyle}>
            <div className={styles.poiTitleStyle}>
              <p>{t("HotelZone.subwayStations")}</p>
            </div>
            <div className={styles.poisStyle}>
              {zone.metro_stations.map((e, i) => (
                <div key={i}>
                  <p>{e.name}</p>
                  {e.distance && <p>{e.distance}</p>}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default componentQueries(({ width }) => ({
  mode: width < 1104 ? "narrow" : "wide"
}))(theme(HotelZone))
