import { searchAPIAccommodations } from "../api/accommodations"
import { SearchParams } from "../types"

export const SEARCH_ACCOMMODATIONS_FETCHING = "SEARCH_ACCOMMODATIONS_FETCHING"
export const SEARCH_ACCOMMODATIONS_FINISHED = "SEARCH_ACCOMMODATIONS_FINISHED"
export const SEARCH_ACCOMMODATIONS_ERROR = "SEARCH_ACCOMMODATIONS_ERROR"
export const SEARCH_PAGE_ACCOMODATIONS_FETCHING = "SEARCH_PAGE_ACCOMODATIONS_FETCHING"
export const SEARCH_PAGE_ACCOMODATIONS_FINISHED = "SEARCH_PAGE_ACCOMODATIONS_FINISHED"
export const SEARCH_PAGE_ACCOMODATIONS_ERROR = "SEARCH_PAGE_ACCOMODATIONS_ERROR"
export const REWARDS_POINTS_FETCHING = "REWARDS_POINTS_FETCHING"
export const REWARDS_POINTS_ERROR = "REWARDS_POINTS_ERROR"
export const LOCATION_CHANGE = "ROUTER/LOCATION_CHANGE"

export const searchAccommodations = (searchParams: SearchParams) => async (dispatch: any) => {
  dispatch(searchAccommodationsFetching())
  try {
    const res = await searchAPIAccommodations(searchParams)
    dispatch(searchAccommodationsFinished(res))
  } catch (err) {
    dispatch(searchAccommodationsError(err))
  }
}

export const searchPageAccommodations = (searchParams: SearchParams) => async (dispatch: any) => {
  dispatch(searchPageAccommodationsFetching())
  try {
    const res = await searchAPIAccommodations(searchParams)
    dispatch(searchPageAccommodationsFinished(res))
  } catch (err) {
    console.error(err)
    dispatch(searchPageAccommodationsError(err))
  }
}

export const searchPageAccommodationsFetching = () => ({
  type: SEARCH_PAGE_ACCOMODATIONS_FETCHING
})

export const searchPageAccommodationsFinished = (result: any) => ({
  type: SEARCH_PAGE_ACCOMODATIONS_FINISHED,
  accommodations: result.clusters
})

export const searchPageAccommodationsError = (err: any) => ({
  type: SEARCH_PAGE_ACCOMODATIONS_ERROR,
  error: err
})

export const searchAccommodationsFetching = () => ({
  type: SEARCH_ACCOMMODATIONS_FETCHING
})
export const searchAccommodationsError = (err: any) => ({
  type: SEARCH_ACCOMMODATIONS_ERROR,
  error: err
})
export const searchAccommodationsFinished = (result: any) => ({
  type: SEARCH_ACCOMMODATIONS_FINISHED,
  result
})
