import * as React from "react"
import { css, keyframes } from "glamor"
import * as componentQueries from "react-component-queries"

interface Props {
  mode?: string
}

const LoadingRooms = ({ mode }: Props) => {
  if (mode === "wide") {
    return (
      <div className={`${containerStyle}`}>
        <div className={`${roomClusterContainer}`}>
          <div className={`${clusterStyle}`}>
            <div className={`${roomsContainer}`}>
              <div
                className={[animatedBgStyle, loadingBarStyle].join(" ")}
                style={{ width: "72px", marginTop: "40px" }}
              />
              <div
                className={[animatedBgStyle, loadingBarStyle].join(" ")}
                style={{ width: "168", marginTop: "24px" }}
              />
            </div>
            <div className={`${ratesContainer}`}>
              <div className={`${rateStyle}`}>
                <div style={{ width: "356px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "108px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "88px", height: "128px" }} />
              </div>
              <div className={`${rateStyle}`}>
                <div style={{ width: "356px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "108px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "88px", height: "128px" }} />
              </div>
            </div>
          </div>
          <div className={`${clusterStyle}`}>
            <div className={`${roomsContainer}`}>
              <div
                className={[animatedBgStyle, loadingBarStyle].join(" ")}
                style={{ width: "72px", marginTop: "40px" }}
              />
              <div
                className={[animatedBgStyle, loadingBarStyle].join(" ")}
                style={{ width: "168", marginTop: "24px" }}
              />
            </div>
            <div className={`${ratesContainer}`}>
              <div className={`${rateStyle}`}>
                <div style={{ width: "356px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "108px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "88px", height: "128px" }} />
              </div>
              <div className={`${rateStyle}`}>
                <div style={{ width: "356px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "108px", height: "128px", borderRight: "1px solid #eaeaea" }} />
                <div style={{ width: "88px", height: "128px" }} />
              </div>
            </div>
          </div>
        </div>
        <div className={`${priceStyle}`}>
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "168px" }} />
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "168px", marginTop: "32px" }} />
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "168px", marginTop: "8px" }} />
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "168px", marginTop: "24px" }} />
          <div className={[animatedBgStyle, buttonStyle].join(" ")} style={{ marginTop: "16px" }} />
        </div>
      </div>
    )
  }

  return (
    <div className={`${mobileContainer}`}>
      <div className={`${mobileRoomCluster}`}>
        <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "136px", marginBottom: "8px" }} />
        <div
          className={[animatedBgStyle, loadingBarStyle].join(" ")}
          style={{ width: "136px", marginBottom: "16px" }}
        />
        <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "80px", marginBottom: "24px" }} />
        <div className={`${mobileRateStyle}`}>
          <div className={[animatedBgStyle, buttonStyle].join(" ")} style={{ width: "112px" }} />
        </div>
        <div className={`${mobileRateStyle}`}>
          <div className={[animatedBgStyle, buttonStyle].join(" ")} style={{ width: "112px" }} />
        </div>
      </div>
      <div className={`${mobileRoomCluster}`}>
        <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "136px", marginBottom: "8px" }} />
        <div
          className={[animatedBgStyle, loadingBarStyle].join(" ")}
          style={{ width: "136px", marginBottom: "16px" }}
        />
        <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "80px", marginBottom: "24px" }} />
        <div className={`${mobileRateStyle}`}>
          <div className={[animatedBgStyle, buttonStyle].join(" ")} style={{ width: "112px" }} />
        </div>
        <div className={`${mobileRateStyle}`}>
          <div className={[animatedBgStyle, buttonStyle].join(" ")} style={{ width: "112px" }} />
        </div>
      </div>
    </div>
  )
}

const mobileRateStyle = css({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "145px",
  padding: "16px 8px",
  borderRadius: "3px",
  border: "1px solid #eaeaea",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  "&:last-child": {
    marginTop: "8px"
  }
})

const mobileRoomCluster = css({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "16px 8px",
  "&:first-child": {
    marginBottom: "8px"
  }
})

const mobileContainer = css({
  display: "flex",
  flexDirection: "column",
  padding: "16px 8px",
  backgroundColor: "#eaeaea"
})

const priceStyle = css({
  display: "flex",
  flexDirection: "column",
  width: "280px",
  alignItems: "center",
  paddingTop: "74px"
})

const buttonStyle = css({
  borderRadius: "1px",
  height: "32px",
  width: "168px"
})

const roomsContainer = css({
  display: "flex",
  flexDirection: "column",
  width: "256px",
  padding: "0 24px"
})

const rateStyle = css({
  width: "100%",
  height: "128px",
  display: "flex",
  flexDirection: "row",
  "&:last-child": {
    borderTop: "1px dashed #eaeaea"
  }
})

const ratesContainer = css({
  width: "558px",
  display: "flex",
  flexDirection: "column",
  borderLeft: "1px solid #eaeaea"
})

const loadingBarStyle = css({
  height: "16px",
  borderRadius: "8px"
})

const placeHolderShimmer = keyframes({
  "0%": {
    backgroundPosition: "-468px 0"
  },
  "100%": {
    backgroundPosition: "468px 0"
  }
})

const animatedBgStyle = css({
  animationDuration: "1s",
  animationFillMode: "forwards",
  animationIterationCount: "infinite",
  animationName: `${placeHolderShimmer}`,
  animationTimingFunction: "linear",
  background: "linear-gradient(to right, #dedede 8%, #ccc 18%, #dedede 33%)",
  backgroundSize: "800px 104px"
})

const clusterStyle = css({
  display: "flex",
  flexDirection: "row",
  width: "816px",
  height: "256px",
  backgroundColor: "#fff",
  borderRadius: "3px"
})

const roomClusterContainer = css({
  display: "flex",
  flexDirection: "column",
  width: "816px",
  " >div": {
    marginBottom: "8px"
  },
  " >div:last-child": {
    marginBottom: 0
  }
})

const containerStyle = css({
  display: "flex",
  flexDirection: "row",
  padding: "16px 8px",
  backgroundColor: "#eaeaea",
  width: "100%",
  height: "552px"
})

export default componentQueries(({ width }: any) => ({
  mode: width < 1100 ? "narrow" : "wide"
}))(LoadingRooms)
