import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import "./i18n/i18n"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "./index.css"
import App from "./App"

// used for material-ui
// https://material-ui.com/style/typography/#migration-to-typography-v2
// @ts-ignore
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.render(<App />, document.getElementById("root"))
