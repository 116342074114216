import React from "react"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import Popover from "@material-ui/core/Popover"
import CloseIcon from "@material-ui/icons/Close"
//Icons
import EventIcon from "@material-ui/icons/Event"
import { css } from "glamor"
import moment, { Moment } from "moment"
import { DayPickerRangeController, DayPickerSingleDateController } from "react-dates"
import { END_DATE, START_DATE } from "react-dates/constants"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import { withTranslation, WithTranslation } from "react-i18next"
import Modal from "react-modal"
import { theme, ThemeProps } from "../../BassetProvider"
import { isMobile } from "../../../utils/devicesUtils"
import { getLanguageFromI18n } from "../../../utils/languageUtils"
import { setMomentLocale } from "../../../utils/momentUtils"
//Components
import FieldContainer from "../../Searchbox/FieldContainer"
import TextInput from "../TextInput"
import { modalStyle } from "./theme"
import { datepickerStyle } from "./datepickerStyle"

const VERTICAL_SCROLLABLE = "verticalScrollable"

interface Props extends ThemeProps, WithTranslation {
  usesEndDate: boolean
  index: number
  selectedDateFrom: Moment | null
  selectedDateTo: Moment | null
  minDate: Moment
  maxDate: Moment
  highlightedDates?: any[]
  inputValueFormat?: string
  minNights?: number
  maxNights?: number
  error?: string
  onSelectRange: (sd: Moment, ed: Moment | null) => void
}

class SearchBoxDateRangePicker extends React.PureComponent<Props, any> {
  static defaultProps = {
    minDate: moment().startOf("day"),
    maxDate: moment()
      .add(330, "d")
      .endOf("day"),
    highlightedDates: [],
    inputValueFormat: "DD MMM YYYY",
    minNights: 0,
    index: 0
  }

  state = {
    datePickerOpen: false,
    focusedInput: START_DATE,
    focused: true,
    anchorEl: null
  }

  componentWillReceiveProps(newProps: any) {
    if (!newProps.selectedDateTo) {
      this.setState({ focusedInput: END_DATE })
    }
  }

  handleOpenDatePicker = (event: any) => {
    // hack to display the popover on top of the input field if the calendar doesnt fit on the bottom
    // TODO: find a better way to solve this shit :/
    if (!isMobile()) {
      const rect = event.currentTarget.getBoundingClientRect()
      const bottom = rect.bottom || rect.top + event.currentTarget.offsetHeight
      if (bottom + 351 > window.innerHeight) {
        // 351 is the height of the calendar now
        setTimeout(() => {
          window.scrollTo(window.scrollX, window.scrollY + 1)
        }, 100)
      }
    }
    // end of hack
    this.setState({
      datePickerOpen: true,
      anchorEl: event.currentTarget,
      focusedInput: event.currentTarget.id === `search-box-date-to-${this.props.index}` ? END_DATE : START_DATE
    })
  }

  handleCloseDatePicker = () => {
    this.setState({
      datePickerOpen: false
    })
  }

  handleDateRangeChange = (date: any) => {
    const { usesEndDate, onSelectRange } = this.props
    let startDate = date.startDate ? date.startDate : date
    onSelectRange(usesEndDate && date.startDate === null ? moment() : startDate, usesEndDate ? date.endDate : null)
  }

  onFocusChangeRange = (focusedInput: any) => {
    this.setState({ focusedInput: !focusedInput ? START_DATE : focusedInput })
  }

  onFocusChangeSingle = () => {
    this.setState({ focused: true })
  }

  getSelectedDateFrom = (): Moment | null => {
    const { selectedDateFrom, minDate } = this.props
    return selectedDateFrom && selectedDateFrom.isSameOrAfter(minDate) ? selectedDateFrom : null
  }

  getSelectedDateTo = (): Moment | null => {
    const { selectedDateTo, maxDate } = this.props
    return selectedDateTo && selectedDateTo.isSameOrBefore(maxDate) ? selectedDateTo : null
  }

  dayPicker = () => {
    const { usesEndDate, minDate, maxDate, highlightedDates, maxNights, minNights, theme } = this.props
    const { focusedInput, focused } = this.state
    const selectedDateFrom = this.getSelectedDateFrom()
    const selectedDateTo = this.getSelectedDateTo()
    const defaultIsOutsideRange = (day: Moment) => day.isBefore(minDate) || day.isAfter(maxDate)

    const singleCalendarProps = {
      onClose: this.handleCloseDatePicker,
      hideKeyboardShortcutsPanel: true,
      keepOpenOnDateSelect: false,
      numberOfMonths: isMobile() ? 12 : 2,
      initialVisibleMonth: () => (isMobile() ? moment() : selectedDateFrom ? selectedDateFrom : minDate),
      firstDayOfWeek: 1,
      isOutsideRange: defaultIsOutsideRange,
      isDayHighlighted: (day1: Moment) => {
        if (highlightedDates) {
          return highlightedDates.some(day2 => day1.isSame(day2, "day"))
        }
        return false
      },
      navPrev: <Icon style={iconStyle}>arrow_back</Icon>,
      navNext: <Icon style={iconStyle}>arrow_forward</Icon>
    }

    if (usesEndDate) {
      let isOutsideRange = defaultIsOutsideRange

      // when the user will select the end date, if we have configured a maxNights (feature not provided by react-dates)
      // then we change the isOutsideRange function to let the user select a range of dates that doesn't exceed the
      // maxNights attribute
      if (focusedInput === END_DATE && maxNights) {
        const maxEndDate = selectedDateFrom ? selectedDateFrom.clone().add(maxNights, "d") : maxDate
        isOutsideRange = (day: Moment) => day.isBefore(minDate) || day.isAfter(maxEndDate)
      }

      const rangedCalendarProps = {
        ...singleCalendarProps,
        minimumNights: minNights,
        isOutsideRange: isOutsideRange
      }

      return (
        <div className={`${datepickerStyle(theme)}`}>
          <DayPickerRangeController
            {...rangedCalendarProps}
            firstDayOfWeek={1}
            orientation={isMobile() ? VERTICAL_SCROLLABLE : undefined}
            onDatesChange={this.handleDateRangeChange}
            onFocusChange={this.onFocusChangeRange}
            focusedInput={focusedInput}
            startDate={selectedDateFrom}
            endDate={selectedDateTo}
          />
        </div>
      )
    }

    return (
      <div className={`${datepickerStyle(theme)}`}>
        <DayPickerSingleDateController
          {...singleCalendarProps}
          firstDayOfWeek={1}
          orientation={isMobile() ? VERTICAL_SCROLLABLE : undefined}
          onDateChange={this.handleDateRangeChange}
          onFocusChange={this.onFocusChangeSingle}
          focused={focused}
          date={selectedDateFrom}
        />
      </div>
    )
  }

  render() {
    const { usesEndDate, index, error, inputValueFormat, t, i18n } = this.props
    const { datePickerOpen, anchorEl } = this.state
    const selectedDateFrom = this.getSelectedDateFrom()
    const selectedDateTo = this.getSelectedDateTo()

    setMomentLocale(getLanguageFromI18n(i18n))

    return (
      <div>
        <FieldContainer
          label={t("DatePicker.dates_label")}
          icon={<EventIcon style={{ fontSize: "16px" }} />}
          error={error}
        >
          <div className={`${dateInput}`} style={{ borderRight: "solid 1px #eaeaea" }}>
            <TextInput
              borderless
              onClick={this.handleOpenDatePicker}
              readOnly={true}
              id={`search-box-date-from-${index}`}
              value={selectedDateFrom ? selectedDateFrom.format(inputValueFormat) : ""}
              placeholder={t("DatePicker.from")}
              pointer
            />
          </div>
          {usesEndDate ? (
            <div className={`${dateInput}`}>
              <TextInput
                borderless
                onClick={this.handleOpenDatePicker}
                readOnly={true}
                id={`search-box-date-to-${index}`}
                value={selectedDateTo ? selectedDateTo.format(inputValueFormat) : ""}
                placeholder={t("DatePicker.to")}
                pointer
              />
            </div>
          ) : null}
        </FieldContainer>

        {isMobile() ? (
          <Modal
            isOpen={datePickerOpen}
            closeTimeoutMS={1}
            style={modalStyle}
            onRequestClose={this.handleCloseDatePicker}
            shouldCloseOnOverlayClick={false}
            contentLabel="Modal"
            ariaHideApp={false}
            htmlOpenClassName="ReactModal__Html--open"
            role="dialog"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse"
              }}
            >
              <IconButton onClick={this.handleCloseDatePicker}>
                <CloseIcon />
              </IconButton>
            </div>

            {this.dayPicker()}
          </Modal>
        ) : (
          <Popover
            open={datePickerOpen}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            onClose={this.handleCloseDatePicker}
          >
            {this.dayPicker()}
          </Popover>
        )}
      </div>
    )
  }
}

const dateInput = css({
  flex: 1,
  display: "flex"
})

const iconStyle = {
  width: 20,
  height: 20,
  fill: "#fc511f",
  color: "#fc511f"
}

export default withTranslation()(theme(SearchBoxDateRangePicker))
