import * as React from "react"
import ErrorTooltip from "../Common/ErrorTooltip"
import { css } from "glamor"
import { theme, ThemeProps } from "../BassetProvider"
import { isMobile } from "../../utils/devicesUtils"

interface Props extends ThemeProps {
  label: string
  labelWidth?: string
  icon?: React.ReactNode
  descriptionText?: string
  children: React.ReactNode
  error?: string
}

const getPlacement = () => {
  return isMobile() ? "top" : "right"
}

const FieldContainer = ({ theme, label, icon, descriptionText, labelWidth, error, children }: Props) => {
  const selectionColorTheme = css({ color: theme.searchbox_colors.click_color + " !important" })
  const backgroundColorTheme = css({ backgroundColor: theme.searchbox_colors.background_color + " !important" })
  const errorContainerStyle = error && error.length > 0 ? css({ border: "solid 1px red" }) : css({})

  return (
    <ErrorTooltip placement={getPlacement()} error={error}>
      <div className={`${fieldContainer} ${selectionColorTheme} ${backgroundColorTheme} ${errorContainerStyle}`}>
        <div className={`${descriptionContainer}`} style={labelWidth ? { width: labelWidth } : { minWidth: "89px" }}>
          {icon && icon}
          <span className={`${labelStyle}`}>{label}</span>
          {descriptionText && descriptionText.length > 0 && (
            <span className={`${descriptionTextStyle}`}>{descriptionText}</span>
          )}
        </div>
        <div className={`${childContainer}`}>{children}</div>
      </div>
    </ErrorTooltip>
  )
}

const fieldContainer = css({
  borderRadius: "3px",
  border: "solid 1px #cccccc",
  display: "flex",
  height: "34px",
  marginTop: "8px"
})

const descriptionContainer = css({
  borderRight: "solid 1px #eaeaea",
  display: "flex",
  alignItems: "center",
  paddingLeft: "8px"
})

const childContainer = css({
  display: "flex",
  flexGrow: 1,
  fontSize: "14px !important"
})

const descriptionTextStyle = css({
  color: "#4a4a4a",
  display: "flex",
  fontSize: "10px",
  width: "100%"
})

const labelStyle = css({
  margin: "0 8px 0 8px",
  width: "100%"
})

export default theme(FieldContainer)
