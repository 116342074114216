import React from "react"
//Components
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
//Icons
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import { css } from "glamor"
import Autosuggest, { AutosuggestPropsMultiSection, AutosuggestPropsSingleSection } from "react-autosuggest"
import { WithTranslation, withTranslation } from "react-i18next"
import Modal from "react-modal"
import _ from "underscore"
import { Theme, theme, ThemeProps } from "../../BassetProvider"
import { isMobile } from "../../../utils/devicesUtils"
import TextInput from "../TextInput"
import { acTheme, modalStyle } from "./acTheme"
import Suggestion from "./Suggestion"
import SuggestionTitle from "./SuggestionTitle"
import * as constants from "../../../utils/constants"

import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"

const getSuggestionValue = (suggestion: any) => {
  return suggestion.name
}

const getSectionSuggestions = (section: any) => section.locations

const renderSuggestion = (suggestion: any, { query }: any) => {
  let className = "autocomplete-menu-item"
  if (!suggestion.iata_code) {
    className += " no-iata-code"
  }

  const matches = match(suggestion.name, query)
  const parts = parse(suggestion.name, matches)

  return <Suggestion parts={parts} className={className} />
}

interface Props extends WithTranslation, ThemeProps {
  // required
  label: string
  dataSource: any[]
  selectedLocation?: {
    id: string
    iata?: string
    name: string
    type: string
  }
  index: number
  // optional
  placeholder?: string
  error?: boolean
  // handlers
  onUpdateInput: (v: any) => void
  onSelectItem: (v: any, i: number) => void
  onClear: () => void
}

export class LocationAutocomplete extends React.PureComponent<Props, any> {
  static defaultProps = {
    index: 0
  }

  constructor(props: Props) {
    super(props)
    const { selectedLocation } = props
    this.state = {
      autocompleteValue: selectedLocation ? selectedLocation.name : "",
      modalOpen: false
    }
  }

  componentWillReceiveProps(newProps: Props) {
    if (this.props.selectedLocation !== newProps.selectedLocation && newProps.selectedLocation) {
      this.setState({
        autocompleteValue: newProps.selectedLocation.name
      })
    }
  }

  onSuggestionSelected = (event: any, { suggestion }: any) => {
    const { index, onSelectItem } = this.props

    onSelectItem(suggestion, index)
    this.setState({ modalOpen: false })
  }

  onChange = (eventL: any, { newValue }: any) => {
    this.setState({
      autocompleteValue: newValue
    })
  }

  onBlur = (event: any) => {
    const { selectedLocation, index, onSelectItem } = this.props
    const { autocompleteValue } = this.state

    if (!selectedLocation) return

    if (!autocompleteValue.length) {
      onSelectItem({}, index)
    } else if (
      selectedLocation.name !== autocompleteValue &&
      (!event.relatedTarget ||
        ` ${event.relatedTarget.className} `.indexOf(" autocomplete-menu-item ") === -1 ||
        ` ${event.relatedTarget.className} `.indexOf(" no-iata-code ") !== -1)
    ) {
      this.setState({ autocompleteValue: selectedLocation.name })
    }
  }

  onSuggestionsFetchRequested = ({ value, reason }: any) => {
    if (reason === "input-changed") {
      this.props.onUpdateInput(value)
    }
  }

  onSuggestionsClearRequested = () => {
    this.props.onClear()
  }

  openModal = (event: any) => {
    event.target.blur()
    this.setState({ modalOpen: true })
  }

  focusAutocompleteInput = () => {
    const input = document.getElementById(this.inputId())
    if (input) {
      input.focus()
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  inputId = () => {
    return this.props.label.toLowerCase().replace(/ /g, "_") + "__input"
  }

  renderSectionTitle = (section: any) => {
    const { t } = this.props
    switch (section.title) {
      // Flights
      case constants.LOCATION_TYPE_AIRPORT:
        return <SuggestionTitle iconType={constants.LOCATION_AIRPORT} title={t("Autocomplete.airports")} />
      case constants.LOCATION_TYPE_CITY || constants.LOCATION_TYPE_MULTI_CITY:
        return <SuggestionTitle iconType={constants.LOCATION_CITY} title={t("Autocomplete.cities")} />
      case constants.LOCATION_TYPE_CITY_ACCOMMODATIONS:
        return <SuggestionTitle iconType={constants.LOCATION_CITY} title={t("Autocomplete.cities")} />
      case constants.LOCATION_TYPE_NEIGHBORHOOD:
        return <SuggestionTitle iconType={constants.LOCATION_CITY} title={t("Autocomplete.neighborhood")} />
      // Hotels
      case constants.LOCATION_TYPE_ACCOMMODATION:
        return <SuggestionTitle iconType={"hotel"} title={t("Autocomplete.hotels")} />
      default:
        return null
    }
  }

  renderInputComponent = (inputProps: any) => {
    const { ref, ...restInputProps } = inputProps
    const textField = <TextInput borderless {...restInputProps} innerRef={ref} />

    if (isMobile()) {
      return (
        <div style={{ flex: "1" }}>
          {textField}
          <IconButton
            onClick={() => {
              this.setState({ autocompleteValue: "" })
              this.focusAutocompleteInput()
            }}
            style={{
              position: "absolute",
              right: 2,
              top: 6,
              padding: 0,
              width: 24,
              height: 24,
              backgroundColor: "#fff"
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </div>
      )
    }
    return textField
  }

  render() {
    const { t, theme, label, placeholder, error, dataSource } = this.props
    const { autocompleteValue, modalOpen } = this.state

    const inputProps = {
      placeholder: placeholder ? placeholder : "",
      value: autocompleteValue ? autocompleteValue : "",
      onChange: this.onChange,
      onBlur: this.onBlur,
      id: this.inputId(),
      error: error
    }

    const suggestions = _.map(_.groupBy(dataSource, "type"), (locations, type) => {
      return {
        title: type,
        locations: locations
      }
    })

    const autosuggestProps: AutosuggestPropsMultiSection<any, any> | AutosuggestPropsSingleSection<any> = {
      suggestions: suggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      getSuggestionValue: getSuggestionValue,
      getSectionSuggestions: getSectionSuggestions,
      renderSuggestion: renderSuggestion,
      renderInputComponent: this.renderInputComponent,
      onSuggestionSelected: this.onSuggestionSelected,
      multiSection: true,
      renderSectionTitle: this.renderSectionTitle,
      inputProps: inputProps,
      focusInputOnSuggestionClick: false,
      theme: acTheme as Autosuggest.Theme,
      id: label.toLowerCase().replace(/ /g, "_")
    }

    if (isMobile()) {
      return [
        <TextInput
          borderless
          error={error}
          id={this.inputId() + "__fake_input"}
          value={inputProps.value}
          placeholder={inputProps.placeholder}
          onFocus={this.openModal}
          readOnly
          key={0}
        />,
        <Modal
          isOpen={modalOpen}
          closeTimeoutMS={1}
          style={modalStyle}
          onAfterOpen={this.focusAutocompleteInput}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal"
          ariaHideApp={false}
          htmlOpenClassName="ReactModal__Html--open"
          role="dialog"
          key={1}
        >
          <div className={`${modalInnerContainerStyle(theme)}`}>
            <Autosuggest {...autosuggestProps} alwaysRenderSuggestions={true} />
            <Button onClick={this.closeModal} style={{ color: "#fff", marginLeft: 8 }}>
              {t("Autocomplete.cancel")}
            </Button>
          </div>
        </Modal>
      ]
    }

    const webProps = { ...autosuggestProps, inputProps: { ...inputProps, applyInputGroupLastChild: true } }

    return <Autosuggest {...webProps} />
  }
}

const modalInnerContainerStyle = (theme: Theme) =>
  css({
    background: theme.searchbox_colors.highlight_color,
    padding: "8px 8px 8px 16px",
    display: "flex"
  })

export default withTranslation()(theme(LocationAutocomplete))
