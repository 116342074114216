import { getClientConfig } from "./common"

export const getAdvertising = async () => {
  const url = process.env.REACT_APP_ADVERTISING_API_URI
  const apiKey = process.env.REACT_APP_API_KEY
  if (!url) throw new Error("missing key REACT_APP_ADVERTISING_API_URI")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  // FIXME: language hardcoded
  const config = await getClientConfig()
  const res = await fetch(`${url}/?channel=WEB&product=ACCOMMODATIONS&site=${config.site}`, {
    headers: {
      "x-client-id": config.id,
      "x-api-key": apiKey
    }
  })
  if (res.ok) {
    const accommodation = await res.json()
    return accommodation
  }
  throw new Error("Failed to get accommodation")
}
