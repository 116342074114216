import React from "react"
import qs from "qs"
import moment from "moment"
import animateScrollTo from "animated-scroll-to"
import { withTranslation, WithTranslation } from "react-i18next"
import ReactGA from "react-ga"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import { calculateTotalGuests, searchParamsToPath, parseDistribution, highlightedAmenities } from "../../utils/helpers"

import noPhoto from "../../images/accommodation-image.svg"
import HotelDetailSummary from "./HotelDetailSummary"
import AmenitiesAndCheckin from "./AmenitiesAndCheckin"
import HotelDescription from "./HotelDescription"
import HotelAmenities from "./HotelAmenities"
import LoadingDetail from "./LoadingDetail"
import LoadingRooms from "./LoadingRooms"
import HotelDetailMap from "./map/HotelDetailMap"
import RoomClusters from "./rooms/RoomClusters"
import AppLoader from "../AppLoader"
import ErrorAlert from "../Common/ErrorAlert"

import { rewards, priceAssignableToPointsOnRate, calculatePointsByRate } from "../../utils/rewards"
import { getAccommodationInformation, getRoomClusters, checkRate, getRewardsPoints } from "../../api/accommodations"
import { getClientConfig } from "../../api/common"
import { getAdvertising } from "../../api/advertising"
import Wrapper from "../Wrapper"
import { RouteComponentProps } from "react-router"
import SliderComponent from "../Common/Slider"
import { SearchParams } from "../../types"
import { RewardsCookies } from "../../utils/rewards"
import { applications, ApplicationsProps } from "../../Applications"
import styles from "./Detail.styles"
import HotelZone from "./HotelZone"
import Searchbox from "../Searchbox/Searchbox"
import { theme, ThemeProps } from "../BassetProvider"

interface MatchProps {
  id: string
}

interface Props extends ThemeProps, ApplicationsProps, WithTranslation, RouteComponentProps<MatchProps> {}

interface State {
  accommodation: any | null
  roomClusters: any[]
  checkin: string
  checkout: string
  rooms: any | null
  isPriceChecking: boolean
  isFetchingContent: boolean
  isFetchingRates: boolean
  totalGuests: number
  totalRooms: number
  isShowingMap: boolean
  showSearch: boolean
  roomNotAvailable: boolean
  checkoutURL: string
  selectedRate: any | null
  showProvider: boolean
  rewardsPoint: number
  rewards: rewards | null
  financialAdvertising?: any
}

class Detail extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      accommodation: null,
      roomClusters: [],
      checkin: "",
      checkout: "",
      rooms: null,
      isPriceChecking: false,
      isFetchingContent: true,
      isFetchingRates: true,
      totalGuests: 0,
      totalRooms: 0,
      isShowingMap: false,
      showSearch: false,
      roomNotAvailable: false,
      checkoutURL: "",
      selectedRate: null,
      showProvider: false,
      rewardsPoint: 0,
      rewards: null
    }
  }

  onRateSelect = (clusterIndex: number, rateIndex: number) => {
    const { roomClusters } = this.state
    this.setState({
      roomClusters: roomClusters.map((e, i) => {
        return {
          ...e,
          rates: e.rates.map((re, ri) => {
            return { ...re, selected: clusterIndex === i && rateIndex === ri }
          })
        }
      }),
      selectedRate: roomClusters[clusterIndex].rates[rateIndex]
    })
  }

  openSearch = () => {
    this.setState({ showSearch: true })
  }

  hideSearch = () => {
    this.setState({ showSearch: false })
  }

  onViewMap = () => {
    this.setState({ isShowingMap: !this.state.isShowingMap })
  }

  onReloadPage = () => {
    window.location.reload()
  }

  componentDidMount() {
    document.title = this.props.t("Detail.documentTitle")
    this.getDetailResult()
  }

  getDetailResult = async () => {
    const id = this.props.match.params.id
    const { checkin, checkout, distribution, showProvider, points, rate_type } = qs.parse(
      this.props.location.search.substr(1)
    )
    const accommodation = await this.getAccommodationInfo(id)
    this.setState({ accommodation: accommodation, isFetchingContent: false })
    this.getBasicInfo()
    const advertising = await this.getAdvertisings()

    ReactGA.pageview(window.location.pathname + window.location.search)
    if (checkin && checkout && distribution) {
      const rooms = parseDistribution(distribution)

      this.setState({
        totalGuests: calculateTotalGuests(distribution),
        checkin: checkin,
        checkout: checkout,
        rooms: rooms,
        totalRooms: distribution.split(",").length,
        showProvider: !!showProvider,
        rewardsPoint: parseInt(points),
        financialAdvertising: advertising.financial
      })

      const clusters = await this.getRates(id, checkin, checkout, distribution, rate_type)

      this.setState({
        roomClusters: clusters,
        selectedRate: clusters.length > 0 ? clusters[0].rates[0] : null,
        isFetchingRates: false
      })

      if (clusters && clusters.length > 0 && this.hasRewardApplication()) {
        this.setState({ isFetchingRates: true })
        const rewards = await this.getRewards(clusters, checkin, checkout, rooms, accommodation.zone.city.id, "city")
        this.applyRewards(rewards)
      }
    }
  }

  hasRewardApplication = () => {
    return this.getApplication("REWARDS") != null
  }

  applyRewards = rewards => {
    let points = 0
    if (rewards) points = this.state.rewardsPoint > rewards.min_points ? this.state.rewardsPoint : rewards.min_points

    this.setState({
      isFetchingRates: false,
      rewards: rewards,
      rewardsPoint: points
    })
  }

  getApplication = category => {
    const { applications } = this.props
    if (!applications) return null
    const application = applications.filter(a => a.category === category)
    return application ? application[0] : null
  }

  getBasicInfo = async () => {
    const config = await getClientConfig()
    this.setState({ checkoutURL: config.checkout_url })
  }

  getAdvertisings = async (): Promise<any> => {
    try {
      return await getAdvertising()
    } catch (err) {
      console.error(err)
    }
    return null
  }

  getAccommodationInfo = async (id: string): Promise<any> => {
    try {
      return await getAccommodationInformation(id)
    } catch (err) {
      console.error(err)
    }
    return null
  }

  checkRateAndGoToCheckout = async (id: string) => {
    this.setState({ isPriceChecking: true })
    const res = await checkRate(id)
    if (res.status === "ok") {
      let checkoutUrl = this.state.checkoutURL.replace("{id}", id).replace("{product}", "accommodations")

      const { selectedRate, rewardsPoint, rewards, totalGuests } = this.state

      if (rewardsPoint !== 0 && !!rewards) {
        const config = await getClientConfig()
        checkoutUrl = checkoutUrl
          .replace("{rewardsId}", rewards!.id)
          .replace(
            "{rewardsPoints}",
            String(calculatePointsByRate(selectedRate.fare, rewardsPoint, rewards, totalGuests))
          )
          .replace("{channel}", config.channel)
      }

      window.location.href = checkoutUrl
    } else {
      this.setState({ roomNotAvailable: true, isPriceChecking: false })
      animateScrollTo(document.querySelector("#root") as HTMLElement)
    }
  }

  getRates = async (
    hotelID: string,
    checkin: string,
    checkout: string,
    distribution,
    rate_type: string
  ): Promise<any[]> => {
    try {
      const clusters = await getRoomClusters(hotelID, checkin, checkout, distribution, rate_type)
      if (clusters && clusters.length) {
        clusters[0].rates[0].selected = true

        return clusters
      }
    } catch (err) {
      console.error(err)
    }
    return []
  }

  getRewards = async (
    clusters: any[],
    checkin: string,
    checkout: string,
    rooms,
    regionId: string,
    regionType: string
  ): Promise<any> => {
    const rates = clusters.reduce((acc, c) => acc.concat(c.rates), [])
    const priceLimiters = rates.map(rate => priceAssignableToPointsOnRate(rate.fare))
    const minPrice = Math.min(...priceLimiters)
    const maxPrice = Math.max(...priceLimiters)
    const searchParams: SearchParams = {
      location: {
        id: regionId,
        type: regionType
      },
      checkin: moment(checkin),
      checkout: moment(checkout),
      rooms: rooms,
      rate_type: "" // FIXME: this is bad, we are using searchparams with no reason here
    }
    try {
      return await getRewardsPoints(searchParams, minPrice, maxPrice)
    } catch (err) {
      console.error(err)
    }
    return
  }

  onViewRooms = () => {
    animateScrollTo(document.querySelector("#hotelRooms") as HTMLElement)
  }

  onViewAmenities = () => {
    animateScrollTo(document.querySelector("#hotelAmenities") as HTMLElement)
  }

  onSelectRate = (rateID: string) => {
    this.checkRateAndGoToCheckout(rateID)
  }

  onSearch = params => {
    const path = searchParamsToPath(params)
    window.location.href = path
  }

  handlePoints = value => {
    this.setState({ rewardsPoint: value })
  }

  render() {
    const {
      accommodation,
      roomClusters,
      totalGuests,
      checkin,
      checkout,
      rooms,
      totalRooms,
      isPriceChecking,
      isFetchingContent,
      isFetchingRates,
      showSearch,
      roomNotAvailable,
      selectedRate,
      showProvider,
      rewards,
      rewardsPoint,
      financialAdvertising
    } = this.state
    const { theme } = this.props
    const isMobile = window.innerWidth < 1200
    const showRewards = rewards !== null

    if (!accommodation || isFetchingContent) {
      return (
        <Wrapper>
          <div className={styles.backgroundStyle}>
            <div className={styles.mainContainerStyle}>
              <LoadingDetail />
            </div>
          </div>
        </Wrapper>
      )
    }
    let checkinInstructions = ""

    if (accommodation.checkin.instructions) {
      checkinInstructions += accommodation.checkin.instructions
    }
    if (accommodation.checkin.special_instructions) {
      checkinInstructions += accommodation.checkin.special_instructions
    }

    return (
      <Wrapper>
        {isPriceChecking && (
          <div className={styles.appLoaderStyle}>
            <AppLoader />
          </div>
        )}
        <div className={styles.backgroundStyle}>
          {showRewards && rewards && (
            <div className={styles.sliderRewards}>
              <SliderComponent
                color={this.props.theme.brand_colors.highlight_color}
                minPoints={rewards.min_points}
                maxPoints={rewards.max_points}
                precision={rewards.precision}
                handlePoints={this.handlePoints}
                selectedPoints={rewardsPoint}
                userPoints={RewardsCookies(rewards.min_points)}
              />
            </div>
          )}
          <div className={styles.mainContainerStyle}>
            {roomNotAvailable && (
              <ErrorAlert>
                <div>
                  <p className={styles.errorTitle}>La habitación que elegiste ya no está disponible.</p>
                  <p className={styles.errorSubtitle(theme.brand_colors.click_color)}>
                    Te recomendamos <button onClick={this.onReloadPage}>recargar</button> la página o realizar una{" "}
                    <button onClick={this.openSearch}>nueva búsqueda</button>
                  </p>
                </div>
              </ErrorAlert>
            )}
            <HotelDetailSummary
              onViewMap={this.onViewMap}
              name={accommodation.name}
              rating={accommodation.rating}
              stars={accommodation.stars}
              address={`${accommodation.address}, ${accommodation.zone.city.name}`}
              photos={accommodation.images}
              fare={selectedRate ? selectedRate.fare : undefined}
              checkin={checkin}
              checkout={checkout}
              totalGuests={totalGuests}
              fetchingRates={isFetchingRates}
              noAvailability={isFetchingRates ? true : roomClusters.length === 0}
              totalRooms={totalRooms}
              onViewRooms={this.onViewRooms}
              onOpenSearch={this.openSearch}
              rewards={rewards ? { points: rewardsPoint, rewards: rewards, passengers: totalGuests } : undefined}
              financial={financialAdvertising}
            />
            <AmenitiesAndCheckin
              {...highlightedAmenities(accommodation)}
              checkin={accommodation.checkin}
              checkout={accommodation.checkout}
              onViewAmenities={this.onViewAmenities}
            />
            {isFetchingRates && <LoadingRooms />}
            {roomClusters.length > 0 && (
              <RoomClusters
                onRateSelection={this.onSelectRate}
                onRateSelect={this.onRateSelect}
                totalGuests={totalGuests}
                id={"hotelRooms"}
                roomClusters={roomClusters}
                selectedRate={selectedRate}
                showProvider={showProvider}
                rewards={{ points: this.state.rewardsPoint, rewards: rewards, passengers: totalGuests }}
                financial={financialAdvertising}
              />
            )}
            <HotelDescription description={accommodation.description} />
            <HotelZone
              onViewMap={this.onViewMap}
              hotelName={accommodation.name}
              hotelImg={accommodation.images ? accommodation.images[0].url : noPhoto}
              hotelCoordinates={accommodation.location}
              zone={accommodation.zone}
            />
            {accommodation.amenities && accommodation.amenities.length > 0 && (
              <HotelAmenities id={"hotelAmenities"} amenities={accommodation.amenities} />
            )}
            {checkinInstructions.length > 0 && (
              <HotelDescription title={"Instrucciones para el checkin"} description={checkinInstructions} />
            )}
          </div>
        </div>
        <HotelDetailMap
          hotelImg={accommodation.images[0].url}
          hotelCoordinates={accommodation.location}
          hotelName={accommodation.name}
          pois={accommodation.zone.pois}
          subwayStations={accommodation.zone.metro_stations}
          trainStations={accommodation.zone.train_stations}
          mobile={isMobile}
          open={this.state.isShowingMap}
          onClose={this.onViewMap}
        />
        <Dialog open={showSearch} onClose={this.hideSearch}>
          <div className={styles.researchContainerStyle(theme.searchbox_colors.highlight_color)}>
            <div className={styles.searchboxTitleStyle(theme.brand_colors.highlight_color)}>
              <IconButton onClick={this.hideSearch}>
                <Icon>close</Icon>
              </IconButton>
            </div>
            <div className={styles.searchboxContainerStyle}>
              <Searchbox
                destination={{ id: accommodation.id, type: "accommodation", name: accommodation.name }}
                checkin={moment(checkin)}
                checkout={moment(checkout)}
                rooms={rooms}
                onSearch={this.onSearch}
                borderless
                noTitle
              />
            </div>
          </div>
        </Dialog>
      </Wrapper>
    )
  }
}

export default withTranslation()(applications(theme(Detail)))
