import React from "react"
import { css } from "glamor"
import { WithTranslation, withTranslation } from "react-i18next"
import CSSTransition from "react-transition-group/CSSTransition"
import { List, WindowScroller } from "react-virtualized"
import _ from "underscore"
import AccommodationCluster from "./AccommodationCluster"
import { highlightedAmenities, roundingWithStep } from "../../../utils/helpers"
import { calculatePointsByRate, Points } from "../../../utils/rewards"
import HotelRowLoading from "./HotelRowLoading"
import NoAvailFound from "./NoAvailFound"

interface Props extends WithTranslation {
  channel?: string
  site?: string
  loading: boolean
  onPagination: (p: number) => void
  isPaginating: boolean
  pageSize: number
  accommodations: any[]
  total: number
  nights: number
  totalGuests?: number
  onHotelSelect: (id: string, points?: any) => void
  showProvider?: boolean
  rewards?: Points
  financial?: any
}

const LIST_WIDTH = window.screen.width

class HotelList extends React.Component<Props, any> {
  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(this.props, nextProps)
  }

  rowRenderer = ({ index, key, style }: any) => {
    const {
      channel,
      site,
      accommodations,
      onHotelSelect,
      isPaginating,
      onPagination,
      pageSize,
      nights,
      totalGuests,
      t,
      showProvider,
      rewards,
      financial
    } = this.props

    if (index >= accommodations.length) {
      if (!isPaginating) {
        onPagination(Math.floor(index / pageSize))
      }
      return (
        <div key={key} style={style}>
          <HotelRowLoading mobile={this.isMobile()} />
        </div>
      )
    }

    const e = accommodations[index]

    const rate = e.rates[0]
    const mealPlan = rate.meal_plan !== "ROOM_ONLY" ? t(`HotelList.mealPlan.${rate.meal_plan}`) : undefined
    const refundable = rate.refundable
    return (
      <div key={key} style={style}>
        <AccommodationCluster
          site={site}
          channel={channel}
          mobile={this.isMobile()}
          onHotelSelect={() => {
            if (rewards) {
              return onHotelSelect(
                e.id,
                calculatePointsByRate(
                  rate.fare,
                  roundingWithStep(rewards.points, rewards.rewards.precision),
                  rewards.rewards,
                  rewards.passengers
                )
              )
            } else {
              return onHotelSelect(e.id, undefined)
            }
          }} //TODO: Refactor to be agnostic of rewards
          name={e.name}
          address={e.address}
          stars={e.stars}
          photos={e.images}
          rates={e.rates}
          rating={e.rating}
          amenities={highlightedAmenities(e)}
          nights={nights}
          totalGuests={totalGuests}
          mealPlan={mealPlan}
          showProvider={showProvider}
          refundable={refundable}
          rewards={rewards}
          financial={financial}
        />
      </div>
    )
  }

  isMobile = () => {
    return window.innerWidth < 1200
  }

  render() {
    const { channel, loading, accommodations, total, rewards } = this.props
    const desktop = channel === "DESKTOP"
    const ROW_HEIGHT = rewards ? 325 : 280 // + 16px margin
    const DESKTOP_ROW_HEIGHT = rewards ? 325 : 280 // + 16px margin
    const MOBILE_ROW_HEIGHT = rewards ? 585 : 440

    let hasMore = total > accommodations.length
    if (loading) {
      return (
        <CSSTransition classNames="hotels" timeout={500} key={0}>
          <div className={`${containerStyle}`}>
            <HotelRowLoading mobile={this.isMobile()} />
            <HotelRowLoading mobile={this.isMobile()} />
            <HotelRowLoading mobile={this.isMobile()} />
          </div>
        </CSSTransition>
      )
    } else {
      if (accommodations && accommodations.length === 0) {
        return (
          <CSSTransition classNames="hotels" timeout={500} key={0}>
            <NoAvailFound />
          </CSSTransition>
        )
      }
    }

    return (
      <CSSTransition classNames="hotels" timeout={500} key={0}>
        <div className={`${listStyle(desktop)}`}>
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }: any) => (
              <List
                autoHeight
                tabIndex={null}
                height={height}
                rowCount={hasMore ? accommodations.length + 3 : total}
                rowHeight={this.isMobile() ? MOBILE_ROW_HEIGHT : desktop ? DESKTOP_ROW_HEIGHT : ROW_HEIGHT}
                isScrolling={isScrolling}
                onChildScroll={onChildScroll}
                scrollTop={scrollTop}
                rowRenderer={this.rowRenderer}
                style={{ width: this.isMobile() ? window.innerWidth - 16 : "100% !important" }}
                width={this.isMobile() ? window.innerWidth - 16 : LIST_WIDTH}
                rewards={this.props.rewards}
              />
            )}
          </WindowScroller>
        </div>
      </CSSTransition>
    )
  }
}

const listStyle = (desktop: boolean) =>
  css({
    flex: "1 1 auto",
    backgroundColor: "#f0f0f0",
    padding: desktop ? "0" : "8px",
    "& > div": {
      width: "100% !important"
    }
  })

const containerStyle = css({
  padding: "8px",
  backgroundColor: "#f0f0f0",
  " > div": {
    marginBottom: "8px"
  },
  " > div:last-child": {
    marginBottom: "0px"
  },
  "@media(min-width:1200px)": {
    minWidth: "auto",
    width: "100%",
    paddingRight: "24px"
  }
})

export default withTranslation()(HotelList)
