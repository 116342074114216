import { css } from "emotion"

export default {
  titleContainer: (highlightColor: string, clickColor: String) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      " > p": {
        fontSize: "18px",
        lineHeight: 1.33,
        color: highlightColor,
        fontWeight: 300
      },
      " > button": {
        color: `${clickColor} !important`,
        fontSize: "14px",
        cursor: "pointer",
        fontWeight: 300,
        background: "none !important",
        border: "none",
        "&:focus": {
          outline: 0
        },
        " > span": {
          fontSize: "14px"
        }
      }
    }),
  poiTitleStyle: css({
    " > p": {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#4a4a4a"
    },
    "@media(min-width:1200px)": {
      width: "240px",
      marginBottom: 0
    },
    marginBottom: "8px",
    display: "flex",
    flexDirection: "row"
  }),

  poiSectionStyle: css({
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #eaeaea",
    padding: "16px 0px",
    "&:last-child": {
      borderBottom: 0
    },
    "@media(min-width: 1200px)": {
      flexDirection: "row"
    }
  }),

  poisStyle: css({
    display: "flex",
    flexDirection: "column",
    " > div": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "8px",
      flexWrap: "nowrap",
      "@media(min-width:1200px)": {
        width: "352px",
        marginRight: "16px"
      },
      " > p": {
        fontSize: "12px",
        color: "#4a4a4a",
        lineHeight: 1.33,
        fontWeight: 300,
        display: "inline",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      },
      " > p:last-child": {
        fontWeight: 400,
        minWidth: "40px",
        marginLeft: "8px"
      }
    },
    "@media(min-width:1200px)": {
      flex: "3 0 0%",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between"
    }
  }),

  poisContainer: css({
    display: "flex",
    flexDirection: "column"
  }),

  containerStyle: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    backgroundColor: "#fff",
    margin: "0 8px",
    "@media(min-width:1200px)": {
      margin: 0,
      padding: "24px"
    }
  }),

  subtitleStyle: css({
    fontSize: "12px",
    lineHeight: 1.33,
    color: "#9b9b9b",
    fontWeight: 300,
    marginBottom: "16px"
  })
}
