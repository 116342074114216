import HotelIcon from "@material-ui/icons/Hotel"
import { css } from "glamor"
import * as React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import FieldContainer from "./FieldContainer"
import Counter from "./Counter"
import RoomConfiguration from "./RoomConfiguration"

interface Props extends WithTranslation {
  rooms: any[]
  maxRooms: number
  maxPersonsPerRoom: number
  onChangeRoomQuantity: (i: number) => void
  onAdultChangeOnRoom: (i: number, qty: number) => void
  onChildrenQtyChangeOnRoom: (i: number, qty: number) => void
  onChildrenAgeChangeOnRoom: (ri: number, chdInx: number, age: number) => void
  onRemoveRoom: (i: number) => void
}

const RoomSelector = ({
  rooms,
  maxRooms,
  maxPersonsPerRoom,
  onChangeRoomQuantity,
  onAdultChangeOnRoom,
  onChildrenQtyChangeOnRoom,
  onChildrenAgeChangeOnRoom,
  onRemoveRoom,
  t
}: Props) => (
  <>
    <FieldContainer label={t("RoomSelector.rooms")} icon={<HotelIcon style={{ fontSize: "16px" }} />}>
      <Counter value={rooms.length} onChange={onChangeRoomQuantity} maxValue={maxRooms} minValue={1} />
    </FieldContainer>
    <div className={`${roomsContainerStyle}`}>
      {rooms.map((r, i) => (
        <RoomConfiguration
          removable={rooms.length > 1}
          onRemoveRoom={onRemoveRoom}
          onAdultChangeOnRoom={onAdultChangeOnRoom}
          onChildrenQtyChangeOnRoom={onChildrenQtyChangeOnRoom}
          maxPersons={maxPersonsPerRoom}
          adults={r.adults}
          children={r.children}
          onChildrenAgeChangeOnRoom={onChildrenAgeChangeOnRoom}
          index={i}
          key={i}
        />
      ))}
    </div>
  </>
)

const roomsContainerStyle = css({
  " > div": {
    marginTop: "16px"
  }
})

export default withTranslation()(RoomSelector)
