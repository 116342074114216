import React from "react"
import Button from "@material-ui/core/Button"
import Input from "../../material-wrapper/Input/Input"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { theme, ThemeProps } from "../../BassetProvider"
import FilterSection from "./FilterSection"

interface Props extends ThemeProps, WithTranslation {
  title: string
  filterKey: string
  value?: string
  placeholder?: string
  onChange: (fk: string, afs: string[]) => void
}

interface State {
  value: string
}

class NameFilter extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: props.value ? props.value : ""
    }
  }

  _handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      this._handleOnButtonApply()
    }
  }

  _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value })
  }

  _handleOnButtonApply = () => {
    const { value } = this.state
    const { onChange, filterKey } = this.props
    onChange(filterKey, [value])
  }

  render() {
    const { title, theme, placeholder, t } = this.props
    const { value } = this.state

    const buttonStyle = css({
      color: `${theme.brand_colors.click_color} !important`,
      marginTop: "8px !important"
    })

    return (
      <FilterSection title={title}>
        <Input
          placeholder={placeholder}
          highlightColor={theme.brand_colors.click_color}
          value={value}
          onKeyPress={this._handleKeyPress}
          onChange={this._handleOnChange}
          fullWidth
        />
        <Button className={`${buttonStyle}`} onClick={this._handleOnButtonApply}>
          {t("NameFilter.apply")}
        </Button>
      </FilterSection>
    )
  }
}

export default withTranslation()(theme(NameFilter))
