export const getLanguageFromI18n = (i18nInstance: any): string => {
  if (i18nInstance) {
    return i18nInstance.language
      ? i18nInstance.language
      : i18nInstance.options && i18nInstance.options.fallbackLng && i18nInstance.options.fallbackLng.length > 0
      ? i18nInstance.options.fallbackLng[0]
      : "es"
  }
  return ""
}
