import * as React from "react"
import { css, keyframes } from "glamor"

interface Props {
  mobile?: boolean
}

const HotelRowLoading = ({ mobile }: Props) => {
  const isWide = !mobile
  return (
    <div className={[containerStyle, isWide && containerStyleWide].join(" ")}>
      <div className={[isWide && imgStyleWide, animatedBgStyle, imgStyle].join(" ")} />
      <div className={[isWide && mainContentStyleWide, mainContentStyle].join(" ")}>
        <div
          className={[animatedBgStyle, loadingBarStyle].join(" ")}
          style={{ width: "240px", marginBottom: "16px" }}
        />
        <div
          className={[animatedBgStyle, loadingBarStyle].join(" ")}
          style={{ width: "120px", marginBottom: "16px" }}
        />
        <div
          className={[animatedBgStyle, loadingBarStyle].join(" ")}
          style={{ width: "120px", marginBottom: "32px" }}
        />
        <div className={`${dotsContainerStyle}`}>
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "16px", marginRight: "8px" }} />
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "16px", marginRight: "8px" }} />
          <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "16px", marginRight: "8px" }} />
        </div>
      </div>
      <div className={[isWide && priceContainerStyleWide, priceContainerStyle].join(" ")}>
        <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "104px" }} />
        {isWide && <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "184px" }} />}
      </div>
    </div>
  )
}

const priceContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  borderTop: "1px solid #eaeaea",
  justifyContent: "flex-end",
  padding: "16px 8px"
})

const loadingBarStyle = css({
  height: "16px",
  borderRadius: "8px"
})

const dotsContainerStyle = css({
  display: "flex",
  flexDirection: "row"
})

const mainContentStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "8px"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  borderRadius: "3px",
  backgroundColor: "#fff",
  minWidth: "968px",
  width: "100%"
})

const imgStyle = css({
  width: "100%",
  height: "128px"
})

const imgStyleWide = css({
  width: "304px",
  height: "264px"
})

const placeHolderShimmer = keyframes({
  "0%": {
    backgroundPosition: "-468px 0"
  },
  "100%": {
    backgroundPosition: "468px 0"
  }
})

const animatedBgStyle = css({
  animationDuration: "1s",
  animationFillMode: "forwards",
  animationIterationCount: "infinite",
  animationName: `${placeHolderShimmer}`,
  animationTimingFunction: "linear",
  background: "linear-gradient(to right, #dedede 8%, #ccc 18%, #dedede 33%)",
  backgroundSize: "800px 104px"
})

// Wide styles

const priceContainerStyleWide = css({
  flexDirection: "column !important",
  alignItems: "center",
  justifyContent: "space-between !important",
  padding: "48px 24px",
  borderTop: "none !important",
  width: "230px",
  height: "100%",
  borderLeft: "solid 1px #eaeaea !important"
})

const containerStyleWide = css({
  flexDirection: "row !important",
  height: "264px !important"
})

const mainContentStyleWide = css({
  width: "430px",
  justifyContent: "space-between",
  padding: "16px 16px 24px 16px"
})

export default HotelRowLoading
