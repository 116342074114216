import React from "react"
import tripadvisorLogo from "../../images/tripadvisorlogo.svg"
import ratingEmpty from "../../images/tripadvisorRatingEmpty.svg"
import ratingFull from "../../images/tripadvisorRatingFull.svg"
import { css } from "glamor"

interface Props {
  rating: number
  logo?: boolean
}

const TripadvisorRating = ({ rating, logo = true }: Props) => {
  const width = (rating * 65) / 5

  return (
    <div className={`${containerStyle}`}>
      {logo && <img alt={""} src={tripadvisorLogo} className={`${logoStyle}`} />}
      <div className={`${ratingStyle}`}>
        <div style={{ backgroundImage: `url("${ratingEmpty}")` }} className={`${ratingImgStyle}`} />
        <div
          style={{ backgroundImage: `url("${ratingFull}")`, zIndex: 10, width: `${width}px` }}
          className={`${ratingImgStyle}`}
        />
      </div>
    </div>
  )
}

const ratingStyle = css({
  position: "relative",
  height: "14px",
  width: "67px",
  marginLeft: "4px"
})

const ratingImgStyle = css({
  position: "absolute",
  top: "0",
  left: "0",
  height: "13px",
  width: "65px",
  backgrounRepeat: "no-repeat"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
})

const logoStyle = css({
  width: "27px",
  height: "16px"
})

export default TripadvisorRating
