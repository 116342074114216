import * as React from "react"
import Icon from "@material-ui/core/Icon"

import { withTranslation, WithTranslation } from "react-i18next"
import { formatPrice } from "../../utils/helpers"
import { css } from "glamor"

interface Props extends WithTranslation {
  nights: number
  guests: number
  fare: Fare
  site?: string
}

type Fare = {
  base_rate: number
  net_rate: number
  nightly_basis: number
  total: number
  currency: string
  original_currency: {
    currency_code: string
    ratio: number
  }
  charges: any[]
  fees: any[]
  discount?: any
}

const AccommodationPriceDetail = ({ nights, guests, fare, site, t }: Props) => {
  const getCommission = (fare: Fare): number => {
    if (fare.charges) {
      const providerCharges = fare.charges.filter((c: any) => c.collector === "PROVIDER")
      if (providerCharges) {
        const providerCommission = providerCharges.find((c: any) => c.type === "commission")
        return providerCommission ? providerCommission.amount : 0
      }

      const agencyCharges = fare.charges.filter((c: any) => c.collector === "AGENCY")
      if (agencyCharges) {
        const resellerCededCommission = agencyCharges.find((c: any) => c.type === "commission")
        return resellerCededCommission ? resellerCededCommission.amount : 0
      }
    }
    return 0
  }

  const getAgencyCharges = (fare: Fare): number => {
    let total = 0
    if (fare.charges) {
      const agencyCharges = fare.charges.filter((c: any) => c.collector === "AGENCY")
      if (agencyCharges) {
        const agencyMarkup = agencyCharges.find((c: any) => c.type === "markup")
        const agencyOperatingCost = agencyCharges.find((c: any) => c.type === "operating_cost")
        const agencyAdminCost = agencyCharges.find((c: any) => c.type === "admin_cost")

        total = total + (agencyMarkup ? agencyMarkup.amount + agencyMarkup.vat : 0)
        total = total + (agencyOperatingCost ? agencyOperatingCost.amount + agencyOperatingCost.vat : 0)
        total = total + (agencyAdminCost ? agencyAdminCost.amount + agencyAdminCost.vat : 0)
      }

      const providerCharges = fare.charges.filter((c: any) => c.collector === "PROVIDER")
      if (providerCharges) {
        const providerTaxAndServiceFee = providerCharges.find((c: any) => c.type === "tax_and_service_fee")
        // ROOM VAT
        total = total + (providerTaxAndServiceFee ? providerTaxAndServiceFee.vat : 0)
      }
    }
    return total
  }

  // const getTaxPAIS = (fare: Fare): number | undefined => {
  //   if (fare.fees) {
  //     const agencyFees = fare.fees.filter((f: any) => f.collector === "AGENCY")
  //     if (agencyFees) {
  //       const agencyPAISFee = agencyFees.find((f: any) => f.type === "PAIS")

  //       return agencyPAISFee ? agencyPAISFee.amount : undefined
  //     }

  //     const resellerFees = fare.fees.filter((f: any) => f.collector === "RESELLER")
  //     if (resellerFees) {
  //       const resellerPAISFee = resellerFees.find((f: any) => f.type === "PAIS")

  //       return resellerPAISFee ? resellerPAISFee.amount : undefined
  //     }
  //   }
  //   return undefined
  // }

  const commission = getCommission(fare)
  //const paisTax: number | undefined = getTaxPAIS(fare)
  const extraCost = getAgencyCharges(fare)

  return (
    <div className={`${containerStyle}`}>
      <div className={`${rowStyle}`}>
        <p>
          <span>{guests}</span>
          <Icon style={{ fontSize: "12px", padding: "1px", marginRight: "1px" }}>person</Icon>
          <span>{`x ${t("AccommodationPriceDetail.night", { count: nights })}`}</span>
        </p>
        <p>{`${fare.currency} ${formatPrice(fare.net_rate, site)}`}</p>
      </div>
      <div className={`${rowStyle}`}>
        <p>{t("AccommodationPriceDetail.extra_cost")}</p>
        <p>{`${fare.currency} ${formatPrice(extraCost, site)}`}</p>
      </div>
      <div className={[rowStyle, totalStyle].join(" ")}>
        <p>{t("AccommodationPriceDetail.total")}</p>
        <p>{`${fare.currency} ${formatPrice(fare.total, site)}`}</p>
      </div>
      {fare.discount && (
        <div className={`${strikethroughtStyle}`}>
          <p>{`${fare.currency} ${formatPrice(fare.discount.total, site)}`}</p>
        </div>
      )}
      <div className={`${commissionStyle}`}>
        <p>{t("AccommodationPriceDetail.commission")}</p>
        <p>{`${fare.currency} ${formatPrice(commission, site)}`}</p>
      </div>
    </div>
  )
}

const containerStyle = css({
  width: "100%"
})

const commissionStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  color: "#4a4a4a",
  marginTop: "8px",
  paddingTop: "8px",
  borderTop: "1px dotted #ccc"
})

const rowStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  color: "#4a4a4a",
  marginTop: "8px",
  "&:fist-child": {
    marginTop: 0
  }
})

const strikethroughtStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  color: "#9b9b9b",
  textDecoration: "line-through"
})

const totalStyle = css({
  borderTop: "1px dotted #ccc",
  fontWeight: "400",
  lineHeight: "1.14",
  letterSpacing: "0.1px",
  fontSize: "14px",
  paddingTop: "8px"
})
export default withTranslation()(AccommodationPriceDetail)
