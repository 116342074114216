import * as React from "react"
import { css } from "glamor"
import Popover from "@material-ui/core/Popover"

interface Props {
  img: string
  name: string
  lat: number
  lng: number
}

class HotelMarker extends React.PureComponent<Props, any> {
  state = {
    anchorEl: null
  }

  handleOpenPopover = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClosePopover = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { img, name } = this.props
    const { anchorEl } = this.state
    return (
      <div>
        <div onClick={this.handleOpenPopover} className={`${style}`}>
          <img alt={name} src={img} />
        </div>
        <Popover
          open={Boolean(anchorEl)}
          onClose={this.handleClosePopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <p className={`${popoverStyle}`}>{name}</p>
        </Popover>
      </div>
    )
  }
}

const popoverStyle = css({
  color: "#4a4a4a",
  fontWeight: "300",
  textAlign: "center",
  fontSize: "12px",
  padding: "8px"
})

const style = css({
  zIndex: "100",
  width: "44px",
  height: "44px",
  padding: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
  backgroundColor: "#fff",
  " > img": {
    width: "40px",
    height: "40px",
    borderRadius: "50%"
  }
})

export default HotelMarker
