// ext functions & others - non components
import React, { PureComponent } from "react"
import moment, { Moment } from "moment"
import { withTranslation, WithTranslation } from "react-i18next"

// ext components
import AnimateHeight from "react-animate-height"
import Button from "@material-ui/core/Button"

// commons-frontend functions & others
import { theme, ThemeProps } from "../BassetProvider"

// commons frontend - components
import LocationAutocomplete from "../Common/autocomplete"
import RoomSelector from "./RoomSelector"
import SearchboxHeader from "./SearchboxHeader"

// project - functions & others
import { getSearchConfiguration } from "../../api/common"
import { getAutocompleteResults } from "../../api/autocomplete"
import { getRegion } from "../../api/geo"
import * as constants from "../../utils/constants"

// project - components
import styles from "./Searchbox.styles"
import DatePicker from "../Common/date-picker"

import FieldContainer from "./FieldContainer"
import Icon from "@material-ui/core/Icon"

interface Entity {
  id: string
  name: string
  type: string
  iata?: string
}

interface State {
  destination?: Entity
  searchConfiguration?: any
  checkin: Moment | null
  checkout: Moment | null
  minDate?: Moment
  maxDate?: Moment
  rooms: any[]
  autocompleteResults: Entity[]
  isHidden: boolean
}

interface Props extends WithTranslation, ThemeProps {
  destination?: Entity
  checkin: Moment | null
  checkout: Moment | null
  rooms: any[]
  mobile?: boolean
  borderless?: boolean
  noTitle?: boolean
  onSearch: (p: any) => void
  rewards?: any | null
}

const MAX_ROOMS = 4
const MAX_PERSONS_PER_ROOM = 8

class Searchbox extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      destination: props.destination,
      searchConfiguration: null,
      checkin: props.checkin,
      checkout: props.checkout,
      rooms: props.rooms
        ? props.rooms
        : [
            {
              adults: 2,
              children: []
            }
          ],
      autocompleteResults: [],
      isHidden: true
    }
    if (props.destination) {
      if (!props.destination.name) {
        this.getRegionInfo(props.destination.id, props.destination.type)
      }
    }
  }

  componentDidMount() {
    this.getSearchConfiguration()
  }

  getRegionInfo = async (id: string, type: string) => {
    const region = await getRegion(id, type, "es") // FIXME: lang hardcoded
    this.setState({ destination: region })
  }

  pendingAutocompleteRequest?: NodeJS.Timeout = undefined

  addRoom = () => {
    const { rooms } = this.state

    this.setState({
      rooms: [
        ...rooms,
        {
          adults: 2,
          children: []
        }
      ]
    })
  }

  onSearch = () => {
    const { destination, checkin, checkout, rooms } = this.state

    this.props.onSearch({
      location: destination,
      checkin,
      checkout,
      rooms
    })
  }

  removeRoom = (index: number) => {
    const { rooms } = this.state
    this.setState({
      rooms: [...rooms.slice(0, index), ...rooms.slice(index + 1, rooms.length)]
    })
  }

  adultsChangeOnRoom = (roomIndex: number, adultQty: number) => {
    const { rooms } = this.state
    const room = {
      ...rooms[roomIndex],
      adults: adultQty
    }
    this.setState({
      rooms: [...rooms.slice(0, roomIndex), room, ...rooms.slice(roomIndex + 1, rooms.length)]
    })
  }

  changeRoomQuantity = (qty: number) => {
    if (this.state.rooms.length > qty) {
      this.removeRoom(this.state.rooms.length - 1)
    } else {
      this.addRoom()
    }
  }

  changeChildrenQtyOnRoom = (roomIndex: number, childQty: number) => {
    const { rooms } = this.state
    const children = rooms[roomIndex].children
    if (children.length > childQty) {
      this.removeChildrenOnRoom(roomIndex)
    } else {
      this.addChildrenOnRoom(roomIndex)
    }
  }

  removeChildrenOnRoom = (roomIndex: number) => {
    const { rooms } = this.state
    const children = rooms[roomIndex].children
    const room = {
      ...rooms[roomIndex],
      children: children.slice(0, children.length - 1)
    }
    this.setState({
      rooms: [...rooms.slice(0, roomIndex), room, ...rooms.slice(roomIndex + 1, rooms.length)]
    })
  }

  addChildrenOnRoom = (roomIndex: number) => {
    const { rooms } = this.state
    const children = rooms[roomIndex].children
    const room = {
      ...rooms[roomIndex],
      children: [...children, 0]
    }
    this.setState({
      rooms: [...rooms.slice(0, roomIndex), room, ...rooms.slice(roomIndex + 1, rooms.length)]
    })
  }

  childrenAgeChangeOnRoom = (roomIndex: number, chdIndex: number, age: number) => {
    const { rooms } = this.state
    const children = rooms[roomIndex].children
    const room = {
      ...rooms[roomIndex],
      children: [...children.slice(0, chdIndex), age, ...children.slice(chdIndex + 1, children.length)]
    }
    this.setState({
      rooms: [...rooms.slice(0, roomIndex), room, ...rooms.slice(roomIndex + 1, rooms.length)]
    })
  }

  fetchAutocomplete = async (value: string) => {
    const { rewards } = this.props
    let result
    if (rewards) {
      result = await getAutocompleteResults(value, !!rewards)
    } else {
      result = await getAutocompleteResults(value)
    }
    this.setState({ autocompleteResults: result })
  }

  onAutocompleteChange = (value: string) => {
    if (value.trim().length >= 3) {
      // throtteling requests to autocomplete
      if (this.pendingAutocompleteRequest) clearTimeout(this.pendingAutocompleteRequest)
      this.pendingAutocompleteRequest = setTimeout(() => this.fetchAutocomplete(value), 300)
    }
  }

  onClearAutocomplete = () => {
    this.setState({ autocompleteResults: [] })
  }

  onAutocompleteSelect = (select: Entity) => {
    this.setState({ destination: select })
  }

  onSelectDate = (checkin: Moment, checkout: Moment | null) => {
    this.setState({ checkin: checkin, checkout: checkout })
  }

  changeDisplay = () => {
    this.setState({ isHidden: !this.state.isHidden })
  }

  getSearchConfiguration = async () => {
    const searchConfiguration = await getSearchConfiguration()
    this.setState({ searchConfiguration: searchConfiguration })
  }

  render() {
    const { rooms, autocompleteResults, destination, checkin, checkout, isHidden, searchConfiguration } = this.state
    let { minDate, maxDate } = this.state
    const { t, theme, mobile, borderless, noTitle } = this.props

    let rangeDays = constants.MAXIUM_NIGHTS

    if (searchConfiguration && searchConfiguration.range_days) {
      rangeDays = searchConfiguration.range_days
    }

    let guests = 0
    for (let r of rooms) {
      guests += r.adults + r.children.length
    }
    const height = mobile && isHidden ? "0" : "auto"

    if (!minDate) {
      if (searchConfiguration && searchConfiguration.min_days) {
        minDate = moment()
          .add(searchConfiguration.min_days, "d")
          .startOf("day")
      } else {
        minDate = moment()
          .add(0, "d")
          .startOf("day")
      }
    }

    if (!maxDate) {
      if (searchConfiguration && searchConfiguration.max_days) {
        maxDate = moment()
          .add(searchConfiguration.max_days, "d")
          .startOf("day")
      } else {
        maxDate = moment()
          .add(330, "d")
          .startOf("day")
      }
    }

    return (
      <div className={styles.containerStyle(theme.searchbox_colors.highlight_color, borderless)}>
        {mobile && (
          <SearchboxHeader
            destination={destination ? destination.name : ""}
            checkin={checkin ? checkin.format("DD MMM") : ""}
            checkout={checkout ? checkout.format("DD MMM") : ""}
            rooms={rooms.length}
            guests={guests}
            isOpen={!isHidden}
            onClick={this.changeDisplay}
          />
        )}
        {!mobile && !noTitle && (
          <p className={styles.searchboxTitle(theme.searchbox_colors.background_color)}>
            {t("Searchbox.findYourHotel")}
          </p>
        )}
        <AnimateHeight duration={400} height={height}>
          <div className={styles.searchboxStyle(borderless, mobile)}>
            <div className={styles.mainContainer}>
              <FieldContainer
                label={t("Searchbox.destination")}
                icon={<Icon style={{ fontSize: "16px" }}>location_on</Icon>}
              >
                <LocationAutocomplete
                  index={0}
                  label={t("Searchbox.destination")}
                  placeholder={t("Searchbox.typeADestination")}
                  dataSource={autocompleteResults}
                  onClear={this.onClearAutocomplete}
                  onSelectItem={this.onAutocompleteSelect}
                  onUpdateInput={this.onAutocompleteChange}
                  selectedLocation={destination}
                />
              </FieldContainer>
              <DatePicker
                index={0}
                minDate={minDate}
                maxDate={maxDate}
                selectedDateFrom={checkin}
                selectedDateTo={checkout}
                usesEndDate
                onSelectRange={this.onSelectDate}
                maxNights={rangeDays}
                minNights={constants.MINIMUM_NIGHTS}
              />
              <RoomSelector
                onRemoveRoom={this.removeRoom}
                onChildrenQtyChangeOnRoom={this.changeChildrenQtyOnRoom}
                onChangeRoomQuantity={this.changeRoomQuantity}
                onChildrenAgeChangeOnRoom={this.childrenAgeChangeOnRoom}
                onAdultChangeOnRoom={this.adultsChangeOnRoom}
                maxRooms={MAX_ROOMS}
                maxPersonsPerRoom={MAX_PERSONS_PER_ROOM}
                rooms={rooms}
              />
            </div>
            <Button
              onClick={this.onSearch}
              variant={"contained"}
              className={styles.buttonStyle(
                theme.searchbox_colors.click_color,
                theme.searchbox_colors.background_color
              )}
            >
              {t("Searchbox.find")}
            </Button>
          </div>
        </AnimateHeight>
      </div>
    )
  }
}

export default withTranslation()(theme(Searchbox))
