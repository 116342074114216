import { css } from "glamor"
import * as constants from "./constants"

export const mediaMaxMobileCss = css({ display: "none", "@media(max-width: 991px)": { display: "flex" } })

export const mediaMinDesktopCss = css({ display: "none", "@media(min-width: 992px)": { display: "flex" } })

export const isMobile = () => {
  return window.matchMedia(`(max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px)`).matches
}
