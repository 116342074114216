import * as React from "react"
import { css, keyframes } from "glamor"

const LoadingButton = () => {
  return (
    <div>
      <div
        className={[animatedBgStyle, loadingBarStyle].join(" ")}
        style={{ width: "168px", marginBottom: "8px", marginTop: "16px" }}
      />
      <div className={[animatedBgStyle, loadingBarStyle].join(" ")} style={{ width: "168px", marginBottom: "16px" }} />
      <div className={[animatedBgStyle, buttonStyle].join(" ")} />
    </div>
  )
}

const buttonStyle = css({
  borderRadius: "1px",
  height: "32px",
  width: "100%",
  "@media(min-width:1200px)": {
    width: "168px"
  }
})

const placeHolderShimmer = keyframes({
  "0%": {
    backgroundPosition: "-468px 0"
  },
  "100%": {
    backgroundPosition: "468px 0"
  }
})

const animatedBgStyle = css({
  animationDuration: "1s",
  animationFillMode: "forwards",
  animationIterationCount: "infinite",
  animationName: `${placeHolderShimmer}`,
  animationTimingFunction: "linear",
  background: "linear-gradient(to right, #dedede 8%, #ccc 18%, #dedede 33%)",
  backgroundSize: "800px 104px"
})

const loadingBarStyle = css({
  height: "16px",
  borderRadius: "8px"
})

export default LoadingButton
