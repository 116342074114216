import React from "react"
import starIcon from "../../images/star.svg"
import { css } from "glamor"

interface Props {
  amount: number
  size?: string
}

const Stars = ({ amount, size }: Props) => (
  <span className={`${containerStyle}`}>
    {Array.apply(null, Array(Math.floor(amount))).map((e: any, i: number) => (
      <img key={i} style={{ width: size ? size : "12px", height: size ? size : "12px" }} alt={""} src={starIcon} />
    ))}
  </span>
)

const containerStyle = css({
  display: "flex",
  flexDirection: "row"
})

export default Stars
