import React from "react"
import { css } from "glamor"
import GoogleMap from "google-map-react"
import * as componentQueries from "react-component-queries"
import { theme, ThemeProps } from "../../BassetProvider"
import AccommodationCluster from "./AccommodationCluster"
import Sticky from "react-stickynode"
import { highlightedAmenities, formatPrice } from "../../../utils/helpers"

interface Props extends ThemeProps {
  channel?: string
  accommodations: any[]
  nights: number
  totalGuests?: number
  onHotelSelect: (id: string) => void
  mode?: string
  top?: string
  bottomBoundary?: string
  site?: string
}

interface State {
  selectedIndex: number | null
}

class HotelListMap extends React.PureComponent<Props, State> {
  state = {
    selectedIndex: null
  }

  onSelect = (index: number) => {
    this.setState({ selectedIndex: index })
  }

  clearSelect = () => {
    this.setState({ selectedIndex: null })
  }

  render() {
    const {
      channel,
      accommodations,
      nights,
      totalGuests,
      mode,
      onHotelSelect,
      theme,
      top,
      bottomBoundary,
      site
    } = this.props
    const { selectedIndex } = this.state

    return (
      <Sticky innerZ={1000} top={top} bottomBoundary={bottomBoundary}>
        <div className={`${containerStyle}`}>
          <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <GoogleMap
                defaultCenter={{
                  lat: accommodations[0].geo_location.latitude,
                  lng: accommodations[0].geo_location.longitude
                }}
                onClick={this.clearSelect}
                onChange={this.clearSelect}
                defaultZoom={13}
                bootstrapURLKeys={{ key: "AIzaSyDGPT1jvVhMQtKD3dtZNFu_2iuxBeGH5ns" }}
              >
                {accommodations.map((e, i) => {
                  const label = `${e.rates[0].currency} ${formatPrice(e.rates[0].nightly_basis, site)}`

                  return (
                    <Marker
                      width={`${label.length * 11}px`}
                      key={i}
                      lat={e.geo_location.latitude}
                      lng={e.geo_location.longitude}
                      label={label}
                      selectedColor={theme.brand_colors.selection_color}
                      selected={i === selectedIndex}
                      onClick={() => this.onSelect(i)}
                    />
                  )
                })}
              </GoogleMap>
            </div>
            {selectedIndex !== null && (
              <div className={`${hotelStyle}`}>
                <AccommodationCluster
                  mobile
                  channel={channel}
                  nights={nights}
                  totalGuests={totalGuests}
                  onHotelSelect={() => onHotelSelect(accommodations[selectedIndex!].id)}
                  name={accommodations[selectedIndex!].name}
                  address={accommodations[selectedIndex!].address}
                  stars={accommodations[selectedIndex!].stars}
                  rates={accommodations[selectedIndex!].rates}
                  refundable={accommodations[selectedIndex!].rates[0].refundable}
                  photos={accommodations[selectedIndex!].images}
                  rating={accommodations[selectedIndex!].rating}
                  amenities={highlightedAmenities(accommodations[selectedIndex!])}
                  mini={mode === "narrow"}
                  site={site}
                />
              </div>
            )}
          </div>
        </div>
      </Sticky>
    )
  }
}

interface MarkerProps {
  width: string
  label: string
  selected: boolean
  selectedColor: string
  onClick: () => void
  lat: number
  lng: number
}

const Marker = ({ width, label, selected, selectedColor, onClick }: MarkerProps) => {
  return (
    <div
      style={{ width: width, backgroundColor: selected ? selectedColor : "#fff", color: selected ? "#fff" : "#4a4a4a" }}
      className={`${markerStyle}`}
      onClick={onClick}
    >
      <p>{label}</p>
    </div>
  )
}

const hotelStyle = css({
  width: "calc(100vw - 32px)",
  position: "absolute",
  display: "flex",
  top: "16px",
  left: "16px",
  zIndex: "2000",
  maxHeight: "240px",
  "@media(min-width: 1200px)": {
    width: "328px",
    maxHeight: "unset"
  }
})

const markerStyle = css({
  padding: "4px 8px",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
  border: "solid 1px #ccc",
  borderRadius: "3px",
  height: "24px",
  alignItems: "center",
  display: "flex",
  cursor: "pointer",
  transform: "translate(-50%, -50%)",
  " > p": {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.71",
    height: "24px",
    margin: "0 auto",
    textAlign: "center"
  }
})

const containerStyle = css({
  width: "100%",
  height: "calc(100vh - 56px)", // hackish way to make it work in mobile ()
  position: "absolute",
  top: "0",
  left: "0",
  backgroundColor: "#f0f0f0",
  "@media(min-width:1200px)": {
    padding: "8px",
    top: "auto",
    left: "auto",
    position: "static",
    height: "100vh"
  }
})

export default componentQueries(({ width }: any) => {
  let mode = width < 768 ? "narrow" : "wide"
  return {
    mode: mode
  }
})(theme(HotelListMap))
