import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"
import { css } from "glamor"
import * as React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { Theme, theme, ThemeProps } from "../../BassetProvider"
import ComboBox from "../../Common/comboBox/ComboBox"

interface Props extends ThemeProps, WithTranslation {
  onViewMap: () => void
  id?: string
  appliedSortingOption: any
  availableSortingOptions: any
  onFilterChange: (fk: string, afs: string[]) => void
  searchParams: any
  rewards?: any
  viewMap?: boolean
  regionName: string
}

class ListHeader extends React.Component<Props, any> {
  state = {
    destination: null
  }

  onInputChange = (selectedOption: any) => {
    const { onFilterChange } = this.props
    onFilterChange("order_by", [selectedOption.value])
  }

  render() {
    const {
      onViewMap,
      id,
      availableSortingOptions,
      appliedSortingOption,
      regionName,
      rewards,
      viewMap,
      t,
      theme
    } = this.props

    return (
      <div id={id} className={`${containerStyle}`}>
        {regionName && (
          <div className={`${destinationStyle}`}>{t("ListHeader.hotels_in_region", { region: regionName })}</div>
        )}
        {availableSortingOptions && (
          <div style={{ width: "400px", margin: "0 16px" }}>
            <ComboBox
              options={availableSortingOptions.options.map((option: any) => ({
                value: option,
                label: t(`ListHeader.sorting.${option}`)
              }))}
              placeholder={t("ListHeader.sort")}
              onChange={this.onInputChange}
              name="sort"
              value={appliedSortingOption}
            />
          </div>
        )}
        {!rewards && (
          <Button onClick={onViewMap} variant={"outlined"} className={`${buttonStyle(theme)}`}>
            <Icon className={`${iconStyle}`}>{viewMap ? "list" : "location_on"}</Icon>
            {viewMap ? t("ListHeader.list_view") : t("ListHeader.map_view")}
          </Button>
        )}
      </div>
    )
  }
}

const buttonStyle = (theme: Theme) =>
  css({
    fontSize: "16px !important",
    lineHeight: "1 !important",
    color: `${theme.brand_colors.click_color} !important`,
    minHeight: "32px !important",
    textTransform: "none !important",
    minWidth: "170px !important"
  })

const containerStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: "100%",
  height: "32px",
  marginBottom: "8px"
})

const iconStyle = css({
  fontSize: "14px !important",
  marginRight: "5px"
})

const destinationStyle = css({
  fontSize: "16px !important",
  lineHeight: "1 !important",
  color: "#4a4a4a",
  width: "100%",
  alignItems: "center",
  display: "flex"
})

export default theme(withTranslation()(ListHeader))
