import Dialog from "@material-ui/core/Dialog"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import { css } from "glamor"
import GoogleMap from "google-map-react"
import * as React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { Theme, theme, ThemeProps } from "../../BassetProvider"
import CheckIcon from "../../Common/CheckIcon"
import HotelMarker from "./HotelMarker"
import PoiMarker from "./PoiMarker"

interface Props extends ThemeProps, WithTranslation {
  hotelImg: string
  hotelCoordinates: {
    latitude: number
    longitude: number
  }
  hotelName: string
  pois?: any[]
  airports?: any[]
  subwayStations?: any[]
  trainStations?: any[]
  mobile?: boolean
  open: boolean
  onClose: () => void
}

class HotelDetailMap extends React.PureComponent<Props, any> {
  state = {
    renderPois: true,
    renderTrainStations: true,
    renderSubWayStations: true,
    renderAirports: true
  }

  handlePoiClick = () => {
    this.setState({ renderPois: !this.state.renderPois })
  }
  handleTrainClick = () => {
    this.setState({ renderTrainStations: !this.state.renderTrainStations })
  }
  handleSubwayClick = () => {
    this.setState({ renderSubWayStations: !this.state.renderSubWayStations })
  }
  handleAirportClick = () => {
    this.setState({ renderAirports: !this.state.renderAirports })
  }

  render() {
    const {
      hotelImg,
      hotelCoordinates,
      hotelName,
      pois,
      airports,
      subwayStations,
      trainStations,
      mobile,
      open,
      onClose,
      theme,
      t
    } = this.props

    const { renderPois, renderTrainStations, renderSubWayStations, renderAirports } = this.state
    const shouldRenderLeftColumn =
      (pois && pois.length > 0) ||
      (airports && airports.length > 0) ||
      (subwayStations && subwayStations.length > 0) ||
      (trainStations && trainStations.length > 0)

    const poisDrawed = (
      <div>
        <p className={`${subTitleStyle}`}>Seleccione las listas que desea visualizar en el mapa</p>
        <div>
          {pois &&
            pois.length > 0 &&
            createPoiSection(t("HotelDetailMap.points_of_interest"), renderPois, pois, this.handlePoiClick, "#e44652")}
          {trainStations &&
            trainStations.length > 0 &&
            createPoiSection(
              t("HotelDetailMap.train_stations"),
              renderTrainStations,
              trainStations,
              this.handleTrainClick,
              "#fdb43c"
            )}
          {subwayStations &&
            subwayStations.length > 0 &&
            createPoiSection(
              t("HotelDetailMap.subway_stations"),
              renderSubWayStations,
              subwayStations,
              this.handleSubwayClick,
              "#92bce3"
            )}
          {airports &&
            createPoiSection(
              t("HotelDetailMap.airports"),
              renderAirports,
              airports,
              this.handleAirportClick,
              "#59c28d"
            )}
        </div>
      </div>
    )

    return (
      <Dialog maxWidth={"md"} open={open} onClose={onClose} fullScreen={mobile}>
        <div className={`${mobileNavBar(theme)}`}>
          <p>{t("HotelDetailMap.location")}</p>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div className={`${containerStyle}`}>
          {!mobile && shouldRenderLeftColumn && <div className={`${leftColumnStyle}`}>{poisDrawed}</div>}
          <div className={`${mapContainerStyle}`}>
            <div style={{ width: "100%", height: "100%" }}>
              <GoogleMap
                defaultCenter={{ lat: hotelCoordinates.latitude, lng: hotelCoordinates.longitude }}
                defaultZoom={15}
                bootstrapURLKeys={{ key: "AIzaSyDGPT1jvVhMQtKD3dtZNFu_2iuxBeGH5ns" }}
              >
                <HotelMarker
                  name={hotelName}
                  img={hotelImg}
                  lat={hotelCoordinates.latitude}
                  lng={hotelCoordinates.longitude}
                />
                {pois &&
                  renderPois &&
                  pois.map((e, i) => (
                    <PoiMarker
                      lat={e.location.latitude}
                      lng={e.location.longitude}
                      name={e.name}
                      key={i}
                      backgroundColor={"#e44652"}
                    />
                  ))}
                {trainStations &&
                  renderTrainStations &&
                  trainStations.map((e, i) => (
                    <PoiMarker
                      lat={e.location.latitude}
                      lng={e.location.longitude}
                      name={e.name}
                      key={i}
                      backgroundColor={"#fdb43c"}
                    />
                  ))}
                {subwayStations &&
                  renderSubWayStations &&
                  subwayStations.map((e, i) => (
                    <PoiMarker
                      lat={e.location.latitude}
                      lng={e.location.longitude}
                      name={e.name}
                      key={i}
                      backgroundColor={"#92bce3"}
                    />
                  ))}
                {airports &&
                  renderAirports &&
                  airports.map((e, i) => (
                    <PoiMarker
                      lat={e.location.latitude}
                      lng={e.location.longitude}
                      name={e.name}
                      key={i}
                      backgroundColor={"#59c28d"}
                    />
                  ))}
              </GoogleMap>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const createPoiSection = (title: string, checked: boolean, pois: any[], onClick: () => void, checkColor: string) => {
  return (
    <div>
      <div onClick={onClick} className={`${sectionTitleStyle}`}>
        <CheckIcon backgroundColor={checkColor} checked={checked} />
        <p>{title}</p>
      </div>
      <div className={`${poisStyle}`}>
        {pois.map((e, i) => (
          <p key={i}>
            {`${e.name} - `}
            <span>{e.distance}</span>
          </p>
        ))}
      </div>
    </div>
  )
}

const poisStyle = css({
  marginTop: "8px",
  " > p": {
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "1.33",
    color: "#4a4a4a",
    " > span": {
      color: "#9b9b9b"
    }
  }
})

const sectionTitleStyle = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  " > p": {
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#4a4a4a",
    fontWeight: "300",
    marginLeft: "8px"
  }
})

const subTitleStyle = css({
  fontSize: "12px",
  color: "#9b9b9b",
  lineHeight: "1.33",
  fontWeight: "300"
})

const mapContainerStyle = css({
  width: "100%",
  height: "100%"
})

const leftColumnStyle = css({
  display: "flex",
  flexDirection: "column",
  width: "376px",
  height: "100%",
  backgroundColor: "#fff",
  padding: "0px 24px 16px",
  overflow: "scroll",
  boxSizing: "content-box"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  "@media(min-width: 1200px)": {
    flexDirection: "row",
    width: "960px",
    height: "544px"
  }
})

const mobileNavBar = (theme: Theme) =>
  css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "8px 24px",
    alignItems: "center",
    justifyContent: "space-between",
    " > p": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontSize: "18px",
      fontWeight: "300",
      lineHeight: "1.33",
      color: theme.brand_colors.highlight_color
    }
  })

export default theme(withTranslation()(HotelDetailMap))
