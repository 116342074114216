import * as moment from "moment"
import "moment/locale/es"
import "moment/locale/en-gb"

export const setMomentLocale = (language: string) => {
  const esLocale = {
    parentLocale: "es",
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split("_"),
    weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    weekdaysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sá".split("_")
  }

  moment.updateLocale("es", esLocale)

  moment.locale(language)
}
