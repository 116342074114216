import { Moment } from "moment"

export interface SearchParams {
  location: {
    id: string
    type: string
  }
  checkin: Moment
  checkout: Moment
  rooms: Room[]
  rate_type: string
  pagination?: Pagination
  filters?: Filters
  order_by?: string
  showProvider?: boolean
  shouldSearchPackageRates?: boolean
}

export interface Pagination {
  limit: number
  offset: number
}

export interface Filters {
  stars?: string[]
  mealPlan?: string[]
  zone?: string[]
  name?: string[]
  amenities?: string[]
  price?: string[]
  category?: string[]
}

export interface Room {
  adults: number
  children: Array<number>
}

export enum RateTypeEnum {
  Standalone = "STANDALONE",
  PackageOpaque = "PACKAGE_OPAQUE",
  PackagePublic = "PACKAGE_PUBLIC",
  Deal = "DEAL"
}

export class Slider {
  position: string
  images: Array<Image>
  duration: number

  constructor(obj: any, position: string) {
    this.position = position
    this.images = obj.images.map((i: any) => {
      return new Image(i)
    })
    this.duration = obj.duration
  }
}

class Image {
  url: string
  alt: string
  link: string

  constructor(obj: any) {
    this.url = obj.url
    this.alt = obj.alt
    this.link = obj.link
  }
}
