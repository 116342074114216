import * as React from "react"
import { Subtract } from "utility-types"
import { ApplicationsContextConsumer } from "./ApplicationsContext"

export interface Application {
  id: string
  active: true
  metadata: any
  type: string
  category: string
}

export interface ApplicationsProps {
  applications: Application[]
}

export const applications = <P extends ApplicationsProps>(
  Component: React.ComponentClass<P> | React.StatelessComponent<P>
) => {
  return class ApplicationsComponent extends React.Component<Subtract<P, ApplicationsProps>, React.ComponentState> {
    render() {
      return (
        <ApplicationsContextConsumer>
          {applications => <Component {...(this.props as P)} applications={applications} />}
        </ApplicationsContextConsumer>
      )
    }
  }
}
