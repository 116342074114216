import React from "react"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import Icon from "@material-ui/core/Icon"
import { css } from "glamor"
import { WithTranslation, withTranslation } from "react-i18next"
import FilterIcon from "../../../images/filters.svg"
import { Theme, theme, ThemeProps } from "../../BassetProvider"

interface Props extends ThemeProps, WithTranslation {
  onViewMap: () => void
  onViewFilters: () => void
  onViewSorting: () => void
  viewMap: boolean
}

const MobileBottomNavigation = ({ theme, onViewMap, onViewFilters, viewMap, onViewSorting, t }: Props) => (
  <BottomNavigation className={`${navStyle}`} showLabels>
    <BottomNavigationAction
      onClick={onViewMap}
      className={`${buttonStyle(theme)}`}
      label={viewMap ? t("MobileBottomNavigation.list") : t("MobileBottomNavigation.map")}
      icon={<Icon>{viewMap ? "list" : "location_on"}</Icon>}
    />
    <BottomNavigationAction
      onClick={onViewSorting}
      className={`${buttonStyle(theme)}`}
      label={t("MobileBottomNavigation.sort")}
      icon={<Icon>swap_vert</Icon>}
    />
    <BottomNavigationAction
      className={`${buttonStyle(theme)}`}
      onClick={onViewFilters}
      label={t("MobileBottomNavigation.filter")}
      icon={
        <img height="18px" className={`${filterIconStyle}`} src={FilterIcon} alt={t("MobileBottomNavigation.filter")} />
      }
    />
  </BottomNavigation>
)

const buttonStyle = (theme: Theme) =>
  css({
    color: `${theme.brand_colors.click_color} !important`
  })

const navStyle = css({
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
  zIndex: "3000",
  boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)"
})

const filterIconStyle = css({
  margin: "3px 0px"
})

export default withTranslation()(theme(MobileBottomNavigation))
