import React from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { Theme, theme, ThemeProps } from "../../BassetProvider"

interface Props extends ThemeProps, WithTranslation {
  appliedSortingOption: any
  availableSortingOptions: any
  onFilterChange: (fk: string, afs: string[]) => void
}

class SortingOptions extends React.PureComponent<Props, any> {
  onInputChange = (event: any) => {
    const { value } = event.target
    const { onFilterChange } = this.props
    onFilterChange("order_by", value.length ? [value] : [])
  }

  render() {
    let { appliedSortingOption, availableSortingOptions, theme, t } = this.props

    return (
      <div className={`${containerStyle}`}>
        <div className={`${headerStyle}`}>
          <p>{t("SortingOptions.sort")}</p>
        </div>
        <RadioGroup>
          {availableSortingOptions.options.map((option: any, i: number) => (
            <div key={`div${i}`} className={`${checkBoxContainerStyle}`}>
              <Radio
                classes={{
                  root: `${checkboxStyle}`,
                  checked: `${checkboxCheckedStyle(theme)}`
                }}
                onChange={this.onInputChange}
                checked={option === appliedSortingOption}
                className={`${checkboxStyle}`}
                value={option}
              />
              <span className={`${labelStyle}`}>{t(`SortingOptions.sorting.${option}`)}</span>
            </div>
          ))}
        </RadioGroup>
      </div>
    )
  }
}

const checkBoxContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: "0px"
  }
})

const checkboxStyle = css({
  width: "18px !important",
  height: "18px !important",
  color: "#4a4a4a"
})

const labelStyle = css({
  marginLeft: "8px",
  fontSize: "16px",
  lineHeight: "1.5",
  fontWeight: "300",
  letterSpacing: "0.6px",
  color: "#4a4a4a"
})

const containerStyle = css({
  display: "flex",
  padding: "16px",
  flexDirection: "column"
})

const headerStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
  " > p": {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#4a4a4a"
  },
  cursor: "pointer"
})

const checkboxCheckedStyle = (theme: Theme) =>
  css({
    color: `${theme.brand_colors.selection_color} !important`
  })

export default withTranslation()(theme(SortingOptions))
