import { CookiesProvider, Cookies } from "react-cookie"

export interface Points {
  points: number
  rewards: rewards
  passengers: number
}
export interface rewards {
  id: string
  coefficient: number
  max_points: number
  min_percentage: number
  min_points: number
  precision: number
  ratio: number
  fee: number
  taxes_max_percentage: number
  taxes_cod: string[]
}

//COMMONS
export const calculatePointsLimitsByPrice = (
  price: number,
  rewards: rewards | undefined
): { minPoints: number; maxPoints: number } => {
  let minPoints = 0,
    maxPoints = 0

  if (rewards && rewards.coefficient && rewards.precision && rewards.min_percentage) {
    const exactMinPoints = (price * (rewards.min_percentage / 100)) / rewards.coefficient
    minPoints = Math.round(exactMinPoints / rewards.precision) * rewards.precision
    if (minPoints <= 0) minPoints = rewards.precision //avoid zero on very low prices

    const exactMaxPoint = price / rewards.coefficient
    maxPoints = Math.round(exactMaxPoint / rewards.precision) * rewards.precision
    if (maxPoints <= 0) maxPoints = rewards.precision //avoid zero on very low prices
  }

  return { minPoints, maxPoints }
}

export const calculatePercentage = (
  points: number,
  minPoints: number,
  maxPoints: number,
  minPercentage: number
): number => {
  if (points === minPoints) return minPercentage
  else return (points * 100) / maxPoints
}

export const calculatePercentageFromPrice = (points: number, price: number, rewards: rewards): number => {
  const { minPoints, maxPoints } = calculatePointsLimitsByPrice(price, rewards)
  const finalPoints = validPoints(points, minPoints, maxPoints)

  return calculatePercentage(finalPoints, minPoints, maxPoints, rewards.min_percentage)
}

const validPoints = (points: number, min: number, max: number): number => {
  return points < min ? min : points > max ? max : points
}

export const calculatePointsInCash = (percentage: number, priceAssignableToPoints: number) => {
  return ((100 - percentage) * priceAssignableToPoints) / 100
}

export const calculateCashGeneric = (
  points: number,
  priceAssignableToPoints: number,
  priceAssignableToCash: number,
  rewards: rewards,
  passengers: number
) => {
  let cash: number = 0
  const percentage = calculatePercentageFromPrice(points, priceAssignableToPoints, rewards)
  cash = calculatePointsInCash(percentage, priceAssignableToPoints) //Calculate how much cash wasnt cover by points
  //cash += priceAssignableToCash
  cash += rewards.fee * passengers
  return cash
}

export const calculateFinalPoints = (price: number, points: number, rewards: rewards): number => {
  const { minPoints, maxPoints } = calculatePointsLimitsByPrice(price, rewards)
  return validPoints(points, minPoints, maxPoints)
}

//FLIGHTS
export const calculatePointsByCluster = (price: any, points: number, rewards: rewards) => {
  const priceAssignableToPoints = priceAssignableToPointsOnCluster(price, rewards)
  return calculateFinalPoints(priceAssignableToPoints, points, rewards)
}

export const getTotalFare = (price: any): number => {
  return (
    (price.adults !== undefined ? price.adults.fare : 0) +
    (price.children !== undefined ? price.children.fare : 0) +
    (price.infants !== undefined ? price.infants.fare : 0)
  )
}

export const getNoAssignableTaxes = (price: any, rewards: rewards): number => {
  let totalTaxes = 0
  if (rewards.taxes_cod.length === 0) return totalTaxes
  const taxesCodes = rewards.taxes_cod

  const filterTaxes = (taxes: any[], codes: string[]) => {
    const impuestos = taxes.filter(t => {
      return codes.some(c => {
        return t.type.includes(c) //This code is tricky: the codes like YQI have to match with YQ taxes
      })
    })
    return impuestos.reduce((a, b) => a + b.total, 0)
  }

  if (price.adults !== undefined && price.adults.taxes && price.adults.taxes.length > 0)
    totalTaxes += filterTaxes(price.adults.taxes, taxesCodes)

  if (price.children !== undefined && price.children.taxes.length > 0)
    totalTaxes += filterTaxes(price.children.taxes, taxesCodes)

  if (price.infants !== undefined && price.infants.taxes.length > 0)
    totalTaxes += filterTaxes(price.infants.taxes, taxesCodes)

  return totalTaxes
}

export const priceAssignableToPointsOnCluster = (price: any, rewards: rewards): number => {
  return getTotalFare(price) + getNoAssignableTaxes(price, rewards)
}

const priceAssignableToCashOnCluster = (price: any, priceAssignableToPoints: number, rewards: rewards): number => {
  let noAssignableTaxes = price.charges

  noAssignableTaxes += price.taxes - getNoAssignableTaxes(price, rewards)

  const maxDeducibleTaxes = priceAssignableToPoints * (rewards.taxes_max_percentage / 100) //refear to documentation to understand this calculation
  if (noAssignableTaxes > maxDeducibleTaxes) noAssignableTaxes = maxDeducibleTaxes

  return noAssignableTaxes
}

export const calculateClusterCash = (price: any, points: number, rewards: rewards) => {
  return calculateCashGeneric(
    points,
    priceAssignableToPointsOnCluster(price, rewards),
    priceAssignableToCashOnCluster(price, priceAssignableToPointsOnCluster(price, rewards), rewards),
    rewards,
    calculatePassengersOnCluster(price)
  )
}

const calculatePassengersOnCluster = (price: any): number => {
  let passengers = price.adults ? price.adults.quantity : 0
  passengers += price.children ? price.children.quantity : 0
  passengers += price.infants ? price.infants.quantity : 0
  return passengers
}

//HOTELS
export const calculatePointsByRate = (fare: any, points: number, rewards: rewards, passengers: number) => {
  const price = priceAssignableToPointsOnRate(fare)
  return calculateFinalPoints(price, points, rewards)
}

export const calculateCash = (fare: any, points: number, rewards: rewards, passengers: number) => {
  return calculateCashGeneric(
    points,
    priceAssignableToPointsOnRate(fare),
    priceAssignableToCashOnRate(fare),
    rewards,
    passengers
  )
}
export const priceAssignableToPointsOnRate = (fare: any): number => {
  let total = fare.total
  if (fare.charges) {
    const comission = fare.charges.find((c: any) => c.collector === "PROVIDER" && c.type === "comission")
    if (comission) {
      total = total - comission.amount
    }
  }
  return total
}

const priceAssignableToCashOnRate = (fare: any): number => {
  if (fare.charges) {
    const comission = fare.charges.find((c: any) => c.collector === "PROVIDER" && c.type === "comission")
    if (comission) {
      return comission.amount
    }
  }
  return 0
}

export const RewardsCookies = <P extends CookiesProvider>(min: number) => {
  const cookies = new Cookies()

  let extractCookie = 0
  let rewardsCookie = cookies.get("rewards")
  if (rewardsCookie) {
    extractCookie = parseFloat(rewardsCookie.rewards.points.replace(/\./g, ""))
  } else {
    extractCookie = min
  }
  return extractCookie
}
