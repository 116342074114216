import * as React from "react"
import Icon from "../Icon"
const icon = {
  p: [
    "M493.449 103.884c-214.792 0-389.565 174.747-389.565 389.565 0 214.792 174.773 389.539 389.565 389.539s389.565-174.747 389.565-389.539c0-214.819-174.773-389.565-389.565-389.565zM493.449 812.166c-175.762 0-318.736-142.974-318.736-318.717s142.974-318.743 318.736-318.743c175.762 0 318.736 143.001 318.736 318.743 0 175.736-142.974 318.717-318.736 318.717zM455.342 525.424h152.486v-76.273h-152.486v-86.106h161.756v-76.869h-255.854v414.524h264.416v-76.875h-170.318v-98.402z",
    "M627.419 607.127c-19.328 0-37.723 3.98-54.43 11.141 0 0-27.233 9.125-55.169 42.864-17.973 23.334-28.687 52.551-28.687 84.283v46.838c0 76.372 61.915 138.286 138.286 138.286h134.843c76.372 0 138.286-61.915 138.286-138.286v-46.838c0-72.698-56.098-132.295-127.365-137.864-3.604-0.282-7.246-0.425-10.922-0.425h-134.843z",
    "M729.258 753.959c-4.913 0-8.97 2.441-12.173 6.945-3.203 4.514-4.781 10.771-4.726 18.562h33.639c-0.161-7.792-1.844-14.64-5.047-18.91-3.206-4.275-7.1-6.597-11.693-6.597z",
    "M817.264 753.959c-4.913 0-8.972 2.441-12.175 6.945-3.203 4.514-4.779 10.771-4.726 18.562h33.639c-0.158-7.792-1.841-14.64-5.045-18.91-3.203-4.275-7.1-6.597-11.693-6.597z",
    "M855.702 791.934h-56.386c0.158 6.6 1.973 12.164 5.445 16.127 3.47 3.967 7.797 5.692 12.974 5.692 3.527 0 6.49-1.153 8.893-3.208 2.402-2.050 4.215-5.419 5.445-9.982l22.428 3.991c-2.884 8.778-7.436 15.445-13.656 20.034-6.222 4.589-14.004 6.877-23.35 6.877-14.791 0-25.737-5.166-32.838-15.482-5.608-8.264-8.411-18.699-8.411-31.298 0-15.051 3.685-26.839 11.053-35.36 7.37-8.521 16.688-12.787 27.954-12.787 12.655 0 22.639 4.97 29.956 13.894 7.315 8.919 10.813 21.702 10.492 41.502zM766.766 791.934h-56.386c0.158 6.6 1.973 12.164 5.445 16.127 3.472 3.967 7.797 5.692 12.974 5.692 3.525 0 6.488-1.153 8.895-3.208 2.4-2.050 4.215-5.419 5.443-9.982l22.428 3.991c-2.884 8.778-7.434 15.445-13.656 20.034s-14.006 6.877-23.347 6.877c-14.793 0-25.739-5.166-32.841-15.482-5.606-8.264-8.409-18.699-8.409-31.298 0-15.051 3.685-26.839 11.053-35.36s16.688-12.787 27.954-12.787c12.655 0 22.637 4.97 29.952 13.894 7.317 8.919 10.816 21.702 10.494 41.502zM679.511 762.022c-3.793-2.623-7.315-3.934-10.571-3.934-3.153 0-5.821 0.928-8.011 2.779-2.191 1.855-3.784 5.201-5.038 10.048-1.254 4.845-1.751 14.994-1.751 30.443v27.978h-22.001v-90.204h19.801v12.371c4.4-6.099 6.965-10.118 9.821-12.056 2.856-1.936 6.184-2.909 9.817-2.909 5.126 0 10.109 1.511 14.859 4.534l-6.926 20.949zM618.938 725.932h-55.002v28.601h48.402v22.001h-48.402v52.802h-24.201v-125.405h79.203v22.001zM627.419 607.127c-19.328 0-37.723 3.98-54.43 11.141 0 0-27.233 9.125-55.169 42.864-17.973 23.334-28.687 52.551-28.687 84.283v46.838c0 76.372 61.915 138.286 138.286 138.286h134.843c76.372 0 138.286-61.915 138.286-138.286v-46.838c0-72.698-56.098-132.295-127.365-137.864-3.604-0.282-7.246-0.425-10.922-0.425h-134.843z"
  ],
  a: [
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(255, 255, 255)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" }
  ]
}
const FreeParking = (props: any) => {
  return <Icon icon={icon} {...props} />
}
export default FreeParking
