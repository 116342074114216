import { createStore, applyMiddleware } from "redux"
import reducers from "../reducers"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"
import { routerMiddleware, startListener } from "redux-first-routing"

export const configureStore = (history: any) => {
  const store = createStore(reducers, applyMiddleware(thunk, createLogger(), routerMiddleware(history)))
  startListener(history, store)
  return store
}
