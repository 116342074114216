import React from "react"
import { css } from "glamor"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import { Theme, theme, ThemeProps } from "../BassetProvider"

interface Props extends ThemeProps {
  value: number
  onChange: (i: number) => any
  maxValue: number
  minValue: number
}

const Counter = ({ value, onChange, maxValue, minValue, theme }: Props) => {
  const disableAdd = value >= maxValue
  const disableRemove = value <= minValue

  const onAddAction = () => {
    !disableAdd && onChange(++value)
  }

  const onRemoveAction = () => {
    !disableRemove && onChange(--value)
  }

  return (
    <div className={`${containerStyle}`}>
      <div className={`${buttonContainer}`} onClick={onRemoveAction}>
        <IconButton disabled={disableRemove} className={`${buttonStyle}`}>
          <Icon className={[iconButtonStyle(theme), disableRemove && iconDisabledStyle].join(" ")}>remove</Icon>
        </IconButton>
      </div>
      <p className={`${counterStyle}`}>{value}</p>
      <div className={`${buttonContainer}`} onClick={onAddAction}>
        <IconButton className={`${buttonStyle}`} disabled={disableAdd}>
          <Icon className={[iconButtonStyle(theme), disableAdd && iconDisabledStyle].join(" ")}>add</Icon>
        </IconButton>
      </div>
    </div>
  )
}

const buttonContainer = css({
  flex: "1 0 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})

const iconDisabledStyle = css({
  cursor: "auto",
  color: "#ccc !important"
})

const containerStyle = css({
  display: "flex",
  width: "100%",
  backgroundColor: "#fff"
})

const counterStyle = css({
  fontFamily: "Roboto",
  fontSize: "16px",
  lineHeight: "1.5",
  fontWeight: "300",
  margin: "auto 0",
  display: "inline-block",
  flex: "1 0 0",
  textAlign: "center"
})

const buttonStyle = css({
  padding: "0 !important",
  width: "24px !important",
  height: "24px !important"
})

const iconButtonStyle = (theme: Theme) =>
  css({
    fontSize: "18px !important",
    color: theme.brand_colors.click_color
  })

export default theme(Counter)
