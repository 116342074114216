import * as React from "react"
import { css } from "glamor"
import AnimateHeight from "react-animate-height"
import * as componentQueries from "react-component-queries"
import { Theme, theme, ThemeProps } from "../BassetProvider"
import Button from "@material-ui/core/Button"
import { withTranslation, WithTranslation } from "react-i18next"

interface Props extends ThemeProps, WithTranslation {
  description: string
  title?: string
  mode?: string
}

class HotelDescription extends React.PureComponent<Props, any> {
  state = {
    showFullDescription: false
  }

  onClick = () => {
    this.setState({ showFullDescription: !this.state.showFullDescription })
  }

  render() {
    const { description, mode, theme, t, title } = this.props
    const { showFullDescription } = this.state
    const shouldHideDescription = description.length > 1100 && mode === "narrow"

    return (
      <div id={"#hotelDescription"} className={`${containerStyle}`}>
        <p className={`${titleStyle(theme)}`}>{title ? title : t("HotelDescription.description")}</p>
        <AnimateHeight duration={200} height={shouldHideDescription && !showFullDescription ? 284 : "auto"}>
          <p dangerouslySetInnerHTML={{ __html: description }} className={`${descriptionStyle}`} />
        </AnimateHeight>
        {shouldHideDescription && (
          <Button onClick={this.onClick} className={`${buttonStyle(theme)}`}>
            {showFullDescription ? t("HotelDescription.see_less") : t("HotelDescription.see_more")}
          </Button>
        )}
      </div>
    )
  }
}

const descriptionStyle = css({
  fontSize: "14px",
  lineHeight: "1.5",
  color: "#4a4a4a",
  fontWeight: "300"
})

const containerStyle = css({
  padding: "16px",
  display: "flex",
  backgroundColor: "#fff",
  margin: "0px 8px",
  flexDirection: "column",
  maxWidth: "1104px",
  "@media(min-width:1200px)": {
    padding: "24px",
    margin: 0
  }
})

const buttonStyle = (theme: Theme) =>
  css({
    fontSize: "16px !important",
    color: `${theme.brand_colors.click_color} !important`,
    width: "100% !important",
    display: "flex",
    flexGrow: "0",
    lineHeight: "1.5 !important",
    textTransform: "none !important",
    fontWeight: "300 !important",
    padding: "4px !important",
    minHeight: "24px !important",
    marginTop: "8px !important",
    minWidth: "0px !important"
  })

const titleStyle = (theme: Theme) =>
  css({
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "1.33",
    color: theme.brand_colors.highlight_color,
    marginBottom: "16px"
  })

export default componentQueries(({ width }: any) => ({
  mode: width < 1104 ? "narrow" : "wide"
}))(theme(withTranslation()(HotelDescription)))
