export const MEDIA_QUERY_BREAKPOINT_MOBILE_MIN = 992
export const MEDIA_QUERY_BREAKPOINT_MOBILE_MAX = 991
export const MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX = 1199
export const MEDIA_QUERY_BREAKPOINT_MEDIUM_SCREEN_MAX = 1365

export const SEARCH_BOX_MIN_DAYS = 0
export const SEARCH_BOX_MAX_DAYS = 330

export const LOCATION_TYPE_CITY = "city"
export const LOCATION_TYPE_ACCOMMODATION = "accommodation"
export const LOCATION_TYPE_MULTI_CITY = "multi_city_vicinity"
export const LOCATION_TYPE_NEIGHBORHOOD = "neighborhood"

export const ONE_WAY = "ONE_WAY"
export const ROUND_TRIP = "ROUND_TRIP"
export const MULTI_DESTINATION = "MULTI_DESTINATION"
export const FLIGHT_TYPES = [ONE_WAY, ROUND_TRIP, MULTI_DESTINATION]

export const MAXIUM_NIGHTS = 30
export const MINIMUM_NIGHTS = 1

export const ANIMATIONS_DURATION = 300
export const SLIDER_ANIMATION_INTERVAL = 6000

export const LOCATION_CITY = "location_city"
export const LOCATION_AIRPORT = "location_airport"

export const LOCATION_TYPE_CITY_ACCOMMODATIONS = "city"
export const LOCATION_TYPE_AIRPORT = "AIRPORT"
