import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { css } from "glamor"
import * as React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import FilterSection from "./FilterSection"

interface Props extends WithTranslation {
  title: string
  filterKey: string
  value?: string
  availableFilters: any[]
  onChange: (fk: string, afs: string[]) => void
}

const NO_VALUE = "-1"

const SelectFilter = ({ title, filterKey, value, availableFilters, onChange, t }: Props) => {
  const onSelectChange = (event: any) => {
    const value = event.target.value
    if (value === NO_VALUE) {
      onChange(filterKey, [])
    } else {
      onChange(filterKey, [value])
    }
  }
  const val = value && value.length ? value[0] : NO_VALUE
  return (
    <div>
      <FilterSection title={title}>
        <Select className={`${selectStyle}`} value={val} onChange={onSelectChange}>
          <MenuItem value={NO_VALUE}>{t("SelectFilter.all")}</MenuItem>
          {availableFilters.map((e, i) => (
            <MenuItem key={i} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
      </FilterSection>
    </div>
  )
}

const selectStyle = css({
  width: "100% !important"
})

export default withTranslation()(SelectFilter)
