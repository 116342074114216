import React from "react"
import { css } from "glamor"
import errorIcon from "../../images/errorIcon.svg"

type Props = {
  children: React.ReactNode
}

const ErrorAlert = ({ children }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      <img alt={"error"} src={errorIcon} />
      <div>{children}</div>
    </div>
  )
}

const containerStyle = css({
  display: "flex",
  flexDirection: "row",
  padding: "8px 16px",
  width: "100%",
  borderRadius: "3px",
  backgroundColor: "rgba(208, 2, 27, 0.1)",
  " >img": {
    width: "100px",
    height: "72px",
    marginRight: "16px"
  },
  " > div": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
})

export default ErrorAlert
