import * as React from "react"

interface Props {
  icon:
    | string
    | {
        p: Array<string>
        a?: Array<Object>
      }
  size?: number
  style?: any
  color?: Array<string> | string
  dir?: string
  suffix?: string
}

const Icon = ({ icon, size = 16, style = {}, color, dir = "airlines", suffix = "-ISO" }: Props) => {
  if (typeof icon === "string") {
    return (
      <img
        style={{ display: "inline-block", verticalAlign: "text-bottom", width: size, height: size, ...style }}
        src={`https://statics.basset.la/${dir}/${icon}${suffix}.svg`}
        alt={icon}
      />
    )
  }

  const _style = { display: "inline-block", verticalAlign: "top", ...style }

  if (!icon) {
    return null
  }

  return (
    <svg style={_style} width={size} height={size} viewBox="0 0 1024 1024">
      {icon.p.map((path, i) => {
        let _path_style: any = icon.a ? { ...icon.a[i] } : {}
        if (_style.fill) {
          _path_style.fill = _style.fill
        }
        if (_style.stroke) {
          _path_style.stroke = _style.stroke
        }
        if (color) {
          _path_style.fill = Array.isArray(color) ? (color[i] ? color[i] : _path_style.fill) : color
        }
        return <path key={i} style={_path_style} d={path} />
      })}
    </svg>
  )
}

export default Icon
