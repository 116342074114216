import React from "react"
import Button from "@material-ui/core/Button"
import { css } from "glamor"
import moment from "moment"
import { withTranslation, WithTranslation } from "react-i18next"
import { Theme, theme, ThemeProps } from "../../BassetProvider"
import { formatPrice, setFormatPrice, roundingWithStep } from "../../../utils/helpers"
import RateDetail from "./RateDetail"
import RoomDescription from "./RoomDescription"
import { Points, calculatePointsByRate, calculateCash } from "../../../utils/rewards"

interface Props extends ThemeProps, WithTranslation {
  roomClusters: any[]
  totalGuests: number
  onRateSelection: (id: string) => void
  site?: string
  points?: Points
}

// TODO: i18n
class MobileRoomCluster extends React.PureComponent<Props, any> {
  render() {
    const { roomClusters, theme, t, points, totalGuests, onRateSelection, site } = this.props

    const checkin = moment(roomClusters[0].rates[0].checkin)
    const checkout = moment(roomClusters[0].rates[0].checkout)
    const nights = checkout.diff(checkin, "days")

    return (
      <div className={`${containerStyle}`}>
        <p className={`${titleStyle}`}>{t("MobileRoomCluster.rooms")}</p>
        <p className={`${rateInformationStyle}`}>
          {t("MobileRoomCluster.price_for_nights_and_guests", { nights: nights, guests: totalGuests })}
        </p>
        <div className={`${roomClustersContainerStyle}`}>
          {roomClusters.map((e, i) => (
            <div key={i} className={[i === 0 && highlightedStyle(theme), roomClusterStyle].join(" ")}>
              <div className={`${roomsContainerStyle}`}>
                {e.rooms.map((room: any, roomIndex: number) => (
                  <RoomDescription
                    amount={e.rooms.length}
                    beds={room.bed_configuration}
                    capacity={room.capacity}
                    name={room.name}
                    key={roomIndex}
                  />
                ))}
              </div>
              <div className={`${ratesContainerStyle}`}>
                {e.rates.map((rate: any, rateIndex: number) => {
                  let totalPrice = rate.fare.total
                  if (points && points.rewards) {
                    totalPrice = calculateCash(
                      rate.fare,
                      roundingWithStep(points.points, points.rewards.precision),
                      points.rewards,
                      points.passengers
                    )
                  }
                  return (
                    <div key={rateIndex} className={`${rateStyle}`}>
                      <RateDetail
                        mealPlan={rate.meal_plan}
                        refundable={rate.refundable}
                        cancelPolicies={rate.cancel_penalties}
                      />
                      <div className={`${priceContainerStyle}`}>
                        <div>
                          <p className={`${priceStyle}`}>
                            {points && points.rewards && (
                              <>
                                <p>
                                  {setFormatPrice(
                                    calculatePointsByRate(
                                      rate.fare,
                                      roundingWithStep(points.points, points.rewards.precision),
                                      points.rewards,
                                      points.passengers
                                    ),
                                    0,
                                    0
                                  )}{" "}
                                  {t("rewards.points")}{" "}
                                  {totalPrice !== 0 && `+ ${rate.fare.currency} ${formatPrice(totalPrice, site)}`}
                                </p>
                              </>
                            )}
                          </p>
                          <p className={`${taxDisclamerStyle}`}>{t("MobileRoomCluster.taxes_not_included")}</p>
                          <p className={`${disclamerStyle}`}>{t("MobileRoomCluster.disclaimer")}</p>
                        </div>
                        <Button
                          variant={"contained"}
                          className={`${buttonStyle(theme)}`}
                          onClick={() => onRateSelection(rate.id)}
                        >
                          {t("MobileRoomCluster.reserve")}
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

const taxDisclamerStyle = css({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "1.33",
  letterSpacing: "0.4px",
  color: "#9b9b9b"
})

const disclamerStyle = css({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "1.33",
  letterSpacing: "0.4px",
  marginTop: "8px",
  color: "red"
})

const priceStyle = css({
  fontSize: "18px",
  lineHeight: "1.33",
  letterSpacing: "1px",
  color: "#000",
  " > span": {
    fontWeight: "500"
  }
})

const priceContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "16px",
  alignItems: "center"
})

const rateStyle = css({
  border: "1px solid #eaeaea",
  borderRadius: "3px",
  padding: "16px 8px"
})

const ratesContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  " > div": {
    marginBottom: "8px"
  },
  " > div:last-child": {
    marginBottom: "0px"
  }
})

const roomsContainerStyle = css({
  padding: "8px",
  " > div": {
    marginBottom: "16px"
  },
  " > div:last-child": {
    marginBottom: "0px"
  }
})

const roomClusterStyle = css({
  padding: "8px",
  borderRadius: "3px",
  backgroundColor: "#fff",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: 0
  }
})

const roomClustersContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  marginTop: "8px"
})

const rateInformationStyle = css({
  fontSize: "14px",
  fontWeight: "300",
  lineHeight: "1.71",
  color: "#4a4a4a",
  margin: "0 8px"
})

const titleStyle = css({
  fontWeight: "300",
  fontSize: "18px",
  lineHeight: "1.33",
  color: "#4a4a4a",
  margin: "0 8px"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "8px",
  backgroundColor: "#eaeaea"
})

const highlightedStyle = (theme: Theme) =>
  css({
    boxShadow: "0 4px 4px 0 rgba(74, 144, 226, 0.24), 0 0 4px 0 rgba(74, 144, 226, 0.12)",
    border: `solid 2px ${theme.brand_colors.selection_color}`
  })

const buttonStyle = (theme: Theme) =>
  css({
    color: "#fff !important",
    backgroundColor: `${theme.brand_colors.click_color} !important`,
    width: "112px !important",
    height: "32px !important",
    textTransform: "capitalize !important",
    fontWeight: "300 !important",
    fontSize: "18px !important",
    padding: "0px !important",
    margin: "0px !important",
    minHeight: "0px !important",
    minWidth: "0px !important"
  })

export default theme(withTranslation()(MobileRoomCluster))
