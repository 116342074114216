import * as React from "react"
import Slider from "react-slick"
import { css } from "glamor"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import "slick-carousel/slick/slick.css"

interface Props {
  imgs: Array<string>
  maxHeight?: string
}

const AccommodationImagesCarousel = ({ imgs, maxHeight }: Props) => {
  const settings = {
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    touchMove: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  }

  if (!maxHeight) maxHeight = "100%"

  const imageContainerStyle = css({
    width: "100%",
    height: maxHeight,
    " > img": {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    }
  })

  return (
    <div className={`${containerStyle}`}>
      <Slider {...settings}>
        {imgs.map((e, i) => (
          <div className={`${imageContainerStyle}`} key={i}>
            <img alt={"Hotel"} src={e} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

const containerStyle = css({
  height: "100%",
  width: "100%"
})

interface ArrowProps {
  onClick?: () => void
  style?: any
  className?: string
}

const Arrow = ({ onClick, style, className }: ArrowProps) => {
  const right = className && className.includes("slick-next")

  const arrowDirection = right ? "keyboard_arrow_right" : "keyboard_arrow_left"
  const position = right ? { right: "0px" } : { left: "0px" }
  return (
    <div className={[arrowContainerStyle, className].join(" ")} style={{ ...style, ...position }} onClick={onClick}>
      <IconButton className={`${buttonStyle}`}>
        <Icon>{arrowDirection}</Icon>
      </IconButton>
    </div>
  )
}

const arrowContainerStyle = css({
  position: "absolute",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  top: "40%",
  zIndex: "1"
})

const buttonStyle = css({
  color: "#fff !important",
  width: "24px !important",
  //height: "24px !important",
  " span": {
    fontSize: "16px !important"
  }
})

export default AccommodationImagesCarousel
