import * as React from "react"
import { css } from "glamor"
import Amenities from "../Common/Amenities"
import { Theme, theme, ThemeProps } from "../BassetProvider"
import { WithTranslation, withTranslation } from "react-i18next"

interface Props extends ThemeProps, WithTranslation {
  freeWifi?: boolean
  freeParking?: boolean
  pool?: boolean
  allDayCheckin?: boolean
  checkin: {
    time: string
    instructions?: string
    special_instructions?: string
  }
  checkout: {
    time: string
  }
  onViewAmenities: () => void
}

const AmenitiesAndCheckin = ({
  freeWifi,
  freeParking,
  pool,
  allDayCheckin,
  checkin,
  checkout,
  onViewAmenities,
  theme,
  t
}: Props) => (
  <div className={`${containerStyle}`}>
    <div className={`${amenitiesContainer(theme)}`}>
      <div className={`${amenitiesContainerStyle}`}>
        <p className={`${titleStyle}`}>{t("AmenitiesAndCheckin.amenities")}</p>
        <div className={`${amenitiesStyle}`}>
          <Amenities freeWifi={freeWifi} freeParking={freeParking} pool={pool} allDayCheckin={allDayCheckin} />
        </div>
      </div>
      <button onClick={onViewAmenities}>{t("AmenitiesAndCheckin.see_all")}</button>
    </div>
    <div className={`${checkinCheckoutContainer}`}>
      <p className={`${titleStyle}`}>{t("AmenitiesAndCheckin.time_schedule")}</p>
      <div className={`${checkinCheckoutStyle}`}>
        <div className={`${hourContainerStyle}`}>
          <p>{checkin.time}</p>
          <p>{t("AmenitiesAndCheckin.checkin")}</p>
        </div>
        <div className={`${hourContainerStyle}`}>
          <p>{checkout.time}</p>
          <p>{t("AmenitiesAndCheckin.checkout")}</p>
        </div>
      </div>
    </div>
  </div>
)

const amenitiesContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
})

const checkinCheckoutStyle = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "80px",
  "@media(min-width:1200px)": {
    marginLeft: "54px"
  }
})

const hourContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "0 32px",
  "&:last-child": {
    padding: "0 0 0 32px"
  },
  "@media(min-width:1200px)": {
    padding: "0 64px",
    "&:last-child": {
      padding: "0 64px"
    }
  },
  "&:first-child": {
    borderRight: "1px solid #eaeaea"
  },
  " > p:first-child": {
    fontSize: "14px",
    lineHeight: "1.14",
    "@media(min-width:1200px)": {
      fontSize: "16px",
      lineHeight: "1.5"
    },
    fontWeight: "400",
    color: "#4a4a4a",
    textAlign: "center"
  },
  " > p:last-child": {
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "1.33",
    color: "#4a4a4a",
    textAlign: "center"
  }
})

const checkinCheckoutContainer = css({
  display: "flex",
  flexDirection: "row",
  padding: "16px",
  "@media(min-width:1200px)": {
    padding: "16px 0px 16px 24px"
  }
})

const containerStyle = css({
  borderTop: "1px solid #eaeaea",
  borderBottom: "1px solid #eaeaea",
  display: "flex",
  flexDirection: "column",
  "@media(min-width:1200px)": {
    flexDirection: "row"
  }
})

const amenitiesStyle = css({
  marginLeft: "24px",
  marginRight: "24px",
  " > span": {
    "@media(min-width:1200px)": {
      margin: "0 12px"
    },
    margin: "0 4px"
  }
})

const titleStyle = css({
  fontSize: "18px",
  fontWeight: "300",
  lineHeight: "1.33",
  color: "#4a4a4a",
  display: "inline-table",
  margin: "auto 0",
  verticalAlign: "middle"
})

const amenitiesContainer = (theme: Theme) =>
  css({
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "solid 1px #eaeaea",
    "@media(min-width:1200px)": {
      borderBottom: "none",
      borderRight: "solid 1px #eaeaea",
      padding: "24px 88px 24px 16px",
      minWidth: "552px"
    },
    " > button": {
      fontSize: "14px",
      lineHeight: "1.71",
      color: theme.brand_colors.click_color,
      cursor: "pointer",
      background: "none !important",
      border: "none",
      "&:focus": {
        outline: 0
      }
    }
  })

export default theme(withTranslation()(AmenitiesAndCheckin))
