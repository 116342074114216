import React from "react"
import { css } from "glamor"
import check from "../../images/check.svg"
import checkContrast from "../../images/checkContrast.svg"
import IconButton from "@material-ui/core/IconButton"

interface Props {
  backgroundColor?: string
  onClick?: (e: any) => void
  checked?: boolean
}

const CheckIcon = ({ backgroundColor, onClick, checked }: Props) => {
  return (
    <IconButton className={`${buttonStyle}`} onClick={onClick}>
      <div className={`${iconStyle}`} style={{ backgroundColor: checked ? backgroundColor : "#fff" }}>
        <span style={{ backgroundImage: checked ? `url("${check}")` : `url("${checkContrast}")` }} />
      </div>
    </IconButton>
  )
}

const buttonStyle = css({
  padding: "0 !important",
  width: "32px !important",
  height: "32px !important"
})

const iconStyle = css({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "solid 1px #cccccc",
  " > span": {
    display: "block",
    width: "12px",
    height: "12px",
    backgroundRepeat: "no-repeat",
    marginTop: "3px",
    marginLeft: "-4px"
  }
})

export default CheckIcon
