import * as React from "react"
import { css } from "glamor"
import RoomsHeader from "./RoomsHeader"
import RoomCluster from "./RoomCluster"
import RateTotal from "./RateTotal"
import * as componentQueries from "react-component-queries"
import MobileRoomCluster from "./MobileRoomCluster"
import { Points } from "../../../utils/rewards"
import { getProviderName } from "../../../utils/helpers"

interface Props {
  roomClusters: any[]
  channel?: string
  site?: string
  mode?: string
  id?: string
  totalGuests: number
  selectedRate: any
  showProvider?: boolean
  onRateSelection: (ri: string) => void
  onRateSelect: (ci: number, ri: number) => void
  rewards?: Points
  financial?: any
}

class RoomClusters extends React.PureComponent<Props, any> {
  onRoomClusterSelect = () => {
    const { selectedRate } = this.props
    this.props.onRateSelection(selectedRate.id)
  }

  render() {
    const {
      site,
      channel,
      totalGuests,
      onRateSelection,
      onRateSelect,
      selectedRate,
      roomClusters,
      showProvider,
      rewards,
      financial
    } = this.props

    const { mode, id } = this.props
    if (mode === "narrow") {
      return (
        <div id={id}>
          <MobileRoomCluster
            totalGuests={totalGuests}
            roomClusters={roomClusters}
            onRateSelection={onRateSelection}
            site={site}
            points={rewards}
          />
        </div>
      )
    }

    return (
      <div className={`${containerStyle}`} id={id}>
        <RoomsHeader top={0} bottomBoundary={"#roomClustersMainContainer"} points={rewards} />
        <div className={`${mainContainerStyle}`} id={"roomClustersMainContainer"}>
          <div className={`${roomClustersContainerStyle}`}>
            {roomClusters.map((e, i) => (
              <RoomCluster
                onSelect={onRateSelect}
                clusterIndex={i}
                key={i}
                rates={e.rates}
                rooms={e.rooms}
                hasRewards={!!rewards && rewards.rewards != null}
                site={site}
              />
            ))}
          </div>
          <div style={{ width: "100%" }}>
            {showProvider && <p>{getProviderName(selectedRate.provider)}</p>}
            <RateTotal
              onSelect={this.onRoomClusterSelect}
              rate={selectedRate}
              totalGuests={totalGuests}
              top={48}
              bottomBoundary={"#roomClustersMainContainer"}
              points={rewards}
              channel={channel}
              site={site}
              financial={financial}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mainContainerStyle = css({
  width: "1104px",
  backgroundColor: "#eaeaea",
  padding: "8px",
  display: "flex",
  flexDirection: "row"
})

const roomClustersContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  " > div": {
    marginBottom: "8px"
  },
  " > div:last-child": {
    marginBottom: 0
  }
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column"
})

export default componentQueries(({ width }: any) => ({
  mode: width < 1104 ? "narrow" : "wide"
}))(RoomClusters)
