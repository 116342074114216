import React from "react"
import { connect } from "react-redux"
import { push } from "redux-first-routing"
import { withTranslation, WithTranslation } from "react-i18next"

import Wrapper from "../Wrapper"
import styles from "./Home.styles"
import * as constants from "../../utils/constants"
import Searchbox from "../Searchbox/Searchbox"
import { theme, ThemeProps, Theme } from "../BassetProvider"
import { getRegion } from "../../api/geo"
import { Moment } from "moment"
import { getClientConfig } from "../../api/common"
import { searchParamsToPath } from "../../utils/helpers"
import { ApplicationsProps, applications } from "../../Applications"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
interface DispachProps {
  dispatchPush: (path: string) => void
}

interface Entity {
  id: string
  name: string
  type: string
  iata?: string
}

interface OwnProps extends WithTranslation, ThemeProps, ApplicationsProps {}

interface Props extends DispachProps, OwnProps {
  destination?: Entity
  checkin: Moment | null
  checkout: Moment | null
  rooms: any[]
  mobile?: boolean
  borderless?: boolean
  noTitle?: boolean
  onSearch: (p: any) => void
}

interface State {
  theme: Theme | null
  searchBoxMinDays: number
  searchBoxMaxDays: number
  destination?: Entity
  searchConfiguration?: any
  checkin: Moment | null
  checkout: Moment | null
  minDate?: Moment
  maxDate?: Moment
  rooms: any[]
  autocompleteResults: Entity[]
  isHidden: boolean
}

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      destination: props.destination,
      searchConfiguration: null,
      checkin: props.checkin,
      checkout: props.checkout,
      theme: this.props.theme,
      searchBoxMinDays: constants.SEARCH_BOX_MIN_DAYS,
      searchBoxMaxDays: constants.SEARCH_BOX_MAX_DAYS,
      rooms: props.rooms
        ? props.rooms
        : [
            {
              adults: 2,
              children: []
            }
          ],
      autocompleteResults: [],
      isHidden: true
    }
    if (props.destination) {
      if (!props.destination.name) {
        this.getRegionInfo(props.destination.id, props.destination.type)
      }
    }
  }

  componentDidMount() {
    this.fetchTheme()

    document.title = this.props.t("Home.documentTitle")
  }

  fetchTheme = async () => {
    const theme = await getClientConfig()
    this.setState({
      theme: theme
    })
  }

  onSearch = params => {
    const { dispatchPush } = this.props
    const path = searchParamsToPath(params)
    dispatchPush(path)
  }

  getRegionInfo = async (id: string, type: string) => {
    const region = await getRegion(id, type, "es") // FIXME: lang hardcoded
    this.setState({ destination: region })
  }

  render() {
    const { theme, rooms, destination } = this.state
    const rewards = this.props.applications && this.props.applications.some(a => a.category === "REWARDS")

    if (!theme) {
      return <div />
    }
    const backgroundImg: string | undefined =
      theme.home_image_url && theme.home_image_url !== "" ? theme.home_image_url : undefined
    const isMobile = window.innerWidth < 1200

    return (
      <Wrapper>
        <div className={styles.background(backgroundImg)} />
        <div className={styles.container}>
          <div className={styles.filterContainer}>
            <div className={styles.searchboxContainer}>
              <Searchbox
                destination={destination}
                checkin={null}
                checkout={null}
                rooms={rooms}
                mobile={isMobile}
                onSearch={this.onSearch}
                rewards={rewards}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchPush: path => {
      dispatch(push(path))
    }
  }
}

export default connect<DispachProps>(null, mapDispatchToProps)(withTranslation()(applications(theme(Home))))
