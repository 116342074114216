import * as React from "react"
import { css } from "glamor"
import Popover from "@material-ui/core/Popover"

type Props = {
  backgroundColor: string
  name: string
  lat: number
  lng: number
}

class PoiMarker extends React.PureComponent<Props, any> {
  state = {
    anchorEl: null
  }

  handleOpenPopover = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClosePopover = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { backgroundColor, name } = this.props
    const { anchorEl } = this.state
    return (
      <div>
        <div style={{ backgroundColor: backgroundColor }} onClick={this.handleOpenPopover} className={`${style}`} />
        <Popover
          open={Boolean(anchorEl)}
          onClose={this.handleClosePopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <p className={`${popoverStyle}`}>{name}</p>
        </Popover>
      </div>
    )
  }
}

const popoverStyle = css({
  color: "#4a4a4a",
  fontWeight: "300",
  textAlign: "center",
  fontSize: "12px",
  padding: "8px"
})

const style = css({
  width: "16px",
  height: "16px",
  cursor: "pointer",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  borderRadius: "50%",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)"
})

export default PoiMarker
