import React from "react"
import { css } from "glamor"
import i18next from "i18next"
import { withTranslation, WithTranslation } from "react-i18next"
import { theme, ThemeProps } from "../../BassetProvider"

interface CancelPolicy {
  start_date: string
  start_time: string
  end_date: string
  end_time: string
}

interface Props extends ThemeProps, WithTranslation {
  mealPlan: string
  cancelPolicies: CancelPolicy[]
  refundable?: boolean
}

const RateDetail = ({ mealPlan, cancelPolicies, refundable, theme, t }: Props) => {
  const getMealPlanText = (mealPlan: string, t: i18next.TFunction) => {
    if (mealPlan && mealPlan === "ROOM_ONLY") {
      return (
        <p>
          • <span style={{ color: "#9b9b9b" }}>{t("RateDetail.mealplan_not_included")}</span>
        </p>
      )
    }
    return (
      <p>
        • <span style={{ color: "#579b08" }}>{t(`RateDetail.mealPlan.${mealPlan}`)}</span>
      </p>
    )
  }

  return <div className={`${containerStyle}`}>{getMealPlanText(mealPlan, t)}</div>
}

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  " > p": {
    fontSize: "14px",
    lineHeight: "1.14",
    color: "#4a4a4a",
    marginBottom: "8px",
    fontWeight: "300"
  },
  " > p:last-child": {
    marginBottom: 0
  }
})

export default theme(withTranslation()(RateDetail))
