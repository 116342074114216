import * as React from "react"
import { css } from "glamor"
import Stars from "../../Common/Stars"
import Icon from "@material-ui/core/Icon"
import { Theme, theme, ThemeProps } from "../../BassetProvider"
import TripadvisorRating from "../../Common/TripadvisorRating"
import ClusterPrice from "./ClusterPrice"
import Amenities from "../../Common/Amenities"
import * as _ from "underscore"
import { withTranslation, WithTranslation } from "react-i18next"
import noImage from "../../../images/accommodation-image.svg"
import { Points } from "../../../utils/rewards"

interface Props extends ThemeProps, WithTranslation {
  name: string
  address: string
  stars: number
  photos: any[]
  refundable: boolean
  rating?: number
  amenities?: any
  rates?: any[]
  onHotelSelect?: () => void
  mini?: boolean
  borderless?: boolean
  nights?: number
  totalGuests?: number
  mealPlan?: string
  showProvider?: boolean
  channel?: string
  site?: string
  mobile?: boolean
  rewards?: Points | undefined
  financial?: any
}

class AccommodationCluster extends React.Component<Props, any> {
  shouldComponentUpdate(nextProps: Props) {
    return !_.isEqual(this.props, nextProps)
  }

  render() {
    const {
      channel,
      site,
      mobile,
      theme,
      name,
      stars,
      address,
      rating,
      amenities,
      rates,
      photos,
      onHotelSelect,
      mini,
      borderless,
      nights,
      totalGuests,
      mealPlan,
      showProvider,
      //refundable,
      rewards,
      financial
      //t
    } = this.props

    const wide = !mobile && !mini
    return (
      <div className={[wide && containerStyleWide, mini && containerStyleMini, containerStyle(borderless)].join(" ")}>
        <div className={[wide && mainContainerStyleWide, mini && mainContainerStyleMini, mainContainerStyle].join(" ")}>
          <div
            className={[
              mini && carouselContainerStyleMini,
              wide && carouselContainerStyleWide,
              carouselContainerStyle
            ].join(" ")}
          >
            <img
              onError={e => {
                // @ts-ignore
                e.target.onerror! = null
                // @ts-ignore
                e.target.src! = noImage
              }}
              src={photos && photos.length > 0 ? photos[0].url : noImage}
              alt={name}
            />
          </div>
          <div
            className={[
              wide && hotelInfoContainerStyleWide,
              mini && hotelInfoContainerStyleMini,
              hotelInfoContainerStyle
            ].join(" ")}
          >
            <div
              className={`${hotelNameStyle(mini)}`}
              style={{ cursor: onHotelSelect ? "pointer" : "default", fontSize: wide ? "18px" : "16px" }}
              onClick={() => onHotelSelect && onHotelSelect()}
            >
              <p>{name}</p>
            </div>
            {stars && <Stars amount={stars} />}
            <div className={[wide && labelIconStyleWide, labelIconStyle(theme)].join(" ")}>
              <Icon className={`${iconStyle(theme)}`}>location_on</Icon>
              <p>{address}</p>
            </div>
            <div className={`${detailedInfoContainer}`}>
              {mealPlan && (
                <div className={[wide && mealPlanContainerStyleWide, mealPlanContainerStyle].join(" ")}>
                  <span className={`${mealPlanStyle}`}>{mealPlan}</span>
                </div>
              )}

              {rating && !mini && (
                <div className={[wide && tripAdvisorContainerStyleWide, tripAdvisorContainerStyle].join(" ")}>
                  <TripadvisorRating rating={rating} logo={true} />
                </div>
              )}
              {amenities && !mini && (
                <div className={[wide && amenitiesStyleWide, amenitiesStyle].join(" ")}>
                  <Amenities {...amenities} />
                </div>
              )}
            </div>
          </div>
        </div>
        {rates && nights && (
          <ClusterPrice
            onHotelSelect={onHotelSelect}
            rates={rates}
            wide={wide}
            site={site}
            channel={channel}
            nights={nights}
            totalGuests={totalGuests}
            showProvider={showProvider}
            rewards={rewards}
            financial={financial}
          />
        )}
      </div>
    )
  }
}

const detailedInfoContainer = css({
  marginTop: "auto"
})
// ToDo: Borrar despues de testear
// const freeCancellation = (theme: Theme) =>
//   css({
//     fontSize: "12px",
//     fontWeight: "300",
//     lineHeight: "1.33",
//     color: theme.brand_colors.click_color
//   })

const mainContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1"
})

const containerStyle = (borderless: boolean | undefined) =>
  css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: borderless ? 0 : "3px",
    minWidth: "auto",
    width: "100%",
    overflow: "hidden",
    justifyContent: "space-between",
    height: "calc( 100% - 8px )",
    transition: "box-shadow 0.5s",
    "&:hover": {
      boxShadow: borderless ? 0 : "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)"
    }
  })

const labelIconStyle = (theme: Theme) =>
  css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "5px",
    marginBottom: "16px",
    " > img": {
      width: "16px",
      height: "16px",
      marginRight: "4px"
    },
    " > p": {
      fontSize: "14px",
      lineHeight: "1.14",
      fontWeight: "300",
      color: theme.brand_colors.click_color
    }
  })

const hotelNameStyle = (mini: boolean | undefined) =>
  css({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.33",
    color: "#4a4a4a",
    " > p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "8px",
      width: mini ? "200px" : "auto",
      maxWidth: "336px"
    }
  })

const iconStyle = (theme: Theme) =>
  css({
    fontSize: "16px !important",
    marginRight: "4px",
    color: theme.brand_colors.click_color
  })

const mealPlanContainerStyle = css({
  margin: "8px 0"
})

const mealPlanStyle = css({
  backgroundColor: "#579b08",
  color: "#ffffff",
  padding: "5px",
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "2",
  borderRadius: "2px"
})

const carouselContainerStyle = css({
  height: "100%",
  display: "flex",
  " > img": {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  }
})

const hotelInfoContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  padding: "8px",
  " > p": {
    marginBottom: "0",
    paddingBottom: "8px"
  },
  " > *:last-child": {
    marginBottom: "0px"
  }
})

const amenitiesStyle = css({
  marginTop: "16px",
  " > span": {
    "@media(min-width:1200px)": {
      marginRight: "8px"
    },
    marginRight: "4px"
  }
})

const tripAdvisorContainerStyle = css({
  marginTop: "16px"
})

// Mini style

const mainContainerStyleMini = css({
  flexDirection: "row !important",
  height: "152px !important",
  justifyContent: "unset"
})

const containerStyleMini = css({
  flexDirection: "row",
  minWidth: "0",
  height: "152px !important"
})

const hotelInfoContainerStyleMini = css({
  marginLeft: "8px"
})

const labelIconStyleWide = css({
  marginBottom: "24px"
})

// Wide Styles

const tripAdvisorContainerStyleWide = css({
  marginTop: "24px"
})

const amenitiesStyleWide = css({
  marginTop: "24px"
})

const containerStyleWide = css({
  flexDirection: "row !important",
  justifyContent: "unset"
})

const mainContainerStyleWide = css({
  flexDirection: "row",
  minWidth: "0"
})

const carouselContainerStyleWide = css({
  width: "280px",
  flexShrink: "0",
  height: "100%"
})

const mealPlanContainerStyleWide = css({
  marginBottom: "16px"
})

const hotelInfoContainerStyleWide = css({
  padding: "16px",
  minWidth: "0",
  width: "auto"
})

// Mini styles

const carouselContainerStyleMini = css({
  width: "128px",
  height: "152px"
})

export default withTranslation()(theme(AccommodationCluster))
