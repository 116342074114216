import React from "react"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import _ from "underscore"
import iconStar from "../../../images/star.svg"
import ActiveFilters from "./ActiveFilters"
import CheckboxFilter from "./CheckboxFilter"
import NameFilter from "./NameFilter"
import SelectFilter from "./SelectFilter"
// ToDo: Borrar despues de testear
//import SliderFilter from "./SliderFilter"

interface Props extends WithTranslation {
  availableFilters: any
  appliedFilters: any
  currencyCode: string
  onFilterChange: (fk: string, afs: string[]) => void
}

const Filters = ({ availableFilters, appliedFilters, currencyCode, onFilterChange, t }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      {!_.isEmpty(appliedFilters) && (
        <ActiveFilters
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilterChange={onFilterChange}
          currencyCode={currencyCode}
        />
      )}
      {availableFilters.stars && (
        <CheckboxFilter
          title={t("Filters.all_stars")}
          availableFilters={availableFilters.stars}
          appliedFilters={appliedFilters.stars}
          valuesIcon={iconStar}
          removeAllValue={t("Filters.all_stars")}
          onChange={onFilterChange}
          filterKey={"stars"}
          maxShownFilters={5}
        />
      )}
      {availableFilters.category && (
        <CheckboxFilter
          title={t("Filters.types")}
          availableFilters={availableFilters.category}
          appliedFilters={appliedFilters.category}
          removeAllValue={t("Filters.all_types")}
          onChange={onFilterChange}
          filterKey={"category"}
          maxShownFilters={4}
        />
      )}
      {availableFilters.amenities && (
        <CheckboxFilter
          title={t("Filters.amenities")}
          availableFilters={availableFilters.amenities}
          appliedFilters={appliedFilters.amenities}
          removeAllValue={t("Filters.all_amenities")}
          onChange={onFilterChange}
          filterKey={"amenities"}
          maxShownFilters={4}
        />
      )}
      {/* ToDo: Borrar despues de testear */}
      {/* {availableFilters.price && (
        <SliderFilter
          onApply={onFilterChange}
          filterKey={"price"}
          value={appliedFilters.price}
          currency={currencyCode}
          title={t("Filters.price")}
          min={parseFloat(availableFilters.price[0].value)}
          max={parseFloat(availableFilters.price[1].value)}
        />
      )} */}
      {availableFilters.zone && (
        <SelectFilter
          title={t("Filters.zone")}
          filterKey={"zone"}
          value={appliedFilters.zone}
          availableFilters={availableFilters.zone}
          onChange={onFilterChange}
        />
      )}
      <NameFilter
        title={t("Filters.name")}
        filterKey={"name"}
        placeholder={t("Filters.insert_name")}
        value={appliedFilters.name}
        onChange={onFilterChange}
      />
    </div>
  )
}

const containerStyle = css({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  " > div": {
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.14)"
  },
  " > div:last-child": {
    borderBottom: "none"
  }
})

export default withTranslation()(Filters)
