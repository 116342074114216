import * as React from "react"
import { css } from "glamor"
import FreeWifiIcon from "./svg-icons/free-wifi"
import FreeParkingIcon from "./svg-icons/free-parking"
import PoolIcon from "./svg-icons/pool"
import AllDayCheckinIcon from "./svg-icons/hs-checkin"
import { theme, ThemeProps } from "../BassetProvider"

interface Props extends ThemeProps {
  freeWifi?: boolean
  freeParking?: boolean
  pool?: boolean
  allDayCheckin?: boolean
}

const Amenities = ({ freeWifi, freeParking, pool, allDayCheckin, theme }: Props) => {
  let amenities: JSX.Element[] = []

  if (freeWifi) amenities.push(<FreeWifiIcon size={24} color={theme.brand_colors.highlight_color} key={0} />)
  if (freeParking) amenities.push(<FreeParkingIcon size={24} color={theme.brand_colors.highlight_color} key={1} />)
  if (pool) amenities.push(<PoolIcon size={24} color={theme.brand_colors.highlight_color} key={2} />)
  if (allDayCheckin) amenities.push(<AllDayCheckinIcon size={24} color={theme.brand_colors.highlight_color} key={3} />)

  return (
    <>
      {amenities.map((a, i) => (
        <span className={`${amenityContainerStyle}`} key={i}>
          {a}
        </span>
      ))}
    </>
  )
}

const amenityContainerStyle = css({
  display: "inline-block",
  border: "1px solid #1134d7",
  borderRadius: "50%",
  padding: "4px"
})

export default theme(Amenities)
