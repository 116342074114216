import React from "react"
import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import FaceIcon from "@material-ui/icons/Face"
import PersonIcon from "@material-ui/icons/Person"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { Theme, theme, ThemeProps } from "../BassetProvider"
import FieldContainer from "./FieldContainer"
import ChildrenAgeSelector from "./ChildrenAgeSelector"
import Counter from "./Counter"

interface Props extends ThemeProps, WithTranslation {
  maxPersons: number
  adults: number
  children: []
  index: number
  onRemoveRoom: (i: number) => void
  removable?: boolean
  onAdultChangeOnRoom: (i: number, qty: number) => void
  onChildrenQtyChangeOnRoom: (i: number, qty: number) => void
  onChildrenAgeChangeOnRoom: (i: number, chdIdx: number, age: number) => void
}

const RoomConfiguration = ({
  maxPersons,
  adults,
  children,
  index,
  onRemoveRoom,
  onAdultChangeOnRoom,
  onChildrenQtyChangeOnRoom,
  onChildrenAgeChangeOnRoom,
  removable,
  t,
  theme
}: Props) => {
  const onAdultChange = (qty: number) => {
    onAdultChangeOnRoom(index, qty)
  }
  const onChildQtyChange = (qty: number) => {
    onChildrenQtyChangeOnRoom(index, qty)
  }

  const onChildrenAgeChange = (chdIndex: number, chdAge: number) => {
    onChildrenAgeChangeOnRoom(index, chdIndex, chdAge)
  }

  const onRemoveRoomIndex = () => {
    onRemoveRoom(index)
  }

  const maxChildren = maxPersons - adults
  const maxAdults = maxPersons - children.length

  return (
    <div>
      <div className={`${titleContainerStyle}`}>
        <p className={`${roomTitleStyle(theme)}`}>{t("RoomConfiguration.room_number", { numebr: index + 1 })}</p>
        {removable && (
          <IconButton className={`${removeRoomButtonStyle}`} onClick={onRemoveRoomIndex}>
            <Icon className={`${removeIconStyle}`}>close</Icon>
          </IconButton>
        )}
      </div>
      <div className={`${fieldsContainer}`}>
        <FieldContainer
          icon={<PersonIcon style={{ fontSize: "16px" }} />}
          label={t("RoomConfiguration.adults")}
          descriptionText={t("RoomConfiguration.over_eighteen")}
          labelWidth={"152px"}
        >
          <Counter value={adults} minValue={1} maxValue={maxAdults} onChange={onAdultChange} />
        </FieldContainer>
        <FieldContainer
          icon={<FaceIcon style={{ fontSize: "16px" }} />}
          label={t("RoomConfiguration.children")}
          descriptionText={t("RoomConfiguration.under_eighteen")}
          labelWidth={"152px"}
        >
          <Counter minValue={0} maxValue={maxChildren} value={children.length} onChange={onChildQtyChange} />
        </FieldContainer>
      </div>
      <div className={`${childrenAgeSelectorContainer}`}>
        {children.length > 0 && <p className={`${disclamerStyle}`}>{t("RoomConfiguration.childrens_disclamer")}</p>}
        {children.map((c, i) => (
          <ChildrenAgeSelector onChildrenAgeChange={onChildrenAgeChange} age={c} key={i} index={i} />
        ))}
      </div>
    </div>
  )
}

const titleContainerStyle = css({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  marginBottom: "8px",
  alignItems: "center",
  height: "16px"
})

const removeRoomButtonStyle = css({
  flex: "1 0 0",
  padding: "0 !important",
  width: "24px !important",
  height: "24px !important"
})

const removeIconStyle = css({
  color: "#fff",
  fontSize: "16px !important"
})

const childrenAgeSelectorContainer = css({})

const disclamerStyle = css({
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "1.44",
  color: "#fff",
  marginBottom: "8px",
  marginTop: "16px"
})

const fieldsContainer = css({
  display: "flex",
  flexDirection: "column"
})

const roomTitleStyle = (theme: Theme) =>
  css({
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "300",
    color: theme.searchbox_colors.background_color,
    margin: "auto 0",
    flex: "1 0 0"
  })

export default withTranslation()(theme(RoomConfiguration))
