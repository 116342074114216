import React from "react"
import Button from "@material-ui/core/Button"
import { css } from "glamor"
import moment from "moment"
import { withTranslation, WithTranslation } from "react-i18next"
import Sticky from "react-stickynode"
import { theme, Theme, ThemeProps } from "../../BassetProvider"
import { formatPrice, setFormatPrice } from "../../../utils/helpers"
import AccommodationPriceDetail from "../AccommodationPriceDetail"
import DiscountLabel from "../../Common/DiscountLabel"
import StrikethroughPrice from "../../Common/StrikethroughPrice"
import { calculateCash, Points, calculatePointsByRate, RewardsCookies } from "../../../utils/rewards"
import { roundingWithStep } from "../../../utils/helpers"

interface Props extends ThemeProps, WithTranslation {
  rate: any
  totalGuests: number
  bottomBoundary?: string | number
  top?: string | number
  onSelect: () => void
  points?: Points
  channel?: string
  site?: string
  financial?: any
}

const PACKAGE_TYPE = "PACKAGE_OPAQUE"

const RateTotal = ({
  rate,
  totalGuests,
  top,
  bottomBoundary,
  theme,
  onSelect,
  t,
  channel,
  site,
  points,
  financial
}: Props) => {
  const buttonStyle = (disabled?: boolean) =>
    css({
      color: !disabled && "#fff !important",
      fontSize: "16px !important",
      fontWeight: "300 !important",
      textTransform: "capitalize !important",
      backgroundColor: !disabled && `${theme.brand_colors.click_color} !important`,
      width: "176px !important",
      margin: "0 auto !important",
      height: "32px !important",
      padding: "0 !important",
      marginTop: "16px !important"
    })

  const checkin = moment(rate.checkin)
  const checkout = moment(rate.checkout)

  const nights = checkout.diff(checkin, "days")

  const isPackageRate = rate.type === PACKAGE_TYPE

  let totalPrice = rate.fare.total
  if (points && points.rewards) {
    totalPrice = calculateCash(
      rate.fare,
      roundingWithStep(points.points, points.rewards.precision),
      points.rewards,
      points.passengers
    )
  }

  let disabled: boolean =
    points && points.rewards
      ? RewardsCookies(0) <
        calculatePointsByRate(
          rate.fare,
          roundingWithStep(points.points, points.rewards.precision),
          points.rewards,
          points.passengers
        )
      : false

  return (
    <Sticky top={top} innerZ={500} bottomBoundary={bottomBoundary}>
      <div className={`${containerStyle}`}>
        {points && points.rewards && (
          <>
            <div className={`${bannerPoints}`}>
              {t("rewards.total_points", {
                points: setFormatPrice(
                  calculatePointsByRate(rate.fare, 100000000000, points.rewards, points.passengers),
                  0,
                  0
                )
              })}
            </div>
            <p className={`${totalPriceLabelStyle}`}>A canjear</p>
            <p className={`${priceStyle}`}>
              {setFormatPrice(
                calculatePointsByRate(
                  rate.fare,
                  roundingWithStep(points.points, points.rewards.precision),
                  points.rewards,
                  points.passengers
                ),
                0,
                0
              )}{" "}
              {t("rewards.points")}
            </p>
            {totalPrice > 0 && (
              <>
                <div className={`${priceContainer}`}>
                  <p className={`${pricePlusStyle}`}>+</p>
                </div>

                <p className={`${priceStyle}`}>
                  {rate.fare.currency} {formatPrice(totalPrice, site)}
                  <p className={`${totalPriceStyle}`}>
                    {financial &&
                      t("FinancialAdvertising.installments_financial_rewards", {
                        installments: financial.installments
                      })}
                  </p>
                </p>
              </>
            )}
          </>
        )}
        {(!points || !points.rewards) && channel === "DESKTOP" && (
          <div className={`${priceDetailStyle}`}>
            {rate.fare.discount && (
              <div className={`${discountStyle}`}>
                <DiscountLabel value={rate.fare.discount.discount} />
              </div>
            )}
            <AccommodationPriceDetail guests={totalGuests} nights={nights} fare={rate.fare} site={site} />
          </div>
        )}
        {(!points || !points.rewards) && channel !== "DESKTOP" && (
          <>
            <p className={`${nightsTextStyle}`}>
              {t("RateTotal.price_for_nights_and_guests", { nights: nights, guests: totalGuests })}
            </p>
            {rate.fare.discount && (
              <StrikethroughPrice
                bold
                value={`${rate.fare.currency} ${formatPrice(rate.fare.discount.base_rate, site)}`}
              />
            )}
            <p className={`${priceStyle}`}>
              {rate.fare.currency} {formatPrice(rate.fare.net_rate, site)}
            </p>
            {rate.fare.discount && (
              <div className={`${discountLabel}`}>
                <DiscountLabel value={rate.fare.discount.discount} />
              </div>
            )}
          </>
        )}
        {isPackageRate && <p className={`${packageRateStyle(theme)}`}>{t("RateTotal.package_rate")}</p>}
        {channel !== "DESKTOP" && (!points || !points.rewards) && (
          <p className={`${taxDisclamerStyle}`}>{t("RateTotal.taxes_not_included")}</p>
        )}

        <Button className={`${buttonStyle(disabled)}`} disabled={disabled} variant={"contained"} onClick={onSelect}>
          {!points || !points.rewards ? t("RateTotal.reserve") : t("RateTotal.exchange")}
        </Button>
        {points && points.rewards && (
          <>
            <p className={`${taxDisclamerStyle}`}>{t("AccommodationClusterPrice.fee_not_included")}</p>
            <p className={`${disclamerStyle}`}>{t("rewards.disclaimer")}</p>
          </>
        )}
      </div>
    </Sticky>
  )
}

const discountStyle = css({
  alignSelf: "flex-end"
})

const priceDetailStyle = css({
  width: "100%",
  display: "flex",
  flexDirection: "column"
})

const discountLabel = css({
  marginBottom: "16px"
})

const packageRateStyle = (theme: Theme) =>
  css({
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "1.33",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: theme.brand_colors.click_color,
    marginBottom: "24px"
  })

const taxDisclamerStyle = css({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "1.33",
  letterSpacing: "0.4px",
  color: "#9b9b9b",
  textAlign: "center",
  marginTop: "8px",
  marginBottom: "16px"
})

const priceStyle = css({
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "1.33",
  letterSpacing: "1.5px",
  textAlign: "center",
  color: "#000",
  marginBottom: "16px"
})

const nightsTextStyle = css({
  fontSize: "14px",
  fontWeight: "300",
  lineHeight: "1.71",
  letterSpacing: "0.5px",
  textAlign: "center",
  color: "#000",
  marginBottom: "24px"
})

const containerStyle = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  padding: "24px"
})

const totalPriceLabelStyle = css({
  fontSize: "14px",
  fontWeight: "300",
  color: "#9b9b9b",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  textAlign: "center"
})

const priceContainer = css({
  margin: "auto",
  alignItems: "center",
  height: "25px",
  marginBottom: "16px"
})

const pricePlusStyle = css({
  fontSize: "24px",
  fontWeight: "400",
  color: "#4a4a4a",
  textAlign: "center"
})

const disclamerStyle = css({
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "1.33",
  color: "#9b9b9b",
  textAlign: "center"
})

const bannerPoints = css({
  display: "flex",
  clear: "both",
  background: `linear-gradient(45deg, transparent 0%, transparent 15%, black 14%),
  linear-gradient(70deg, transparent 0, transparent 0), linear-gradient(21deg, transparent 76%, black 63%),
  linear-gradient(-45deg, black 85%, transparent 41%)`,
  color: "white",
  width: "123%",
  position: "relative",
  right: "-9px",
  height: "42px",
  fontWeight: "500",
  fontSize: "20px",
  alignItems: "center",
  marginTop: "16px",
  marginBottom: "18px",
  padding: "8px",
  justifyContent: "center"
})

const totalPriceStyle = css({
  fontSize: "12px",
  fontWeight: "300",
  color: "#4a4a4a",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  textAlign: "center"
})

export default theme(withTranslation()(RateTotal))
