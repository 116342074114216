import * as React from "react"
import { css } from "glamor"
import Sticky from "react-stickynode"
import { Points } from "../../../utils/rewards"
import { WithTranslation, withTranslation } from "react-i18next"
interface Props extends WithTranslation {
  bottomBoundary?: string | number
  top?: string | number
  points?: Points
}

const RoomsHeader = ({ bottomBoundary, top, points, t }: Props) => {
  const dinamicWidth = points ? "550px" : "358px"
  return (
    <Sticky innerZ={1000} top={top} bottomBoundary={bottomBoundary}>
      <div className={`${containerStyle}`}>
        <div style={{ width: "256px" }}>{t("RoomsHeader.rooms")}</div>
        <div style={{ width: dinamicWidth, padding: "0px 24px" }}>{t("RoomsHeader.options")}</div>
        {!points && <div style={{ width: "200px", textAlign: "center" }}>{t("RoomsHeader.per_night")}</div>}
        <div style={{ width: "88px", textAlign: "center" }}>{t("RoomsHeader.choose")}</div>
        <div style={{ width: "280px", textAlign: "center" }}>{t("RoomsHeader.total")}</div>
      </div>
    </Sticky>
  )
}

const containerStyle = css({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#fff",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
  height: "40px",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "300",
  lineHeight: "1.71",
  color: "#4a4a4a"
})

export default withTranslation()(RoomsHeader)
