import React from "react"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import { css } from "glamor"
import AnimateHeight from "react-animate-height"
import { withTranslation, WithTranslation } from "react-i18next"
import { theme, ThemeProps } from "../../BassetProvider"
import FilterSection from "./FilterSection"

interface Filter {
  value: string
  label: string
}

interface Props extends ThemeProps, WithTranslation {
  appliedFilters?: string[]
  availableFilters: Filter[]
  filterKey: string
  title: string
  valuesIcon?: string
  removeAllValue?: string
  maxShownFilters: number
  onChange: (filterKey: string, appliedFilters: string[]) => void
}

class CheckboxFilter extends React.PureComponent<Props, any> {
  state = {
    showAll: false
  }

  onInputChange = (event: any) => {
    const { value, checked } = event.target
    const { onChange, filterKey, appliedFilters } = this.props
    const applied = appliedFilters ? new Set(this.props.appliedFilters) : new Set("")
    if (checked) {
      applied.add(value)
      onChange(filterKey, Array.from(applied))
    } else {
      applied.delete(value)
      onChange(filterKey, Array.from(applied))
    }
  }

  clearAll = () => {
    const { filterKey, onChange } = this.props
    onChange(filterKey, [])
  }

  onShowAll = () => {
    this.setState({ showAll: !this.state.showAll })
  }

  buttonStyle = css({
    fontSize: "14px !important",
    color: `${this.props.theme.brand_colors.click_color} !important`,
    width: "fit-content !important",
    display: "flex",
    flexGrow: "0",
    lineHeight: "1.5 !important",
    textTransform: "none !important",
    fontWeight: "300 !important",
    padding: "4px !important",
    minHeight: "24px !important",
    marginTop: "8px !important",
    minWidth: "0px !important"
  })

  render() {
    const {
      appliedFilters,
      availableFilters,
      title,
      removeAllValue,
      valuesIcon,
      theme,
      maxShownFilters,
      t
    } = this.props
    const { showAll } = this.state

    const checkboxCheckedStyle = css({
      color: `${theme.brand_colors.selection_color} !important`
    })

    const shouldHideOptions = availableFilters.length > maxShownFilters
    const applied = appliedFilters ? new Set(this.props.appliedFilters) : new Set()

    return (
      <FilterSection title={title}>
        {removeAllValue && (
          <div className={`${checkBoxContainerStyle}`}>
            <Checkbox
              classes={{
                root: `${checkboxStyle}`,
                checked: `${checkboxCheckedStyle}`
              }}
              onChange={this.clearAll}
              checked={applied.size === 0}
              className={`${checkboxStyle}`}
              value={"-1"}
            />
            <span className={`${labelStyle}`}>{removeAllValue}</span>
          </div>
        )}
        <AnimateHeight duration={250} height={shouldHideOptions && !showAll ? 28 * maxShownFilters : "auto"}>
          {availableFilters.map((e, i) => (
            <div key={`${i}div`} className={`${checkBoxContainerStyle}`}>
              <Checkbox
                classes={{
                  root: `${checkboxStyle}`,
                  checked: `${checkboxCheckedStyle}`
                }}
                onChange={this.onInputChange}
                checked={applied.has(e.value)}
                className={`${checkboxStyle}`}
                value={e.value}
              />
              <span className={`${labelStyle}`}>{e.label}</span>
              {valuesIcon && <img alt={"star"} src={valuesIcon} />}
            </div>
          ))}
        </AnimateHeight>
        {shouldHideOptions && (
          <Button onClick={this.onShowAll} className={`${this.buttonStyle}`}>
            {showAll ? t("CheckboxFilter.see_less") : t("CheckboxFilter.see_more")}
          </Button>
        )}
      </FilterSection>
    )
  }
}

const checkBoxContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: "0px"
  },
  " > img": {
    width: "16px",
    height: "16px",
    marginLeft: "4px"
  }
})

const checkboxStyle = css({
  width: "18px !important",
  height: "18px !important",
  color: "#4a4a4a",
  padding: "0 !important"
})

const labelStyle = css({
  marginLeft: "8px",
  fontSize: "14px",
  lineHeight: "1.5",
  fontWeight: "300",
  letterSpacing: "0.6px",
  color: "#4a4a4a"
})

export default withTranslation()(theme(CheckboxFilter))
