import * as React from "react"
import { css } from "glamor"
import Icon from "@material-ui/core/Icon"

interface Props {
  destination: string
  checkin: string
  checkout: string
  rooms: number
  guests: number
  className?: string
  isOpen?: boolean
  onClick: () => void
}

const SearchboxHeader = ({ destination, checkin, checkout, rooms, guests, className, isOpen, onClick }: Props) => {
  const arrow = isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"

  return (
    <div className={[containerStyle, className].join(" ")} onClick={onClick}>
      <div className={`${destinationContainerStyle}`}>
        <Icon className={`${iconStyle}`}>location_on</Icon>
        <p>{destination}</p>
      </div>
      <div className={`${datesContainerStyle}`}>
        <p>{checkin}</p>
        <p>{checkout}</p>
      </div>
      <div className={`${roomsContainerStyle}`}>
        <div>
          <Icon className={`${iconStyle}`}>hotel</Icon>
          <p>{rooms}</p>
        </div>
        <div>
          <Icon className={`${iconStyle}`}>person</Icon>
          <p>{guests}</p>
        </div>
        <Icon className={`${arrowStyle}`}>{arrow}</Icon>
      </div>
    </div>
  )
}

const arrowStyle = css({
  color: "#fff"
})

const iconStyle = css({
  fontSize: "16px !important",
  color: "#fff",
  marginRight: "8px"
})

const roomsContainerStyle = css({
  paddingLeft: "24px",
  paddingRight: "8px",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  " > div": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "16px"
  }
})

const datesContainerStyle = css({
  padding: "0px 16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minWidth: "80px",
  borderRight: "1px solid #fff"
})

const destinationContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  padding: "0px 12px",
  borderRight: "1px solid #fff",
  alignItems: "center",
  " > img": {
    width: "16px",
    height: "16px",
    marginRight: "8px"
  },
  " > p": {
    textOverflow: "ellipsis",
    width: "82px",
    height: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }
})

const containerStyle = css({
  display: "flex",
  cursor: "pointer",
  flexDirection: "row",
  height: "48px",
  padding: "8px 0px",
  " p": {
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "1.14",
    color: "#fff"
  }
})

export default SearchboxHeader
