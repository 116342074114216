import * as React from "react"

import { css } from "glamor"
import Button from "@material-ui/core/Button"
import { withTranslation, WithTranslation } from "react-i18next"
import { theme, ThemeProps, Theme } from "../../BassetProvider"
import ProviderRatesOptions from "./ProviderRatesOptions"
import { formatPrice, getProviderName, roundingWithStep, setFormatPrice } from "../../../utils/helpers"
import DiscountLabel from "../../Common/DiscountLabel"
import StrikethroughPrice from "../../Common/StrikethroughPrice"
import { Points, calculateCash, calculatePointsByRate } from "../../../utils/rewards"
import AccommodationPriceDetail from "../../Detail/AccommodationPriceDetail"
interface Props extends ThemeProps, WithTranslation {
  site?: string
  channel?: string
  onHotelSelect?: () => void
  rates: any[]
  wide: boolean
  nights: number
  totalGuests?: number
  showProvider?: boolean
  rewards?: Points
  financial?: any
}

const PACKAGE_TYPE = "PACKAGE_OPAQUE"

const AccommodationClusterPrice = ({
  t,
  theme,
  onHotelSelect,
  rates,
  wide,
  site,
  channel,
  nights,
  totalGuests,
  showProvider,
  rewards,
  financial
}: Props) => {
  const rate = rates[0]

  if (!onHotelSelect) {
    return null
  }

  if (channel === "DESKTOP") {
    if (nights === undefined || totalGuests === undefined) {
      throw new Error("Missing properties nights and/or totalGuests when the channel is DESKTOP")
    }

    const isPackageRate = rate.type === PACKAGE_TYPE
    return (
      <div className={`${priceContainerStyleDesktop}`}>
        <ProviderRatesOptions rates={rates} site={site} />
        <div className={`${providerDetailStyle}`}>
          <div className={`${priceTitle}`}>
            <p>{getProviderName(rate.provider)}</p>
            {rate.fare.discount && <DiscountLabel value={rate.fare.discount.discount} />}
          </div>
          <AccommodationPriceDetail guests={totalGuests} nights={nights} fare={rate.fare} site={site} />
          {isPackageRate && <p className={`${packageMessage(theme)}`}>{t("AccommodationClusterPrice.package_rate")}</p>}
          <Button
            onClick={onHotelSelect}
            variant={"contained"}
            fullWidth={true}
            className={`${buttonStyle(theme, false, wide)}`}
          >
            {t("AccommodationClusterPrice.see_hotel")}
          </Button>
        </div>
      </div>
    )
  } else if (rewards && rewards.rewards) {
    const points = calculatePointsByRate(
      rate.fare,
      roundingWithStep(rewards.points, rewards.rewards.precision),
      rewards.rewards,
      rewards.passengers
    )
    const cash = calculateCash(
      rate.fare,
      roundingWithStep(rewards.points, rewards.rewards.precision),
      rewards.rewards,
      rewards.passengers
    )

    const disabled = false

    return (
      <div className={[wide && clusterPriceStyleWide, clusterPriceStyle].join(" ")}>
        {!wide && rate.fare.discount && (
          <div className={`${mobileDiscountContainer}`}>
            <div className={`${rewardsMobilePriceStyles}`}>
              {setFormatPrice(roundingWithStep(points, rewards.rewards.precision), 0, 0)} {t("rewards.points")} <br />+{" "}
              {`${rate.fare.currency} ${formatPrice(cash, site)}`}
            </div>
            <DiscountLabel value={Math.floor(rate.fare.discount.discount)} />
          </div>
        )}
        <div className={[wide && priceContainerStyleWide, priceContainerStyle].join(" ")}>
          {showProvider && <p>{getProviderName(rate.provider)}</p>}
          {wide && (
            <>
              <div className={`${bannerPoints}`}>
                {t("rewards.total_points", {
                  points: setFormatPrice(
                    calculatePointsByRate(rate.fare, 100000000000, rewards.rewards, rewards.passengers),
                    0,
                    0
                  )
                })}
              </div>
              <div className={`${priceContainer}`}>
                <div className={`${totalPriceStyle}`}>A canjear</div>
                <p className={`${priceStyle}`}>
                  {setFormatPrice(roundingWithStep(points, rewards.rewards.precision), 0, 0)} {t("rewards.points")}
                </p>
              </div>
              {cash > 0 && (
                <>
                  <div className={`${priceContainer}`}>
                    <div className={`${plusContainer}`}>
                      <p className={`${pricePlusStyle}`}>+</p>
                    </div>
                  </div>

                  <div className={`${priceContainer}`}>
                    <p className={`${priceStyle}`}>{`${rate.fare.currency} ${formatPrice(cash, site)}`}</p>
                    <p className={`${totalPriceStyle}`}>
                      {financial &&
                        t("FinancialAdvertising.installments_financial_rewards", {
                          installments: financial.installments
                        })}
                    </p>

                    {rate.fare.discount && (
                      <div className={`${discountContainer}`}>
                        <DiscountLabel value={Math.floor(rate.fare.discount.discount)} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          <Button
            onClick={onHotelSelect}
            disabled={disabled}
            variant={"contained"}
            className={`${buttonStyle(theme, false, wide)}`}
          >
            {t("AccommodationClusterPrice.see_hotel")}
          </Button>
          <div className={`${totalPriceLabelStyle(wide)}`}>
            {t("AccommodationClusterPrice.fee_not_included")}
            <div className={`${rewardsDisclaimerContent}`}>
              <div className={`${rewardsDisclaimerMessage}`}>{t("rewards.disclaimer")}</div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    const nightlyBasis = `${rate.fare.currency} ${formatPrice(rate.fare.nightly_basis, site)}`
    const buttonDescription = wide ? t("AccommodationClusterPrice.see_hotel") : nightlyBasis
    const basePrice = `${rate.fare.currency} ${formatPrice(rate.fare.net_rate, site)}`

    return (
      <div className={[wide && clusterPriceStyleWide, clusterPriceStyle].join(" ")}>
        {rate.fare.discount && !wide && (
          <div className={`${mobileDiscountContainer}`}>
            <DiscountLabel value={Math.floor(rate.fare.discount.discount)} />
            <StrikethroughPrice
              value={`${rate.fare.currency} ${formatPrice(rate.fare.discount.nightly_basis, site)}`}
            />
          </div>
        )}
        <div className={[wide && priceContainerStyleWide, priceContainerStyle].join(" ")}>
          {showProvider && <p>{getProviderName(rate.provider)}</p>}
          <div>
            <p className={`${byNightDisclamerStyle}`} style={{ textAlign: wide ? "center" : "left" }}>
              {t("AccommodationClusterPrice.price_per_night")}
            </p>
            <p className={`${taxesDisclamerStyle}`}>{t("AccommodationClusterPrice.taxes_not_included")}</p>
          </div>
          {wide && (
            <div className={`${priceContainer}`}>
              {rate.fare.discount && (
                <div className={`${strikethroughContainer}`}>
                  <StrikethroughPrice
                    value={`${rate.fare.currency} ${formatPrice(rate.fare.discount.nightly_basis, site)}`}
                  />
                </div>
              )}
              <p className={`${priceStyle}`}>{nightlyBasis}</p>
              <p className={`${totalPriceStyle}`}>{`${t("AccommodationClusterPrice.total_nights", {
                count: nights
              })} ${basePrice}`}</p>
              {rate.fare.discount && (
                <div className={`${discountContainer}`}>
                  <DiscountLabel value={Math.floor(rate.fare.discount.discount)} />
                </div>
              )}
            </div>
          )}
          <Button onClick={onHotelSelect} variant={"contained"} className={`${buttonStyle(theme, false, wide)}`}>
            {buttonDescription}
          </Button>
        </div>
      </div>
    )
  }
}

const priceTitle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
})

const clusterPriceStyle = css({
  display: "flex",
  flexDirection: "column",
  borderTop: "solid 1px #eaeaea",
  padding: "8px"
})
const rewardsMobilePriceStyles = css({
  fontSize: "14px"
})
const mobileDiscountContainer = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "8px"
})

const strikethroughContainer = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "8px",
  marginTop: "16px"
})

const discountContainer = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "24px",
  marginBottom: "8px"
})

const priceContainer = css({
  margin: "auto",
  alignItems: "center"
})

const totalPriceLabelStyle = (wide: boolean) =>
  css({
    fontSize: "12px",
    fontWeight: "300",
    color: "#4a4a4a",
    lineHeight: "1.33",
    letterSpacing: "0.1px",
    textAlign: `${wide ? "center" : "left"}`,
    margin: `${wide ? "8px" : "0"} 0 0 8px`
  })

const totalPriceStyle = css({
  fontSize: "12px",
  fontWeight: "300",
  color: "#4a4a4a",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  textAlign: "center"
})

const buttonStyle = (theme: Theme, disabled: boolean, wide: boolean) =>
  css({
    lineHeight: `${wide ? "1.78" : "14px"} !important`,
    fontWeight: "300 !important",
    letterSpacing: "0.9px",
    marginTop: `${wide ? "auto" : "2px"} !important`,
    height: "40px !important",
    fontSize: `${wide ? "16px" : "14px"} !important`,
    color: !disabled && "#fff !important",
    backgroundColor: !disabled && `${theme.brand_colors.click_color} !important`,
    padding: "0px 8px !important",
    textTransform: "none !important"
  })

const priceContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
})

const priceContainerStyleDesktop = css({
  display: "flex",
  flexDirection: "row",
  width: "360px",
  flexShrink: "0",
  height: "100%"
})

const priceContainerStyleWide = css({
  width: "238px",
  padding: "16px",
  flexDirection: "column",
  boxSizing: "border-box",
  borderTop: "none",
  borderLeft: "solid 1px #eaeaea",
  height: "100%"
})

const priceStyle = css({
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "1.33",
  letterSpacing: "0.3px",
  color: "#4a4a4a",
  textAlign: "center"
})

const pricePlusStyle = css({
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "0",
  letterSpacing: "0.3px",
  color: "#4a4a4a",
  textAlign: "center",
  width: "30%"
})

const byNightDisclamerStyle = css({
  fontSize: "14px",
  lineHeight: "1.14",
  fontWeight: "300",
  letterSpacing: "0.5px",
  marginBottom: "4px",
  color: "#4a4a4a",
  textAlign: "center"
})

const taxesDisclamerStyle = css({
  fontSize: "12px",
  lineHeight: "1.33",
  letterSpacing: "0.1px",
  color: "#9b9b9b",
  fontWeight: "300",
  textAlign: "center"
})

const providerDetailStyle = css({
  display: "flex",
  flexDirection: "column",
  fontWeight: "300",
  height: "100%",
  width: "200px",
  padding: "16px",
  " > p": {
    fontSize: "12px",
    lineHeight: "1.33",
    letterSpacing: "0.1px",
    fontWeight: "300",
    textAlign: "left",
    padding: "8px 0",
    borderBottom: "1px dotted #eaeaea",
    " > span": {
      float: "right",
      fontWeight: "normal",
      textAlign: "right"
    }
  },
  " > p:nth-child(2) > span": {
    lineHeight: "2.66"
  },
  " > div:first-child": {
    marginBottom: "8px",
    fontSize: "14px",
    textTransform: "capitalize",
    " > span": {
      fontSize: "12px",
      color: "#0d207b"
    }
  }
})

const packageMessage = (theme: Theme) =>
  css({
    borderBottom: "none !important",
    fontWeight: "500 !important",
    color: theme.brand_colors.click_color,
    padding: "0 !important"
  })

const clusterPriceStyleWide = css({
  borderTop: "none",
  padding: "0px"
})
const plusContainer = css({
  display: "flex",
  margin: "10px auto"
})

const bannerPoints = css({
  display: "flex",
  clear: "both",
  background: `linear-gradient(45deg, transparent 0%, transparent 15%, black 14%),
    linear-gradient(70deg, transparent 0, transparent 0), linear-gradient(21deg, transparent 76%, black 63%),
    linear-gradient(-45deg, black 85%, transparent 41%)`,
  color: "white",
  width: "110%",
  height: "42px",
  fontWeight: "500",
  fontSize: "18px",
  alignItems: "center",
  marginTop: "-16px",
  marginBottom: "18px",
  padding: "8px",
  justifyContent: "center"
})

const rewardsDisclaimerContent = css({
  display: "flex",
  textAlign: "left"
})

const rewardsDisclaimerMessage = css({
  display: "inline-flex",
  fontSize: "12px",
  color: "red"
})

export default theme(withTranslation()(AccommodationClusterPrice))
