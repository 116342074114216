import * as React from "react"
import Icon from "../Icon"
const icon = {
  p: [
    "M383.255 557.948l-20.527 19.924c-2.899 2.816-4.533 6.682-4.533 10.721v8.502c0 8.251 6.692 14.944 14.946 14.944h122.57c8.264 0 14.951-6.692 14.951-14.944v-11.005c0-8.251-6.687-14.946-14.951-14.946h-67.724l1.867-1.717c44.262-42.643 75.59-76.208 75.59-119.911 0-34.943-22.709-70.201-73.462-70.201-22.156 0-43.854 7.111-61.101 20.015-5.601 4.202-7.546 11.732-4.662 18.119l3.77 8.337c1.831 4.055 5.363 7.083 9.658 8.262 4.269 1.179 8.877 0.383 12.521-2.162 11.091-7.745 22.507-11.675 33.945-11.675 22.259 0 33.541 11.067 33.541 32.742-0.297 29.373-24.661 55.741-76.399 104.996z",
    "M606.661 382.955c-0.398-0.036-0.802-0.052-1.2-0.052h-4.564c-4.887 0-9.459 2.387-12.252 6.384l-91.539 131c-1.753 2.511-2.692 5.5-2.692 8.562v10.501c0 8.254 6.687 14.946 14.941 14.946h83.58v42.799c0 8.251 6.687 14.944 14.941 14.944h14.271c8.249 0 14.949-6.692 14.949-14.944v-42.799h14.734c8.254 0 14.949-6.692 14.949-14.946v-9.705c0-8.249-6.695-14.944-14.949-14.944h-14.734v-116.852c0-8.249-6.7-14.944-14.949-14.944h-14.271c-0.409 0-0.817 0.016-1.215 0.049zM592.935 514.702h-45.131c11.466-16.648 31.069-45.219 45.131-66.335v66.335z",
    "M513.050 838.793c-175.898 0-319.004-143.107-319.004-319.004 0-166.56 128.323-303.691 291.289-317.789v50.851c0 3.612 1.994 6.933 5.179 8.629 1.443 0.771 3.023 1.148 4.598 1.148 1.906 0 3.812-0.559 5.451-1.663l115.464-77.576c2.705-1.815 4.326-4.859 4.326-8.117 0-3.256-1.621-6.302-4.326-8.117l-115.464-77.576c-2.997-2.014-6.863-2.216-10.051-0.512-3.186 1.699-5.179 5.019-5.179 8.629v39.445c-198.653 14.253-355.936 180.418-355.936 382.645 0 211.547 172.11 383.651 383.651 383.651 97.942 0 191.183-36.903 262.547-103.908l-44.247-47.13c-59.335 55.712-136.859 86.394-218.298 86.394z",
    "M840.539 319.842c-13.216-21.615-28.672-42.025-45.922-60.654l-47.435 43.921c14.357 15.502 27.208 32.484 38.209 50.463l55.149-33.73z",
    "M829.145 476.465l64.059-8.696c-3.419-25.186-9.374-50.096-17.703-74.038l-61.057 21.232c6.915 19.883 11.859 40.575 14.701 61.502z",
    "M733.088 205.47c-20.803-14.592-43.127-27.141-66.353-37.306l-25.915 59.224c19.301 8.448 37.857 18.879 55.149 31.007l37.12-52.925z",
    "M759.487 722.367l49.915 41.082c16.146-19.619 30.384-40.901 42.312-63.255l-57.031-30.438c-9.919 18.587-21.76 36.29-35.196 52.612z",
    "M831.116 544.458c-1.608 21.093-5.329 42.056-11.049 62.301l62.208 17.589c6.894-24.374 11.367-49.597 13.307-74.964l-64.465-4.926z"
  ],
  a: [
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" },
    { fill: "rgb(13, 32, 123)" }
  ]
}
const HsCheckin = (props: any) => {
  return <Icon icon={icon} {...props} />
}
export default HsCheckin
