import * as React from "react"
import { Subtract } from "utility-types"

const ThemeContext = React.createContext<Theme | undefined>(undefined)

const ThemeProvider = ThemeContext.Provider
const ThemeConsumer = ThemeContext.Consumer

export interface Theme {
  brand_colors: {
    click_color: string
    selection_color: string
    highlight_color: string
    background_color: string
  }
  searchbox_colors: {
    click_color: string
    selection_color: string
    highlight_color: string
    background_color: string
  }
  home_image_url?: string
}

interface Props {
  children: React.ReactNode
  theme: Theme
}

export class BassetProvider extends React.Component<Props, any> {
  render() {
    return <ThemeProvider value={this.props.theme}>{this.props.children}</ThemeProvider>
  }
}

export interface ThemeProps {
  theme: Theme
}

export const theme = <P extends ThemeProps>(Component: React.ComponentClass<P> | React.StatelessComponent<P>) => {
  return class ThemeComponent extends React.Component<Subtract<P, ThemeProps>, React.ComponentState> {
    render() {
      return <ThemeConsumer>{theme => <Component {...(this.props as P)} theme={theme} />}</ThemeConsumer>
    }
  }
}

export const useTheme = (): Theme => {
  const theme = React.useContext(ThemeContext)
  return theme!
}

export default BassetProvider
