import React from "react"
import Icon from "@material-ui/core/Icon"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import doubleBed from "../../../images/standardbed.svg"
import twinBed from "../../../images/twinbed.svg"

interface Props extends WithTranslation {
  amount: number
  name: string
  beds: {
    type: string
    size: string
    count: number
  }[]
  capacity: number
}

const RoomDescription = ({ amount, name, beds, capacity, t }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      <p className={`${titleStyle}`}>{`${amount} x ${name}`}</p>
      <div className={`${bedContainerStyle}`}>
        {beds.map((e, i) => (
          <div key={i} className={`${bedStyle}`}>
            {Array.apply(null, Array(e.count)).map((b: any, i: number) => (
              <img key={i} src={e.size === "Twin" ? twinBed : doubleBed} alt={""} />
            ))}
            <p>{`${t(`RoomDescription.bedTypes.${e.type}`, { count: e.count })}`}</p>
          </div>
        ))}
      </div>
      <div className={`${capacityStyle}`}>
        <p>
          <span>{capacity} </span>
          <Icon style={{ fontSize: "16px" }} className={`${personStyle}`}>
            person
          </Icon>
        </p>
        <p className={`${roomAmountStyle}`}>{t("RoomDescription.for_room", { count: amount })}</p>
      </div>
    </div>
  )
}

const titleStyle = css({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "1.5",
  color: "#000"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  " > *": {
    marginBottom: "8px"
  },
  " > *:last-child": {
    marginBottom: "8px"
  }
})

const roomAmountStyle = css({
  borderRadius: "3px",
  fontWeight: "300",
  backgroundColor: "#f0f0f0",
  padding: "1px 8px",
  marginLeft: "8px"
})

const capacityStyle = css({
  display: "flex",
  flexDirection: "row",
  " > p": {
    display: "table",
    fontSize: "14px",
    fontWeight: "400",
    color: "#4a4a4a",
    " > span": {
      display: "table-cell",
      verticalAlign: "middle"
    }
  }
})

const personStyle = css({
  color: "#4a4a4a !important",
  height: "16px",
  width: "16px"
})

const bedStyle = css({
  display: "flex",
  flexDirection: "row",
  marginBottom: "8px",
  "&:last-child": {
    marginBottom: 0
  },
  " > img": {
    width: "12px",
    height: "12px",
    marginRight: "8px"
  },
  " > p": {
    fontSize: "14px",
    lineHeight: "1.14",
    color: "#4a4a4a",
    fontWeight: "300"
  }
})

const bedContainerStyle = css({
  display: "flex",
  flexDirection: "column"
})

export default withTranslation()(RoomDescription)
