import * as React from "react"

import Slider, { SliderProps } from "@material-ui/core/Slider"
import { css } from "emotion"

export interface Props extends SliderProps {
  rootColor?: string
  trackColor?: string
  disabledColor?: string
  markActive?: string
  markLabel?: string
  markLabelActive?: string
  marked?: string
}

const SliderComponent = ({
  rootColor,
  trackColor,
  disabledColor,
  markActive,
  markLabel,
  markLabelActive,
  marked,
  ...rest
}: Props) => {
  return (
    <Slider
      classes={{
        root: SliderStyle(rootColor).rootStyle,
        track: SliderStyle(trackColor).trackColor,
        thumb: SliderStyle(trackColor).trackColor,
        disabled: SliderStyle(disabledColor).disabledColor,
        markActive: SliderStyle(markActive).markActive,
        markLabelActive: SliderStyle(markLabelActive).markLabelActive,
        markLabel: SliderStyle(markLabel).markLabel,
        marked: SliderStyle(marked).marked,
        mark: SliderStyle(marked).marked,
        valueLabel: SliderStyle(marked).valueLabel
      }}
      {...rest}
    />
  )
}

const SliderStyle = (color?: string) => {
  if (!color) {
    color = ""
  }
  return {
    rootStyle: css({
      color: `${color} !important`
    }),
    trackColor: css({
      backgroundColor: `${color} !important`
    }),
    disabledColor: css({
      backgroundColor: `${color} !important`
    }),
    markActive: css({
      backgroundColor: `${color} !important`
    }),
    markLabel: css({
      "&.MuiSlider-mark": {
        padding: "4px",
        borderRadius: "2px",
        fontSize: "12px",
        textAlign: "center"
      }
    }),
    markLabelActive: css({
      backgroundColor: `${color} !important`
    }),
    marked: css({
      backgroundColor: `${color} !important`
    }),
    valueLabel: css({
      left: "initial !important",
      top: "-24px !important",
      "& *": {
        borderRadius: "4px !important ",
        transform: "none !important",
        padding: "0 4px !important",
        width: "auto !important"
      }
    })
  }
}

export default SliderComponent
