import * as React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import Icon from "@material-ui/core/Icon"
import { css } from "glamor"
import { LOCATION_CITY, LOCATION_AIRPORT } from "../../../utils/constants"

//Icons
import LocationCityIcon from "@material-ui/icons/LocationCity"
import FlightIcon from "@material-ui/icons/Flight"

const suggestionTitleStyle = css({
  "&.MuiMenuItem-root": {
    height: "24px",
    padding: "8px 16px",
    color: "#4a4a4a"
  }
})

const suggestionTitleIconStyle = css({
  fontSize: "16px !important",
  color: "#9b9b9b !important"
})

const titleStyle = css({
  marginLeft: "8px",
  fontSize: "12px !important",
  lineHeight: "16px !important",
  color: "#9b9b9b !important"
})

interface Props {
  title: string
  iconType: string
}

const SuggestionTitle = ({ title, iconType }: Props) => {
  const icon = () => {
    switch (iconType) {
      case LOCATION_AIRPORT:
        return <FlightIcon className={`${suggestionTitleIconStyle}`} />
      case LOCATION_CITY:
        return <LocationCityIcon className={`${suggestionTitleIconStyle}`} />
      default:
        return <Icon className={`${suggestionTitleIconStyle}`}>{iconType}</Icon>
    }
  }

  return (
    <MenuItem component={"div"} className={`${suggestionTitleStyle}`}>
      {icon()}
      <span className={`${titleStyle}`}>{title}</span>
    </MenuItem>
  )
}

export default SuggestionTitle
