import Icon from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"
import { css } from "glamor"
import * as React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { theme, ThemeProps } from "../../BassetProvider"
import { formatPrice } from "../../../utils/helpers"
import RateDetail from "./RateDetail"
import RoomDescription from "./RoomDescription"
interface Props extends ThemeProps, WithTranslation {
  rooms: any[]
  rates: any[]
  clusterIndex: number
  onSelect: (ci: number, ri: number) => void
  hasRewards: boolean
  site?: string
}

const RoomCluster = ({ t, rooms, rates, clusterIndex, onSelect, theme, hasRewards, site }: Props) => {
  const roomMap = {}

  rooms.forEach(r => {
    if (!roomMap[r.id]) {
      roomMap[r.id] = [r]
    } else {
      roomMap[r.id].push(r)
    }
  })

  const roomsToRender: JSX.Element[] = []

  for (let key in roomMap) {
    const room = roomMap[key][0]
    roomsToRender.push(
      <RoomDescription
        key={key}
        amount={roomMap[key].length}
        beds={room.bed_configuration}
        capacity={room.capacity}
        name={room.name}
      />
    )
  }
  const dinamicWidth = hasRewards ? "500px" : "358px"

  return (
    <div className={`${containerStyle}`}>
      <div className={`${roomsDescriptionContainerStyle}`}>{roomsToRender}</div>
      <div className={`${ratesContainerStyle}`}>
        {rates.map((e, i) => (
          <div
            key={i}
            className={`${rateContainerStyle}`}
            style={{
              backgroundColor: e.selected ? "rgba(18, 106, 255, 0.1)" : "",
              height: rates.length === 1 ? "100%" : "128px"
            }}
            onClick={() => onSelect(clusterIndex, i)}
          >
            <div className={`${rateDetailContainer(dinamicWidth)}`}>
              <RateDetail mealPlan={e.meal_plan} refundable={e.refundable} cancelPolicies={e.cancel_penalties} />
            </div>
            {!hasRewards && (
              <div className={`${priceContainerStyle}`}>
                <p>
                  {e.fare.currency} <span>{formatPrice(e.fare.nightly_basis, site)}</span>
                </p>
              </div>
            )}
            <div className={`${buttonContainerStyle}`}>
              <IconButton>
                <Icon
                  style={{
                    fontSize: "24px",
                    color: e.selected ? theme.brand_colors.click_color : "rgba(0, 0, 0, 0.54)"
                  }}
                >
                  {e.selected ? "check_circle" : "check_circle_outline"}
                </Icon>
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const buttonContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "88px",
  justifyContent: "center"
})

const priceContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "149px",
  borderRight: "1px solid #eaeaea",
  justifyContent: "center",
  " > p": {
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "0.7px",
    textAlign: "left",
    fontWeight: "300",
    padding: "16px"
  },
  " > p > span, > p > small > span": {
    fontWeight: "400"
  },
  " > p > small": {
    fontSize: "12px",
    display: "block",
    marginTop: "8px"
  }
})

const ratesContainerStyle = css({
  borderLeft: "1px solid #eaeaea",
  display: "flex",
  flexDirection: "column"
})

const rateContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
  borderBottom: "1px dashed #eaeaea",
  "&:hover": {
    backgroundColor: "rgba(18, 106, 255, 0.1)"
  },
  height: "128px",
  "&:last-child": {
    borderBottom: "none"
  }
})

const rateDetailContainer = (val: string) => {
  return css({
    display: "flex",
    flexDirection: "column",
    padding: "0px 16px",
    justifyContent: "center",
    height: "100%",
    //width: "360px",
    width: val,
    borderRight: "1px solid #eaeaea"
  })
}

const containerStyle = css({
  display: "flex",
  flexDirection: "row",
  borderRadius: "3px",
  backgroundColor: "#fff"
})

const roomsDescriptionContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "32px 16px",
  width: "256px"
})

export default theme(withTranslation()(RoomCluster))
