import * as React from "react"
import { css } from "glamor"
import { theme, ThemeProps } from "../BassetProvider"

interface Props extends ThemeProps {
  id?: string
  placeholder?: string
  name?: string
  onChange?: (e: any) => void
  onFocus?: (e: any) => void
  onClick?: (e: any) => void
  value: string
  error?: boolean
  number?: boolean
  pointer?: boolean
  readOnly?: boolean
  borderless?: boolean
  classes?: string[]
  onBlur?: (e: any) => void
}

const TextInput = ({
  id,
  placeholder,
  name,
  onChange,
  onFocus,
  value,
  number,
  error,
  onBlur,
  readOnly,
  classes,
  onClick,
  pointer,
  borderless,
  theme
}: Props) => {
  const errorStyle = css({
    border: "solid 1px #d0021b !important"
  })

  const inputStyle = css({
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    border: borderless ? "none" : "solid 1px #ccc",
    padding: "8px",
    fontFamily: "Roboto",
    fontSize: "14px",
    borderRadius: borderless ? 0 : "3px",
    "::placeholder": {
      color: "#9b9b9b"
    },
    fontWeight: "300",
    flex: "1 1 0%",
    ":focus": {
      outline: "none",
      border: borderless ? "none" : "solid 1px " + theme.brand_colors.click_color
    },
    cursor: pointer ? "pointer" : "auto"
  })

  classes = classes ? classes : []

  return (
    <input
      id={id}
      onFocus={onFocus}
      name={name}
      className={[...classes, inputStyle, error && errorStyle].join(" ")}
      type={number ? "number" : "text"}
      placeholder={placeholder}
      pattern="[a-zA-Z0-9\s]+"
      value={value}
      onClick={onClick}
      onChange={onChange}
      readOnly={readOnly}
      onBlur={onBlur}
      autoComplete={"off"}
    />
  )
}

export default theme(TextInput)
