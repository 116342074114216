import { makeApiRequest } from "./common"

export const getAutocompleteResults = async (query: string, rewards?: boolean) => {
  const uri = `${
    process.env.REACT_APP_API_URI
  }/autocomplete?entities=ACC_MULTI_CITY_VICINITY,ACC_CITY,ACC_NEIGHBORHOOD${
    rewards ? "" : ",ACCOMMODATION"
  }&q=${query}&language=es&product=ACCOMMODATIONS`
  return makeApiRequest(uri)
}
