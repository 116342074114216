import * as React from "react"
import { css } from "glamor"
import hotelIcon from "../images/hotel.svg"
import LinearProgress from "@material-ui/core/LinearProgress"

const AppLoader = () => (
  <div className={`${containerStyle}`}>
    <div className={`${mainContainerStyle}`}>
      <div className={`${hotelImgContainerStyle}`}>
        <img src={hotelIcon} alt={"hotel"} />
      </div>
      <LinearProgress className={`${linearStyle}`} />
    </div>
  </div>
)

const linearStyle = css({
  width: "100%",
  height: "8px !important",
  borderRadius: "4px",
  " > div": {
    backgroundColor: "#03a9f4 !important"
  }
})

const containerStyle = css({
  display: "flex",
  width: "100vw",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
})

const mainContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "136px"
})

const hotelImgContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "98px",
  height: "98px",
  borderRadius: "50%",
  backgroundColor: "#00a5f4",
  " > img": {
    width: "58px",
    height: "65px"
  },
  marginBottom: "24px"
})

export default AppLoader
