import { css } from "emotion"

export default {
  errorTitle: css({
    fontSize: "16px",
    fontWeight: 500,
    color: "#d0021b",
    lineHeight: 1.5,
    marginBottom: "8px"
  }),

  searchboxContainerStyle: css({
    padding: "0px 32px 32px 32px"
  }),

  appLoaderStyle: css({
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    position: "fixed",
    zIndex: 1002,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.9
  }),

  mainContainerStyle: css({
    "@media(min-width:1200px),": {
      padding: "24px 0",
      margin: "0 auto",
      width: "1104px"
    },
    width: "100vw",
    " > div": {
      marginBottom: "8px",
      width: "inherit"
    }
  }),

  backgroundStyle: css({
    backgroundColor: "#f9f9f9",
    minHeight: "100vh"
  }),

  sliderRewards: css({
    position: "relative",
    zIndex: 1001,
    width: "100vw",
    "@media(min-width:1200px),": {
      width: "1110px"
    },
    paddingTop: "24px",
    margin: " 0 auto",
    marginBottom: "-24px"
  }),
  researchContainerStyle: (highlightColor: string) =>
    css({
      display: "flex",
      flexDirection: "column",
      backgroundColor: highlightColor
    }),

  searchboxTitleStyle: (highlightColor: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      padding: "0px 16px",
      justifyContent: "flex-end",
      " > p": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "18px",
        fontWeight: 300,
        lineHeight: 1.33,
        color: highlightColor
      },
      " > button": {
        color: "#fff !important",
        "&:focus": {
          outline: 0
        }
      }
    }),

  errorSubtitle: (clickColor: string) =>
    css({
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: 1.14,
      color: "#4a4a4a",
      " > button": {
        color: clickColor,
        cursor: "pointer",
        background: "none !important",
        border: "none",
        "&:focus": {
          outline: 0
        }
      }
    })
}
