import * as React from "react"
import { css } from "glamor"
import { theme, ThemeProps } from "../../BassetProvider"
import errorIcon from "../../../images/notFoundIcon.svg"
import { WithTranslation, withTranslation } from "react-i18next"

interface Props extends ThemeProps, WithTranslation {}

const NoAvailFound = ({ theme, t }: Props) => {
  const titleStyle = css({
    color: theme.brand_colors.highlight_color,
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    marginBottom: "8px"
  })

  return (
    <div className={`${containerStyle}`}>
      <img alt={"error"} src={errorIcon} />
      <div>
        <div>
          <p className={`${titleStyle}`}>{t("NoAvailFound.title")}</p>
          <p className={`${subtitleStyle}`}>{t("NoAvailFound.title")}</p>
        </div>
      </div>
    </div>
  )
}

const subtitleStyle = css({
  fontSize: "14",
  fontWeight: "300",
  lineHeight: "1.14",
  color: "#4a4a4a",
  maxWidth: "568px"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "row",
  padding: "8px 16px",
  width: "100%",
  borderRadius: "3px",
  backgroundColor: "rgba(70, 128, 233, 0.12)",
  " >img": {
    width: "100px",
    height: "72px",
    marginRight: "16px"
  },
  " > div": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
})

export default withTranslation()(theme(NoAvailFound))
